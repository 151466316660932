import { useMap } from '@vis.gl/react-maplibre'
import { useState, MouseEvent } from 'react'
import { ReactComponent as InfoIcon } from 'assets/icons/info-outlined.svg'
import { ReactComponent as DeleteBlueIcon } from 'assets/icons/delete-blue.svg'
import { Feature } from 'geojson'
import { deleteFavoriteObject, FavoritesSignal, MapDataSignal, handleLocateObject, SOURCE_NAMES } from 'services'
import { ConfirmModal, Loader, ModalSignal } from 'components'
import { get } from '@osrdata/app_core/dist/requests'
import { FavoriteObject } from 'types'
import { PanelSignal } from '../PanelsManager'
import InfoSinglePanel from '../info/InfoSinglePanel'
import FavoritesPanel from './FavoritesPanel'

export default function FavoriteObjects() {
  const [loading, setLoading] = useState<string>('')
  const maps = useMap()
  const favorites = FavoritesSignal.objects.value

  const handleDisplayInfo = (favorite: FavoriteObject) => async () => {
    if (loading) return
    setLoading(favorite.id)

    const feature = await get<Feature>(
      `/chartis/v2/layer/${favorite.layer_slug}/geojson_feature/full_rgi_track_geo/`,
      { id: favorite.object_id },
    )

    PanelSignal.value = (
      <InfoSinglePanel
        feature={{ ...feature, source: favorite.layer_slug }}
        onBack={() => { PanelSignal.value = <FavoritesPanel /> }}
      />
    )

    setLoading('')
  }

  const handleLocate = (favorite: FavoriteObject) => async (e: MouseEvent) => {
    e.stopPropagation()
    setLoading(favorite.id)
    await handleLocateObject(favorite.object_id, favorite.layer_slug, maps)
    setLoading('')
  }

  const handleDelete = (id: string) => (e: MouseEvent) => {
    e.stopPropagation()
    const closeModal = () => { ModalSignal.value = undefined }
    ModalSignal.value = (
      <ConfirmModal
        title="Supprimer le favori ?"
        handleClose={closeModal}
        handleValidate={async () => {
          await deleteFavoriteObject(id)
          closeModal()
        }}
      />
    )
  }

  const handleHover = (favorite: FavoriteObject) => () => {
    MapDataSignal.hoveredObjects.value = [{ properties: { id: favorite.object_id } }]
  }

  return (
    <div className={`objects-favorites favorites hide-scroll${loading ? ' disable' : ''}`}>
      {favorites.map(favorite => (
        <div
          key={favorite.id}
          className="favorite objects"
          onMouseEnter={handleHover(favorite)}
          onClick={handleLocate(favorite)}
        >
          <div className="flex-center head">
            <p>
              <b>{favorite.object_libelle}</b>
              <br />
              <span className="info">{SOURCE_NAMES[favorite.layer_slug] || 'N/C'}</span>
            </p>
            {loading === favorite.id
              ? <Loader variant="x-small" />
              : <InfoIcon onClick={handleDisplayInfo(favorite)} />}
            <DeleteBlueIcon onClick={handleDelete(favorite.id)} />
          </div>
          <p className="lvp">
            {'Localisation : '}
            {favorite.object_lvp || 'N/C'}
          </p>
        </div>
      ))}
    </div>
  )
}
