/* eslint-disable max-len */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg'
import { ReactComponent as MapIcon } from 'assets/icons/map.svg'
import { ReactComponent as TableIcon } from 'assets/icons/table.svg'
import { ContentType } from 'types'
import {
  ContentSignals, LayersSignal, getContentForParam, parseViewportToURL, GEO_DEFAULT_VIEWPORT, urlEqualsToViewport, reprojectViewport,
  DEFAULT_LAYERS,
} from 'services'
import { debounce } from 'lodash'
import { Resizer, Tooltip } from 'components'
import { Map } from './map'
import CollectionTable from './table/CollectionTable'

import './ContentsManager.scss'

export default function ContentsManager() {
  const [params, setSearchParams] = useSearchParams()
  const [leftElement, setLeftElement] = useState<JSX.Element>(null)
  const [rightElement, setRightElement] = useState<JSX.Element>(null)
  const managerRef = useRef<HTMLDivElement>(null)
  const [splitPercent, setSplitPercent] = useState(50)
  const leftParam = params.get('vl')
  const rightParam = params.get('vr')
  const layerParams = params.get('l')
  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  // Init signal data based on new params
  const handleUpdateData = (newParams: string, key: 'left' | 'right' = 'left') => {
    const { data } = ContentSignals[key]
    if (urlEqualsToViewport(newParams, data.value)) return
    const { type, vp } = getContentForParam(newParams)
    data.value = { type, vp }
  }

  // Init signals based on url params
  useEffect(() => {
    if (!layerParams) LayersSignal.value = []
    else LayersSignal.value = layerParams.split(',')
    if (!leftParam && !rightParam) {
      handleUpdateData(`geo,${parseViewportToURL(GEO_DEFAULT_VIEWPORT)}`, 'left')
      LayersSignal.value = [...DEFAULT_LAYERS]
    }
    if (leftParam) handleUpdateData(leftParam, 'left')
    if (rightParam) handleUpdateData(rightParam, 'right')
  }, [])

  // Update params based on current content data value
  // TODO : refactor to merge left / right logic
  const handleUpdateParams = useCallback(debounce(() => {
    if (leftData.value?.type) {
      params.set('vl', `${leftData.value.type},${parseViewportToURL(leftData.value.vp)}`)
    } else {
      setLeftElement(null)
      params.delete('vl')
    }
    if (rightData.value?.type) {
      params.set('vr', `${rightData.value.type},${parseViewportToURL(rightData.value.vp)}`)
    } else {
      setRightElement(null)
      params.delete('vr')
    }
    setSearchParams(params)
  }, 500), [LayersSignal.value, setSearchParams])

  // Update element based on key and type
  const hanldeUpdateElement = (key: 'left' | 'right', type: 'geo' | 'sch' | 'table') => {
    const { data } = ContentSignals[key]
    if (key === 'left') {
      setLeftElement(type === 'table'
        ? <CollectionTable key={`${key}-table"`} />
        : <Map key={`${key}-map-${type}`} data={data} mapId={key} />)
    } else {
      setRightElement(type === 'table'
        ? <CollectionTable key={`${key}-table`} />
        : <Map key={`${key}-map-${type}`} data={data} mapId={key} />)
    }
  }

  // Update vl / vr params when content signal data changes
  // Update element only if type changes (check current element key)
  useEffect(() => {
    const leftType = leftData.value?.type
    const rightType = rightData.value?.type
    if (leftType && !(leftElement?.key === leftType)) hanldeUpdateElement('left', leftType)
    if (rightType && !(rightElement?.key === rightType)) hanldeUpdateElement('right', rightType)
    handleUpdateParams()
  }, [leftData.value, rightData.value])

  // Update layers params when layers signal data changes
  useEffect(() => {
    if (LayersSignal.value?.length !== 0) params.set('l', LayersSignal.value.join(','))
    if (LayersSignal.value && LayersSignal.value.length === 0) params.delete('l')
    setSearchParams(params)
  }, [LayersSignal.value])

  // Toggle view content type (geo / sch)
  const handleToggle = (key: 'left' | 'right', newType: 'sch' | 'geo' | 'table') => async () => {
    const { vp, type } = ContentSignals[key].data.value
    if (type === newType) return
    const newVp = await reprojectViewport({ center: [vp.longitude, vp.latitude], zoom: vp.zoom }, type, newType)
    if (!newVp) return
    const [longitude, latitude] = newVp.center
    ContentSignals[key].data.value = { type: newType, vp: { longitude, latitude, zoom: newVp.zoom, bearing: newVp.bearing } }
  }

  const getElement = (key: 'left' | 'right', element: JSX.Element, data: ContentType) => data && (
    <div
      className={`content ${key}`}
      style={{
        flexBasis: key === 'left' ? `${splitPercent}%` : `${100 - splitPercent}%`,
        width: key === 'left' ? `${splitPercent}%` : `${100 - splitPercent}%`,
      }}
    >
      <div className="content-toggler flex-center">
        <Tooltip placement="bottom" label="Vue géographique">
          <GlobeIcon className={data.type === 'geo' ? 'active' : ''} onClick={handleToggle(key, 'geo')} />
        </Tooltip>
        <Tooltip placement="bottom" label="Vue schématique">
          <MapIcon className={data.type === 'sch' ? 'active' : ''} onClick={handleToggle(key, 'sch')} />
        </Tooltip>
        <Tooltip placement="bottom" label="Tableau des collections">
          <TableIcon className={data.type === 'table' ? 'active' : ''} onClick={handleToggle(key, 'table')} />
        </Tooltip>
      </div>
      {element}
    </div>
  )

  return (
    <div
      id="contents-manager"
      className={`contents-manager flex-center ${(!!leftElement && !!rightElement) ? ' truncate' : ''}`}
      ref={managerRef}
    >
      {leftElement && getElement('left', leftElement, leftData.value)}
      {rightElement && (<Resizer minWidth={250} setSplitRatio={setSplitPercent} containerRef={managerRef} />)}
      {rightElement && getElement('right', rightElement, rightData.value)}
    </div>
  )
}
