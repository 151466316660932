/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect } from 'react'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as FavoriteSelectedIcon } from 'assets/icons/favorite-selected.svg'
import { Collection } from 'types'
import { Button, ButtonStyle } from 'components'
import {
  deleteFavoriteCollection,
  FavoritesSignal,
  getObjectFa,
  ObjectFASignal,
  postFavoriteCollection,
} from 'services'
import Chantiers from './Chantiers'
import ObjectFMR from './ObjectFMR'
import ObjectFA from './ObjectFA'
import Indices from './Indices'
import Interactions from './Interactions'
import IS from './IS'

import './CollectionPanel.scss'

type Props = {
  collection: Collection
  onBack?: () => void
}

export default function CollectionPanel({ collection, onBack }: Props) {
  const favoriteId = FavoritesSignal.collections.value.find(col => col.id === collection.id)?.favorite_id

  const handleFavorite = async () => {
    await postFavoriteCollection(collection)
  }

  useEffect(() => {
    getObjectFa(collection.indices_gep?.map(indice => indice.code_c6), 1)
  }, [collection])

  return (
    <>
      <div className="panel-title collection-title">
        {onBack && (
          <Button
            style={ButtonStyle.borderLess}
            text=""
            icon={<ArrowLeftIcon />}
            onClick={onBack}
          />
        )}
        <div className="title-container">
          <h3>
            <p className="title">
              {collection.nom_collection_gep}
              {favoriteId
                ? <FavoriteSelectedIcon onClick={() => deleteFavoriteCollection(favoriteId)} />
                : <FavoriteIcon onClick={handleFavorite} />}
            </p>
          </h3>
          <p className="code-geo">
            <b>Code Géographique : </b>
            {collection.code_geographique}
          </p>
          <p className="ouranos-id">
            <b>ID Ouranos : </b>
            {collection.id}
          </p>
        </div>
      </div>
      <div className="collection-panel hide-scroll">
        {collection?.infos_is?.length > 0 && (
          <IS collection={collection} onBack={onBack} />
        )}

        <Interactions collection={collection} />

        {collection.indices_gep?.length > 0 && (
          <Indices collection={collection} />
        )}

        {ObjectFASignal.list.value?.length > 0 && (
          <ObjectFA collection={collection} />
        )}

        {collection.collection_idf?.nom_collection_idf && (
          <Chantiers collectionIdf={collection.collection_idf} />
        )}

        {collection.fiches?.length > 0 && (
          <ObjectFMR collection={collection} />
        )}
      </div>
    </>
  )
}
