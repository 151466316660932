import { useEffect, useState } from 'react'
import terms from 'assets/terms'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import { UserSearch } from 'types'
import { deleteUser, getUsers, UserSignal } from 'services'
import {
  Button, ButtonSize, ButtonStyle, ConfirmModal, CreateUserModal, DropMenu, Loader, ModalSignal,
} from 'components'

import './UsersPanel.scss'

export default function UsersPanel() {
  const [toogledContents, setToogledContents] = useState<string[]>([])
  const loading = UserSignal.loading.value
  const list = UserSignal.list.value

  useEffect(() => {
    getUsers()
  }, [])

  const handleToggleContent = (id: string) => () => {
    if (toogledContents.includes(id)) {
      setToogledContents(toogledContents.filter(content => content !== id))
    } else {
      setToogledContents([...toogledContents, id])
    }
  }

  const handleCreateUser = () => {
    ModalSignal.value = <CreateUserModal />
  }

  const closeModal = () => { ModalSignal.value = undefined }

  const handleDelete = (user: UserSearch) => () => {
    ModalSignal.value = (
      <ConfirmModal
        title={`Supprimer l'utilisateur "${user.firstName} ${user.lastName}" ?`}
        handleClose={closeModal}
        handleValidate={async () => {
          await deleteUser(user)
          closeModal()
        }}
      />
    )
  }

  const handleUpdate = (user: UserSearch) => () => {
    ModalSignal.value = (
      <CreateUserModal user={user} />
    )
  }

  const getDropMenuActions = (user: UserSearch) => [
    { label: 'Supprimer l\'utilisateur', onClick: handleDelete(user) },
    { label: 'Modifier l\'utilisateur', onClick: handleUpdate(user) },
  ]

  const roleClass = (user: UserSearch) => {
    switch (user.role) {
      case 'MOE':
        return 'moe'
      case 'VALIDATEUR':
        return 'validator'
      case 'OBSERVATEUR':
        return 'observer'
      case 'AGENT':
        return 'agent'
      default:
        return ''
    }
  }

  return (
    <>
      <h3 className="panel-title users-panel-title">{terms.Panels.Users.title}</h3>
      <Button className="create-team" text={terms.Panels.Users.create} icon={<PlusIcon />} onClick={handleCreateUser} />
      <div className="users-panel hide-scroll">
        <table className="users">
          <thead>
            <tr>
              <th><div className="cp">{terms.Panels.Users.CP}</div></th>
              <th><div className="lastname">{terms.Panels.Users.lastname}</div></th>
              <th><div className="role">{terms.Panels.Users.role}</div></th>
              <th><div className="entity">{terms.Panels.Users.entities}</div></th>
              <th><div className="section">{terms.Panels.Users.section}</div></th>
              <th><div className="teams">{terms.Panels.Users.teams}</div></th>
              <th><div className="actions">{terms.Panels.Users.action}</div></th>
            </tr>
          </thead>
          <tbody>
            {list?.map(user => (
              <tr key={user.cerbere_id}>
                <td>{user.username}</td>
                <td>{`${user.lastName} ${user.firstName}`}</td>
                <td><span className={`role ${roleClass(user)}`}>{user.role}</span></td>
                <td>{user.entite?.nom}</td>
                <td>
                  <div className="teams">
                    {user.sections?.slice(
                      0,
                      toogledContents.includes(user.cerbere_id) ? user.sections?.length : 2,
                    ).map(({
                      id, nom,
                    }) => (
                      <span key={id}>{nom}</span>
                    ))}
                    {user.sections?.length > 2 && (
                      <Button
                        text={`${toogledContents.includes(user.cerbere_id) ? 'Voir moins' : 'Voir plus'}`}
                        onClick={handleToggleContent(user.cerbere_id)}
                        style={ButtonStyle.borderLess}
                        size={ButtonSize.xsmall}
                      />
                    )}
                  </div>
                </td>
                <td>
                  <div className="teams">
                    {user.equipes?.slice(
                      0,
                      toogledContents.includes(user.cerbere_id) ? user.equipes?.length : 2,
                    ).map(({
                      id, nom,
                    }) => (
                      <span key={id}>{nom}</span>
                    ))}
                    {user.equipes?.length > 2 && (
                      <Button
                        text={`${toogledContents.includes(user.cerbere_id) ? 'Voir moins' : 'Voir plus'}`}
                        onClick={handleToggleContent(user.cerbere_id)}
                        style={ButtonStyle.borderLess}
                        size={ButtonSize.xsmall}
                      />
                    )}
                  </div>
                </td>
                <td>
                  <DropMenu actions={getDropMenuActions(user)}>
                    <Button
                      text=""
                      style={ButtonStyle.borderLess}
                      size={ButtonSize.xsmall}
                      icon={<SettingsIcon />}
                    />
                  </DropMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <Loader />}
      </div>
    </>
  )
}
