import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import {
  Accordion, Button, ButtonSize, ButtonStyle, CodeSelectModal, ModalSignal,
} from 'components'
import { Collection, IndiceGep } from 'types'

type Props = {
  collection: Collection
}

export default function Indices({ collection }: Props) {
  const handleSelectC6 = (indice: IndiceGep) => () => {
    ModalSignal.value = (
      <CodeSelectModal
        title={`Lier l'indice ${indice.indice} - ${indice.objet}`}
        indice={indice}
        collection={collection}
      />
    )
  }

  return (
    <>
      <Accordion title="Indices GEP" defaultExpanded>
        <table>
          <thead>
            <tr>
              <th>Indice</th>
              <th>Code C6</th>
              <th>Statut</th>
              <th>Projet / Travaux</th>
            </tr>
          </thead>
          <tbody>
            {collection.indices_gep?.map(indice => (
              <tr key={indice.id}>
                <td>{indice.indice}</td>
                <td className="c6-field" style={{ minWidth: !indice.code_c6 ? '170px' : '90px' }}>
                  <Button
                    size={indice.code_c6 ? ButtonSize.small : ButtonSize.xsmall}
                    style={!indice.code_c6 ? ButtonStyle.light : ButtonStyle.borderLess}
                    text={indice.code_c6 || 'Renseigner Code C6'}
                    onClick={handleSelectC6(indice)}
                    icon={indice.code_c6 && <EditIcon />}
                  />
                </td>
                <td>{indice.categorie}</td>
                <td>{indice.objet}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
      <div className="separator" />
    </>
  )
}
