import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { useControl, useMap } from '@vis.gl/react-maplibre'
import { editorStyles } from 'assets/editorStyles'
import {
  cancelPerimeterDraw,
  handleConfirmEditGeometry,
  PerimeterSignals, startPerimeterDraw, updatePerimeterFeatures,
  updatePerimeterViewport,
} from 'services'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { Button, ButtonStyle, ConfirmModal, ModalSignal, SavePerimeterModal } from 'components'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import PerimeterInfoPanel from 'pages/home/panels/perimeterInfo/PerimeterInfoPanel'

import './EditorLayer.scss'
import { Feature } from 'geojson'
import { useEffect } from 'react'

export default function EditorLayer() {
  const maps = useMap()
  const draw = useControl(() => new MapboxDraw({
    displayControlsDefault: false,
    controls: {},
    defaultMode: 'draw_polygon',
    styles: editorStyles,
  }), {
    position: 'top-right',
  })

  useEffect(() => {
    const map = maps.current?.getMap()
    const updateFeatures = (e : { features: Feature[] }) => {
      updatePerimeterFeatures(e.features)
    }

    // Auto switch to direct_select mode when a feature is selected
    // This is to avoid the user to have to click on the feature to edit it
    const handleModeChange = () => {
      if (!draw) return
      const firstSelectedId = draw.getSelectedIds()?.[0]
      const mode = draw.getMode()
      if (firstSelectedId && mode === 'simple_select') {
        draw.changeMode('direct_select', { featureId: firstSelectedId })
      }
    }

    if (map) {
      map.on('draw.create', updateFeatures)
      map.on('draw.update', updateFeatures)
      map.on('draw.selectionchange', handleModeChange)
    }
    return () => {
      if (map) {
        map.off('draw.create', updateFeatures)
        map.off('draw.update', updateFeatures)
        map.off('draw.selectionchange', handleModeChange)
      }
    }
  }, [draw])

  useEffect(() => {
    const drawFeatures = draw.getAll()
    PerimeterSignals.features.value?.forEach(feature => {
      if (!drawFeatures.features.find(f => f.id === feature.id)) {
        draw.add(feature)
        draw.changeMode('simple_select', { featureIds: [feature.id] })
      }
    })
  }, [PerimeterSignals.features.value])

  const handleClear = () => {
    if (PerimeterSignals.patchingPerimeter.value) {
      draw.set({
        type: 'FeatureCollection',
        features: [PerimeterSignals.patchingPerimeter.value],
      })
    } else {
      draw.deleteAll()
      draw.changeMode('draw_polygon')
      startPerimeterDraw()
    }
  }

  return (
    <div className="editor-layer">
      <div className="editor-layer-actions">
        <Button
          text="Abandonner"
          icon={(<CloseIcon />)}
          onClick={cancelPerimeterDraw}
          style={ButtonStyle.secondary}
        />
        <Button
          text="Recommencer"
          icon={(<RefreshIcon />)}
          onClick={handleClear}
          style={ButtonStyle.secondary}
        />
        <Button
          text="Continuer"
          icon={(<ArrowRightIcon />)}
          disabled={PerimeterSignals.selfIntersecting.value || !PerimeterSignals.features.value?.length}
          onClick={() => {
            if (PerimeterSignals.patchingPerimeter.value) {
              ModalSignal.value = (
                <ConfirmModal
                  title="Voulez-vous confirmer la modification de votre périmètre ?"
                  handleClose={() => { ModalSignal.value = undefined }}
                  handleValidate={async () => {
                    const [error, perim] = await handleConfirmEditGeometry()
                    if (error) return
                    ModalSignal.value = undefined
                    updatePerimeterViewport(perim, maps)
                    PanelSignal.value = <PerimeterInfoPanel perimeter={perim} />
                  }}
                />
              )
            } else {
              ModalSignal.value = (
                <SavePerimeterModal perimeterGeometry={PerimeterSignals.features.value} />
              )
            }
          }}
        />
      </div>
    </div>
  )
}
