import { Layer } from '@vis.gl/react-maplibre'
import { CHARTIS_SOURCES_IDS, getDistinctUTMCentroid, MAPBOX_LAYER_IDS, MapDataSignal, useBorderLabels } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'

const LABEL_SOURCE_ID = 'utm-labels'

export default function UTMLayer({ view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useBorderLabels([MAPBOX_LAYER_IDS.utm], visibility, LABEL_SOURCE_ID, getDistinctUTMCentroid)
  useSource(
    CHARTIS_SOURCES_IDS.utm,
    view,
    {
      ...perimeterParam,
      type_structure_libelle: 'Unité de production',
    },
  )
  const hoveredObjectsIds = useHoveredObjectsIds('structure_libelle')

  const { opacity, lineFilter } = MapDataSignal

  return (
    <>
      <Layer
        type="line"
        paint={{
          'line-color': ['get', 'color'],
          'line-opacity': [
            'case',
            ['in', lineFilter.value, ['get', 'code_ligne']], 1,
            opacity,
          ],
          'line-width': [
            'case',
            ['in', ['get', 'structure_libelle'], ['literal', hoveredObjectsIds]], 4,
            2,
          ],
        }}
        id={MAPBOX_LAYER_IDS.utm}
        source-layer={CHARTIS_SOURCES_IDS.utm}
        source={CHARTIS_SOURCES_IDS.utm}
        layout={{
          visibility: visibility ? 'visible' : 'none',
          'line-sort-key': ['case', ['in', ['get', 'structure_libelle'], ['literal', hoveredObjectsIds]], 0, -1],
        }}
      />
      <Layer
        type="symbol"
        id={MAPBOX_LAYER_IDS.utmLabel}
        source={LABEL_SOURCE_ID}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-field': '{structure_libelle}',
          'text-size': 14,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-offset': [0, 1],
          visibility: visibility ? 'visible' : 'none',
          'text-allow-overlap': true,
        }}
        paint={{
          'text-color': ['get', 'color'],
          'text-halo-color': ['get', 'color'],
          'text-halo-width': 0.5,
        }}
        maxzoom={24}
        minzoom={8}
      />
    </>
  )
}
