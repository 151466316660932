/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import terms from 'assets/terms'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg'
import { TextInput, Loader, Tooltip } from 'components'
import { shouldBreakWord, useMountEffect } from 'utils'
import { Collection } from 'types'
import { CollectionSignal, getCollection, getCollections, getNextCollections } from 'services/collections'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import CollectionPanel from 'pages/home/panels/collection/CollectionPanel'

import './CollectionTable.scss'
import { ContentSignals } from 'services'

export default function CollectionTable() {
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState('')
  const [ordering, setOrdering] = useState('infos_is__libelle_long')
  useMountEffect(() => getCollections(search, ordering))
  const collections = CollectionSignal.lists.value
  const isLoading = CollectionSignal.loading.value
  const panelOpenned = !!PanelSignal.value
  const viewDualDisplayed = !!ContentSignals.left.data.value && !!ContentSignals.right.data.value

  const handleSearch = useCallback(debounce((value: string) => {
    setSearch(value)
    getCollections(value, ordering)
  }, 500), [ordering])

  const handleDisplay = (collection: Collection) => async () => {
    const fetchedCollection = await getCollection(collection.id)
    if (fetchedCollection) {
      PanelSignal.value = <CollectionPanel collection={fetchedCollection} />
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (tableWrapperRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = tableWrapperRef.current
        if (scrollTop + clientHeight >= scrollHeight - 100 && !CollectionSignal.loading.value) {
          getNextCollections()
        }
      }
    }

    tableWrapperRef.current?.addEventListener('scroll', handleScroll)

    return () => {
      tableWrapperRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [tableWrapperRef.current])

  useEffect(() => {
    getCollections(search, ordering)
  }, [ordering])

  const renderOrdering = (key: string) => (
    <div className="ordering">
      {ordering === key && <Arrow style={{ transform: 'rotate(90deg)' }} />}
      {ordering === `-${key}` && <Arrow style={{ transform: 'rotate(270deg)' }} />}
      {ordering !== key && ordering !== `-${key}` && (
        <Arrow className="hidden" style={{ transform: 'rotate(90deg)' }} />)}
    </div>
  )

  const handleChangeOrdering = (key: string) => () => {
    if (ordering === key) {
      setOrdering(`-${key}`)
    } else {
      setOrdering(key)
    }
  }

  return (
    <div className="collections">
      <TextInput
        label={`${terms.Common.search} une IS`}
        onChange={handleSearch}
        bindedValue={search}
        LeftIcon={<SearchIcon />}
        RightIcon={search && isLoading && <Loader variant="x-small" />}
      />
      <div
        className={`table-wrapper hide-scroll ${panelOpenned || viewDualDisplayed ? 'panel-openned' : ''}`}
        ref={tableWrapperRef}
      >
        <table>
          <thead>
            <tr>
              <th onClick={handleChangeOrdering('nom_collection_gep')}>
                <div>
                  <div className="label">Nom collection</div>
                  {renderOrdering('nom_collection_gep')}
                </div>
              </th>
              <th onClick={handleChangeOrdering('code_geographique')}>
                <div>
                  <div className="code">Code Géo</div>
                  {renderOrdering('code_geographique')}
                </div>
              </th>
              <th onClick={handleChangeOrdering('infos_is__libelle_long')}>
                <div>
                  <div className="type">Nom des IS</div>
                  {renderOrdering('infos_is__libelle_long')}
                </div>
              </th>
              <th onClick={handleChangeOrdering('infos_is__poste__type_installation_fixe__mnemo')}>
                <div>
                  <div className="techno">Technologie</div>
                  {renderOrdering('infos_is__poste__type_installation_fixe__mnemo')}
                </div>
              </th>
              <th onClick={handleChangeOrdering('infos_is__ligne_id')}>
                <div>
                  <div className="ferroloc">Ferrolocalisation</div>
                  {renderOrdering('infos_is__ligne_id')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {collections?.map(collection => (
              <tr className="item" key={collection.id} onClick={handleDisplay(collection)}>
                <td>
                  <Tooltip label={collection.nom_collection_gep} placement="top">
                    <div className={`label${shouldBreakWord(collection.nom_collection_gep) ? ' break-word' : ''}`}>
                      {collection.nom_collection_gep}
                    </div>
                  </Tooltip>
                </td>
                <td>
                  <div className="code">
                    <span>{collection.code_geographique}</span>
                  </div>
                </td>
                <td>
                  <div className="type">
                    {collection.infos_is.map(is => (
                      <span key={is.id}>{is.libelle_long}</span>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="techno">
                    {collection.infos_is.map(is => (
                      <span key={is.id}>{is.poste_technologie_mnemo}</span>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="ferroloc">
                    {collection.infos_is.map(is => (
                      <span key={is.id}>{is.ligne_id}</span>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
            {isLoading && [...Array(15)].map((_, i) => (
              <tr key={`skeleton${i}`} className="skeleton">
                <td><div className="label" /></td>
                <td><div className="code" /></td>
                <td><div className="type" /></td>
                <td><div className="techno" /></td>
                <td><div className="ferroloc" /></td>
              </tr>
            ))}
          </tbody>
        </table>
        {collections?.length === 0 && !isLoading && (
          <h3>Aucune collection trouvée</h3>
        )}
      </div>
    </div>
  )
}
