import { useState, MouseEvent } from 'react'
import terms from 'assets/terms'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import {
  Button, ButtonSize, ButtonStyle, ConfirmModal, CreateTeamModal, DropMenu, Loader, ModalSignal, TeamMembersModal,
} from 'components'
import { deleteTeam, getTeams, TeamSignal } from 'services/users'
import { useMountEffect } from 'utils'
import { Team } from 'types'

export default function Teams() {
  useMountEffect(getTeams)
  const [toogledContents, setToogledContents] = useState<string[]>([])
  const loading = TeamSignal.loading.value
  const list = TeamSignal.list.value

  const handleToggleContent = (id: string) => (e: MouseEvent) => {
    e.stopPropagation()
    if (toogledContents.includes(id)) {
      setToogledContents(toogledContents.filter(content => content !== id))
    } else {
      setToogledContents([...toogledContents, id])
    }
  }

  const handleDisplayMembers = (team: Team) => () => {
    ModalSignal.value = [<TeamMembersModal teamId={team.id} key={0} />]
  }

  const closeModal = () => { ModalSignal.value = undefined }

  const handleUpdate = (team: Team) => () => {
    ModalSignal.value = (
      <CreateTeamModal team={team} />
    )
  }

  const handleDeleteTeam = (team: Team) => () => {
    ModalSignal.value = (
      <ConfirmModal
        title={`Supprimer l'équipe "${team.nom}" ?`}
        handleClose={closeModal}
        handleValidate={async () => {
          await deleteTeam(team.id)
          closeModal()
        }}
      />
    )
  }

  const getDropMenuActions = (team: Team) => [
    { label: 'Supprimer l\'équipe', onClick: handleDeleteTeam(team) },
    { label: 'Modifier l\'équipe', onClick: handleUpdate(team) },
  ]

  return (
    <div className="teams-panel hide-scroll">
      <table className="teams">
        <thead>
          <tr>
            <th><div className="name">{terms.Panels.TeamsManager.teams.name}</div></th>
            <th><div className="entity">{terms.Panels.TeamsManager.teams.entities}</div></th>
            <th><div className="owner">{terms.Panels.TeamsManager.teams.owner}</div></th>
            <th><div className="actions">{terms.Panels.TeamsManager.teams.action}</div></th>
          </tr>
        </thead>
        <tbody>
          {list?.map(team => (
            <tr key={team.id} onClick={handleDisplayMembers(team)}>
              <td>{team.nom}</td>
              <td>
                <div className="toggable-content">
                  {team.entites.slice(0, toogledContents.includes(team.id) ? team.entites.length : 2).map(e => (
                    <span key={e.id}>{`${e.nom} - ${e.type}`}</span>
                  ))}
                  {team.entites.length > 2 && (
                    <Button
                      text={`${toogledContents.includes(team.id) ? 'Voir moins' : 'Voir plus'}`}
                      onClick={handleToggleContent(team.id)}
                      style={ButtonStyle.borderLess}
                      size={ButtonSize.xsmall}
                    />
                  )}
                  {team.entites.length === 0 && 'N/C'}
                </div>
              </td>
              <td>
                <div className="toggable-content">
                  {team.users
                    .filter(user => user.type === 'leader')
                    .slice(0, toogledContents.includes(team.id) ? team.users.length : 2)
                    .map(user => (
                      <span key={user.user}>{user.displayName}</span>
                    ))}
                  {team.users?.filter(user => user.type === 'leader').length > 2 && (
                    <Button
                      text={`${toogledContents.includes(team.id) ? 'Voir moins' : 'Voir plus'}`}
                      onClick={handleToggleContent(team.id)}
                      style={ButtonStyle.borderLess}
                      size={ButtonSize.xsmall}
                    />
                  )}
                  {team.users?.filter(user => user.type === 'leader').length === 0 && 'N/C'}
                </div>
              </td>
              <td>
                <DropMenu actions={getDropMenuActions(team)}>
                  <Button
                    text=""
                    style={ButtonStyle.borderLess}
                    size={ButtonSize.xsmall}
                    icon={<SettingsIcon />}
                  />
                </DropMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <Loader />}
    </div>
  )
}
