import { NavigationControl, ScaleControl, useMap } from '@vis.gl/react-maplibre'
import { Signal } from '@preact/signals-core'
import { ReactComponent as ZoomOutIcon } from 'assets/icons/zoom-out.svg'
import { ReactComponent as GeoSyncIcon } from 'assets/icons/geo-sync.svg'
import { ContentType } from 'types'
import {
  ContentSignals,
  PerimeterSignals,
  reprojectViewport,
  GEO_DEFAULT_VIEWPORT,
  SCH_DEFAULT_VIEWPORT,
} from 'services'

import './MapControls.scss'
import bbox from '@turf/bbox'

type Props = {
  data: Signal<Omit<ContentType, 'id'>>
}

export default function MapControls({ data }: Props) {
  const map = useMap()

  const handleZoomOut = async () => {
    if (PerimeterSignals.activePerimeter.value) {
      const perimeterBbox = bbox(PerimeterSignals.activePerimeter.value.geometry)
      const coordinates = [perimeterBbox[0], perimeterBbox[1], perimeterBbox[2], perimeterBbox[3]]
      const newVs = map.current.cameraForBounds(coordinates as [number, number, number, number])
      if (data.value.type === 'geo') {
        const geoVs = await reprojectViewport(newVs, 'sch', 'geo')
        map.current.flyTo({ center: geoVs.center, zoom: newVs.zoom, animate: false })
      } else {
        map.current.flyTo({ center: newVs.center, zoom: newVs.zoom, animate: false })
      }
    } else {
      const defaultViewport = data.value.type === 'geo' ? GEO_DEFAULT_VIEWPORT : SCH_DEFAULT_VIEWPORT
      map.current.flyTo({ center: [defaultViewport.longitude, defaultViewport.latitude], zoom: defaultViewport.zoom })
    }
  }

  const handleGeoSync = async () => {
    const [leftData, rightData] = [ContentSignals.left.data, ContentSignals.right.data]
    const oppositeData = leftData.value.vp.latitude === data.value.vp.latitude ? rightData : leftData

    if (oppositeData.value.type === data.value.type) {
      map.current.flyTo({
        center: [oppositeData.value.vp.longitude, oppositeData.value.vp.latitude],
        zoom: oppositeData.value.vp.zoom,
      })
    } else {
      const newVp = await reprojectViewport(
        { center: [oppositeData.value.vp.longitude, oppositeData.value.vp.latitude],
          zoom: oppositeData.value.vp.zoom },
        oppositeData.value.type,
        data.value.type,
      )
      map.current.flyTo({ center: newVp.center, zoom: newVp.zoom })
    }
  }

  return (
    <div className="map-controls">
      {data.value.type === 'geo' && (
        <div className="scale-control flex-center">
          <ScaleControl
            maxWidth={70}
            unit="metric"
            position="bottom-right"
            style={{ position: 'absolute', bottom: '90px', right: '60px', transform: 'translateX(50%)' }}
          />
        </div>
      )}
      <div className="map-control-buttons">
        {['geo', 'sch'].includes(ContentSignals.left.data.value?.type)
        && ['geo', 'sch'].includes(ContentSignals.right.data.value?.type)
        && (
          <div className="map-control-button geo-sync">
            <button
              type="button"
              className="flex-center"
              onClick={handleGeoSync}
            >
              <GeoSyncIcon />
            </button>
          </div>
        )}
        <div className="map-control-button">
          <button
            type="button"
            className="flex-center"
            onClick={handleZoomOut}
          >
            <ZoomOutIcon />
          </button>
        </div>
        <NavigationControl
          showZoom={false}
          visualizePitch
          visualizeRoll
          position="bottom-right"
          style={{
            position: 'absolute',
            bottom: '40px',
            right: '24px',
            margin: '0',
            boxShadow: '0px 1px 7.7px -2px #00000026',
            borderRadius: '8px',
          }}
        />
      </div>
    </div>
  )
}
