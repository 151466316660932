/* eslint-disable max-len */
import { Signal, signal } from '@preact/signals-react'
import { get, post } from '@osrdata/app_core/dist/requests'
import bbox from '@turf/bbox'
import { Feature, Geometry, Point } from 'geojson'
import { MapRef } from '@vis.gl/react-maplibre'
import { MapCollection } from '@vis.gl/react-maplibre/dist/components/use-map'
import { ContentType, MapFeature, ObjectCount, PaginatedResponse, Perimeter } from 'types'
import { catchable } from 'utils'
import { CenterZoomBearing, FilterSpecification, LngLat } from 'maplibre-gl'
import { SIGNAL_LAYERS_MNEMO } from 'assets/layers'
import { getIndexForSlug, LayersSignal } from './layers'
import { ContentSignals } from './contents'

export const MapDataSignal = {
  hoveredObjects: signal<{properties: Feature['properties']}[]>(),
  targetedObject: signal<MapFeature>(),
  lineFilter: signal<string>(''),
  opacity: 0.5,
}

export const SCH_DEFAULT_VIEWPORT = {
  latitude: 46.39,
  longitude: 2.57,
  zoom: 5.44,
  bearing: 0,
  pitch: 0,
}

export const GEO_DEFAULT_VIEWPORT = {
  latitude: 46.92,
  longitude: 1.50,
  zoom: 5.46,
  bearing: 0,
  pitch: 0,
}

export const DEFAULT_LAYERS = [
  '1.1', '1.3', '5.1', '5.4.1', '5.4.2', '5.4.2.1', '5.4.2.2', '5.4.2.3', '5.4.3', '5.4.3.1', '5.4.3.2', '5.4.3.3',
  '5.4.3.4', '5.4.4', '5.4.4.1', '5.4.4.2', '5.4.4.3', '5.4.4.4', '5.4.5', '5.4.5.1', '5.4.5.2', '5.4.5.3', '5.4.5.4',
  '5.4.5.5', '5.4.5.6', '5.4.5.7', '5.4.5.8', '5.4.6', '5.4.6.1', '5.4.6.2', '5.4.6.3', '5.4.6.4', '5.4.6.5', '5.4',
  '3.1.1', '3.1.2', '3.1.3', '3.1.4', '3.1.5', '3.1.6', '3.1.7', '3.1.8', '3.1.9', '3.1.10', '3.1', '3.2', '3.3',
  '3.4', '3.7', '3.8',
]

export const CHARTIS_SOURCES_IDS = {
  trackGeo: 'cassini_v2_rgi_track_geo',
  trackSch: 'cassini_v2_rgi_track_sch_flat',
  adv: 'cassini_v2_gaia_appareil_de_voie',
  advLine: 'cassini_v2_gaia_appareil_de_voie_lineaire_20',
  localisateur: 'cassini_v2_gaia_localisateur',
  pedale: 'cassini_v2_gaia_pedale',
  acces: 'cassini_v2_gaia_acces',
  bif: 'cassini_v2_gaia_point_remarquable',
  dbc: 'cassini_v2_gaia_detecteur_boite_chaude',
  passageNiveau: 'cassini_v2_gaia_passage_a_niveau',
  jdz: 'cassini_v2_gaia_joint_de_zone',
  station: 'cassini_v2_gaia_gare',
  sousStation: 'cassini_v2_gaia_sous_station',
  css: 'cassini_v2_gaia_central_sous_station',
  exploitationMode: 'cassini_v2_gaia_habillage_valeur_margin_100',
  signal: 'cassini_v2_gaia_signal',
  cdv: 'cassini_v2_gaia_circuit_de_voie',
  rac: 'cassini_v2_gaia_ligne',
  poste: 'dexcarto_poste',
  chantier: 'dexcarto_chantier',
  zap: 'dexcarto_zone_action_poste',
  bal: 'dexcarto_zone_bal',
  ihm: 'dexcarto_zone_ihm',
  center: 'dexcarto_centres_appareillage',
  utm: 'dexcarto_ouranos_habillage_structure',
  pri: 'dexcarto_ouranos_habillage_region',
  structure: 'dexcarto_gaia_structure',
} as const

export type ChartisSource = typeof CHARTIS_SOURCES_IDS[keyof typeof CHARTIS_SOURCES_IDS]

export const MAP_IDS_SLUGS: {[key in ChartisSource]: string[]} = {
  [CHARTIS_SOURCES_IDS.trackGeo]: [],
  [CHARTIS_SOURCES_IDS.trackSch]: [],
  [CHARTIS_SOURCES_IDS.adv]: ['adv'],
  [CHARTIS_SOURCES_IDS.advLine]: ['adv'],
  [CHARTIS_SOURCES_IDS.localisateur]: ['localisateur'],
  [CHARTIS_SOURCES_IDS.pedale]: ['pedale'],
  [CHARTIS_SOURCES_IDS.acces]: ['acces'],
  [CHARTIS_SOURCES_IDS.bif]: ['bif'],
  [CHARTIS_SOURCES_IDS.dbc]: ['dbc'],
  [CHARTIS_SOURCES_IDS.passageNiveau]: ['passageNiveau'],
  [CHARTIS_SOURCES_IDS.jdz]: ['jdz'],
  [CHARTIS_SOURCES_IDS.station]: ['station'],
  [CHARTIS_SOURCES_IDS.sousStation]: ['sousStation'],
  [CHARTIS_SOURCES_IDS.css]: ['css'],
  [CHARTIS_SOURCES_IDS.exploitationMode]: ['exploitation-mode-unique, exploitation-mode-banalisee, exploitation-mode-ipcs'],
  [CHARTIS_SOURCES_IDS.signal]: ['Signaux', 'signalProtection', 'signalArretAnnonce', 'signalLimitationDeVitesse', 'signalManoeuvre', 'signalIndicateurDirection', 'signalDepartTrain', 'signalSortieGroupesVoiesConvergentes', 'signalTGV', 'signalTractionElectrique', 'signalTableauPancarte'],
  [CHARTIS_SOURCES_IDS.cdv]: ['cdv'],
  [CHARTIS_SOURCES_IDS.rac]: ['rac'],
  [CHARTIS_SOURCES_IDS.poste]: ['Poste Majeurs', 'poste-cc', 'Postes VP - informatisé', 'poste-argos', 'poste-pai', 'poste-pipc', 'Postes VP - à relais', 'poste-prci', 'poste-prs', 'poste-prg', 'poste-prmi', 'Postes VP - électrique', 'poste-plit', 'poste-peli', 'poste-pelir', 'poste-pml', 'Postes VP - mécanique', 'poste-emu', 'poste-mu45', 'poste-mrsaxby', 'poste-mrlevdr', 'poste-mrvignty', 'poste-mrexal', 'poste-ssdv', 'poste-ssvuvb', 'Postes VS (service)', 'poste-pvstri', 'poste-pvselec', 'poste-pvsei', 'poste-pvsci', 'poste-pvsmec', 'Poste Autres', 'poste-point', 'poste-levier', 'poste-p10', 'poste-autres'],
  [CHARTIS_SOURCES_IDS.chantier]: ['Travaux', 'Par année de travaux', 'chantier-2024', 'chantier-2025', 'chantier-2026', 'chantier-2027', 'chantier-2028', 'chantier-2029', 'chantier-2030-plus', 'Par phase', 'chantier-phase-rea', 'chantier-phase-tvx', 'chantier-phase-tvxp', 'chantier-phase-rea-tvxp', 'chantier-phase-rea-tvx', 'chantier-phase-apo-rea', 'chantier-phase-apo-tvx', 'chantier-phase-apo-rea-tvx', 'chantier-phase-pro-rea'],
  [CHARTIS_SOURCES_IDS.zap]: ['zoneActionPoste'],
  [CHARTIS_SOURCES_IDS.bal]: ['bal-zone'],
  [CHARTIS_SOURCES_IDS.ihm]: ['ihm-zone'],
  [CHARTIS_SOURCES_IDS.center]: ['center'],
  [CHARTIS_SOURCES_IDS.utm]: ['utm'],
  [CHARTIS_SOURCES_IDS.pri]: ['pri'],
  [CHARTIS_SOURCES_IDS.structure]: ['zp', 'infrapole'],
}

export const SOURCE_NAMES = {
  [CHARTIS_SOURCES_IDS.signal]: 'Signalisation',
  [CHARTIS_SOURCES_IDS.localisateur]: 'Localisateur',
  [CHARTIS_SOURCES_IDS.passageNiveau]: 'Passage à niveau',
  [CHARTIS_SOURCES_IDS.acces]: 'Accès',
  [CHARTIS_SOURCES_IDS.dbc]: 'Détecteur de boîte chaude',
  [CHARTIS_SOURCES_IDS.pedale]: 'Pédale',
  [CHARTIS_SOURCES_IDS.sousStation]: 'Sous-station',
  [CHARTIS_SOURCES_IDS.adv]: 'Appareil de voie',
  [CHARTIS_SOURCES_IDS.station]: 'Gare',
  [CHARTIS_SOURCES_IDS.jdz]: 'Joint de zone',
  [CHARTIS_SOURCES_IDS.cdv]: 'Circuit de voie',
  [CHARTIS_SOURCES_IDS.rac]: 'Ligne',
  [CHARTIS_SOURCES_IDS.bif]: 'Point remarquable',
  [CHARTIS_SOURCES_IDS.poste]: 'Poste',
  [CHARTIS_SOURCES_IDS.chantier]: 'Projets travaux',
  [CHARTIS_SOURCES_IDS.zap]: 'Zone d\'action de poste',
  [CHARTIS_SOURCES_IDS.bal]: 'Tronçons',
  [CHARTIS_SOURCES_IDS.ihm]: 'Zone IHM',
  [CHARTIS_SOURCES_IDS.center]: 'Centres d\'appareillage',
  [CHARTIS_SOURCES_IDS.css]: 'Centrales sous-station',
}

export const MAPBOX_LAYER_IDS = {
  trackVp: 'track-vp',
  trackVs: 'track-vs',
  trackNameVS: 'track-name-vs',
  trackNameVP: 'track-name-vp',
  lineNumber: 'line-number',
  advCircle: 'adv-circle',
  advHighlight: 'adv-highlight',
  advLabel: 'adv-label',
  advLine: 'adv-line',
  localisateurCircle: 'localisateur-circle',
  localisateurHighlight: 'localisateur-highlight',
  localisateurLabel: 'localisateur-label',
  pedaleCircle: 'pedale-circle',
  pedaleHighlight: 'pedale-highlight',
  pedaleLabel: 'pedale-label',
  accesCircle: 'acces-circle',
  accesHighlight: 'acces-highlight',
  accesLabel: 'acces-label',
  bifCircle: 'bif-circle',
  bifHighlight: 'bif-highlight',
  bifLabel: 'bif-label',
  dbc: 'dbc',
  passageNiveau: 'passage-niveau',
  jdz: 'jdz',
  station: 'station',
  sousStationCircle: 'sous-station-circle',
  sousStationRect: 'sous-station-rect',
  cssCircle: 'css-circle',
  cssRect: 'css-rect',
  exploitationMode: 'exploitation-mode',
  signalCircle: 'signal-circle',
  signalHighlight: 'signal-highlight',
  signalMat: 'signal-mat',
  signalPicto: 'signal-picto',
  signalLabel: 'signal-label',
  cdvLine: 'cdv-line',
  cdvLabel: 'cdv-label',
  racLine: 'rac-line',
  racLabel: 'rac-label',
  posteCircle: 'poste-circle',
  posteLabel: 'poste-label',
  posteCollectionStatus: 'poste-collection-status',
  chantierLine: 'chantier-line',
  chantierPoint: 'chantier-point',
  zapFill: 'zap-fill',
  zapOutline: 'zap-outline',
  zapLabel: 'zap-label',
  balFill: 'bal-fill',
  balOutline: 'bal-outline',
  balDot: 'bal-dot',
  balLabel: 'bal-label',
  ihmFill: 'ihm-fill',
  ihmOutline: 'ihm-outline',
  ihmLabel: 'ihm-label',
  centerCircle: 'center-circle',
  centerHighlight: 'center-highlight',
  centerLabel: 'center-label',
  utm: 'utm',
  utmLabel: 'utm-label',
  pri: 'pri',
  priLabel: 'pri-label',
  zp: 'zp',
  infrapole: 'infrapole',
  zpLabel: 'zp-label',
  infrapoleLabel: 'infrapole-label',
}

export const INTERACTIVE_LAYER_IDS = [
  MAPBOX_LAYER_IDS.advCircle,
  MAPBOX_LAYER_IDS.advHighlight,
  MAPBOX_LAYER_IDS.advLabel,
  MAPBOX_LAYER_IDS.advLine,
  MAPBOX_LAYER_IDS.localisateurCircle,
  MAPBOX_LAYER_IDS.localisateurHighlight,
  MAPBOX_LAYER_IDS.localisateurLabel,
  MAPBOX_LAYER_IDS.pedaleCircle,
  MAPBOX_LAYER_IDS.pedaleHighlight,
  MAPBOX_LAYER_IDS.pedaleLabel,
  MAPBOX_LAYER_IDS.accesCircle,
  MAPBOX_LAYER_IDS.accesHighlight,
  MAPBOX_LAYER_IDS.accesLabel,
  MAPBOX_LAYER_IDS.bifCircle,
  MAPBOX_LAYER_IDS.bifHighlight,
  MAPBOX_LAYER_IDS.bifLabel,
  MAPBOX_LAYER_IDS.dbc,
  MAPBOX_LAYER_IDS.passageNiveau,
  MAPBOX_LAYER_IDS.jdz,
  MAPBOX_LAYER_IDS.station,
  MAPBOX_LAYER_IDS.sousStationCircle,
  MAPBOX_LAYER_IDS.sousStationRect,
  MAPBOX_LAYER_IDS.cssCircle,
  MAPBOX_LAYER_IDS.cssRect,
  MAPBOX_LAYER_IDS.cdvLine,
  MAPBOX_LAYER_IDS.cdvLabel,
  MAPBOX_LAYER_IDS.racLine,
  MAPBOX_LAYER_IDS.racLabel,
  MAPBOX_LAYER_IDS.signalCircle,
  MAPBOX_LAYER_IDS.signalHighlight,
  MAPBOX_LAYER_IDS.signalMat,
  MAPBOX_LAYER_IDS.signalPicto,
  MAPBOX_LAYER_IDS.signalLabel,
  MAPBOX_LAYER_IDS.posteCircle,
  MAPBOX_LAYER_IDS.posteLabel,
  MAPBOX_LAYER_IDS.posteCollectionStatus,
  MAPBOX_LAYER_IDS.chantierLine,
  MAPBOX_LAYER_IDS.chantierPoint,
  MAPBOX_LAYER_IDS.zapFill,
  MAPBOX_LAYER_IDS.zapLabel,
  MAPBOX_LAYER_IDS.balFill,
  MAPBOX_LAYER_IDS.ihmFill,
  MAPBOX_LAYER_IDS.ihmLabel,
  MAPBOX_LAYER_IDS.centerCircle,
  MAPBOX_LAYER_IDS.centerLabel,
  MAPBOX_LAYER_IDS.utm,
  MAPBOX_LAYER_IDS.utmLabel,
  MAPBOX_LAYER_IDS.pri,
  MAPBOX_LAYER_IDS.priLabel,
  MAPBOX_LAYER_IDS.infrapole,
  MAPBOX_LAYER_IDS.infrapoleLabel,
  MAPBOX_LAYER_IDS.zp,
  MAPBOX_LAYER_IDS.zpLabel,
]

export const HOVER_ONLY_LAYER_IDS = [
  MAPBOX_LAYER_IDS.utm,
  MAPBOX_LAYER_IDS.utmLabel,
  MAPBOX_LAYER_IDS.pri,
  MAPBOX_LAYER_IDS.priLabel,
  MAPBOX_LAYER_IDS.zp,
  MAPBOX_LAYER_IDS.zpLabel,
  MAPBOX_LAYER_IDS.infrapole,
  MAPBOX_LAYER_IDS.infrapoleLabel,
]

const postReprojectPoints = (
  points: Point[],
  fromProjection: string,
  toProjection: string,
) => catchable(() => post<Point[]>(
  `/cassini-v2/locate/${fromProjection}/reproject_point/${toProjection}/`,
  points,
))

const getCamera = (camera: CenterZoomBearing) => {
  const { center } = camera
  if (center instanceof LngLat) {
    const { lng, lat } = center
    return { ...camera, center: [lng, lat] as [number, number] }
  } if (center instanceof Array) {
    const [lng, lat] = center
    return { ...camera, center: [lng, lat] as [number, number] }
  } if ('lon' in center) {
    const { lon, lat } = center
    return { ...camera, center: [lon, lat] as [number, number] }
  }
  return { ...camera, center: [center.lng, center.lat] as [number, number] }
}

export const reprojectViewport = async (
  camera: CenterZoomBearing,
  fromType: 'sch' | 'geo' | 'table',
  toType: 'sch' | 'geo' | 'table',
) => {
  if (!camera.center || !camera.zoom) return null
  const cleanCamera = getCamera(camera)
  const [longitude, latitude] = cleanCamera.center
  const fromProjection = fromType === 'sch' ? 'rgi_track_sch_flat' : 'rgi_track_geo'
  const toProjection = toType === 'sch' ? 'rgi_track_sch_flat' : 'rgi_track_geo'
  const points = [{ type: 'Point', coordinates: [longitude, latitude] } as Point]
  const [error, response] = await postReprojectPoints(points, fromProjection, toProjection)

  if (error) return cleanCamera
  const zoomDelta = cleanCamera.zoom > 8 ? cleanCamera.zoom / 10 : 0

  return {
    ...cleanCamera,
    center: [response[0].coordinates[0], response[0].coordinates[1]] as [number, number],
    zoom: toType === 'geo' ? cleanCamera.zoom + zoomDelta : cleanCamera.zoom - zoomDelta,
  }
}

export const getChartisFeature = async (id: string, layer: string, view: string) => {
  const [error, response] = await catchable<Feature>(() => get(`/chartis/v2/layer/${layer}/geojson_feature/${view}/`, { id }))

  if (error) return undefined

  return response
}

export const getGeoTrackFilter = (activePerimeter: Perimeter): FilterSpecification => {
  if (!activePerimeter?.properties?.lvp_perimeter) return undefined
  if (activePerimeter.properties.lvp_perimeter.length === 0) return undefined
  const filters = activePerimeter.properties.lvp_perimeter.map(lvp => {
    const filter: FilterSpecification = ['all',
      ['==', ['get', 'ligne'], lvp.line_code],
      ['==', ['get', 'voie'], lvp.track_name],
      ['<=',
        ['to-number',
          ['concat',
            ['slice', ['get', 'pk_debut'], 0, -4],
            ['slice', ['get', 'pk_debut'], -3],
          ],
        ],
        Number(lvp.end_pk.replace('+', '')),
      ],
      ['>=',
        ['to-number',
          ['concat',
            ['slice', ['get', 'pk_fin'], 0, -4],
            ['slice', ['get', 'pk_fin'], -3],
          ],
        ],
        Number(lvp.start_pk.replace('+', '')),
      ],
    ]
    return filter
  })
  return ['any', ...filters]
}

const MAX_ZOOM = 18.8
const handleUpdateContent = (
  map: MapRef,
  featureGeometry: Record<string, Geometry>,
  data: Signal<ContentType>,
  keySuffix: string,
) => {
  const type = data?.value?.type
  if (type !== 'sch' && type !== 'geo') return

  const geoKey = type === 'sch' ? `geom_rgi_track_sch_flat${keySuffix}` : `geom_rgi_track_geo${keySuffix}`
  const geom = featureGeometry[geoKey]
  if (!geom) return
  const perimeterBbox = bbox(geom)
  const newVs = map.cameraForBounds(perimeterBbox as [number, number, number, number])
  const newZoom = Math.max(Math.min(newVs.zoom, MAX_ZOOM), data.value.vp.zoom)
  map.flyTo({ center: newVs.center, zoom: newZoom })
}

export const handleLocateObject = async (id: string, layer: string, maps: MapCollection) => {
  if (!id || !layer) return
  const { left, right } = maps

  const centroid = layer === (CHARTIS_SOURCES_IDS.bif || layer === CHARTIS_SOURCES_IDS.station || layer === CHARTIS_SOURCES_IDS.poste) ? '_centroid' : ''
  const geometries = await get<PaginatedResponse<Record<string, Geometry>>>(
    `/chartis/v2/layer/${layer}/json/full_rgi_track_geo${centroid}/`,
    {
      columns: `geom_rgi_track_sch_flat${centroid},geom_rgi_track_geo${centroid}`,
      id,
    },
  )
  const featureGeometry = geometries.results[0]

  if (left) handleUpdateContent(left, featureGeometry, ContentSignals.left.data, centroid)
  if (right) handleUpdateContent(right, featureGeometry, ContentSignals.right.data, centroid)

  // Highlight object on map(s)
  const geometry = Object.values(featureGeometry).find(Boolean)
  MapDataSignal.targetedObject.value = { type: 'Feature', geometry, properties: { id }, source: layer }

  const indexes = Object.entries(MAP_IDS_SLUGS).map(
    ([idSlug, slugs]) => (idSlug === layer ? slugs.map(slug => getIndexForSlug(slug)) : undefined),
  ).filter(Boolean)

  LayersSignal.value = [...LayersSignal.value, ...indexes].flat()
}

export const countObjectsFromGeometry = async (geometry: Geometry): Promise<ObjectCount> => {
  const protectSignals = SIGNAL_LAYERS_MNEMO.signalProtection.join(',')
  const avertissementSignals = SIGNAL_LAYERS_MNEMO.signalArretAnnonce.join(',')

  if (geometry.type !== 'Polygon' && geometry.type !== 'MultiPolygon') return undefined

  const bpolygon = JSON.stringify(geometry)

  const [error, response] = await catchable(() => Promise.all([
    post<{count: number}>(`/chartis/v2/layer/${CHARTIS_SOURCES_IDS.adv}/count/full_rgi_track_sch_flat/`, { bpolygon }),
    post<{count: number}>(
      `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.signal}/count/full_rgi_track_sch_flat/`,
      { bpolygon, type_installation_fixe_id_mnemo__in: protectSignals },
    ),
    post<{count: number}>(
      `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.signal}/count/full_rgi_track_sch_flat/`,
      { bpolygon, type_installation_fixe_id_mnemo__in: avertissementSignals },
    ),
  ]), true)

  if (error) return undefined

  const [advCount, protectCount, avertissementCount] = response
  const total = advCount.count + protectCount.count + avertissementCount.count
  return { adv: advCount.count, protect: protectCount.count, avertissement: avertissementCount.count, total }
}
