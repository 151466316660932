import { Flags } from 'types'

const FeatureFlags: Flags = {
  roles: {
    dev: true,
    staging: true,
    prod: true,
    review: true,
    local: true,
  },
}

export const shouldDisplayFeature = (feature: keyof Flags) => (
  FeatureFlags[feature][process.env.REACT_APP_REALM || 'local']
)
