import { Layer } from '@vis.gl/react-maplibre'
import { CHARTIS_SOURCES_IDS, getDistinctPRICentroid, MAPBOX_LAYER_IDS, MapDataSignal, useBorderLabels } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'

const LABEL_SOURCE_ID = 'pri-labels'
export default function PRILayer({ view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useBorderLabels([MAPBOX_LAYER_IDS.pri], visibility, LABEL_SOURCE_ID, getDistinctPRICentroid)
  useSource(
    CHARTIS_SOURCES_IDS.pri,
    view,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds('region_libelle')

  const { opacity, lineFilter } = MapDataSignal

  return (
    <>
      <Layer
        type="line"
        paint={{
          'line-color': ['get', 'region_color'],
          'line-opacity': [
            'case',
            ['in', lineFilter.value, ['get', 'code_ligne']], 1,
            opacity,
          ],
          'line-width': [
            'case',
            ['in', ['get', 'region_libelle'], ['literal', hoveredObjectsIds]], 4,
            2,
          ],
        }}
        id={MAPBOX_LAYER_IDS.pri}
        source-layer={CHARTIS_SOURCES_IDS.pri}
        source={CHARTIS_SOURCES_IDS.pri}
        layout={{
          visibility: visibility ? 'visible' : 'none',
          'line-sort-key': ['case', ['in', ['get', 'region_libelle'], ['literal', hoveredObjectsIds]], 0, -1],
        }}
      />
      <Layer
        type="symbol"
        id={MAPBOX_LAYER_IDS.priLabel}
        source={LABEL_SOURCE_ID}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-field': '{region_libelle}',
          'text-size': 14,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-offset': [0, 1],
          visibility: visibility ? 'visible' : 'none',
          'text-allow-overlap': true,
        }}
        paint={{
          'text-color': ['get', 'region_color'],
          'text-halo-color': ['get', 'region_color'],
          'text-halo-width': 0.5,
        }}
        minzoom={5}
        maxzoom={24}
      />
    </>
  )
}
