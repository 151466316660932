import { useMap } from '@vis.gl/react-maplibre'
import terms from 'assets/terms'
import { Accordion, Button, ButtonStyle, Loader } from 'components'
import {
  ContentSignals, useTabs, MapDataSignal,
  handleLocateObject,
  onScreenSearch,
} from 'services'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { OnScreenResult, OnScreenResults } from 'types'
import { PanelSignal } from '../PanelsManager'
import InfoSinglePanel from '../info/InfoSinglePanel'

import './OnScreenPanel.scss'
import ResultTable from '../common/ResultTable'

const getCount = (count: number) => {
  if (!count) return ''
  if (count >= 50) return ' (50+)'
  return ` (${count})`
}

const MIN_ZOOM = 10

export default function OnScreenPanel() {
  const maps = useMap()
  const { possibleTabs, selectedTab, setSelectedTab } = useTabs()
  const [searchPending, setSearchPending] = useState<boolean>(true)
  const [noPosteSelected, setNoPosteSelected] = useState<boolean>(false)
  const [noBalSelected, setNoBalSelected] = useState<boolean>(false)
  const [noTravauxSelected, setNoTravauxSelected] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<OnScreenResults>({})

  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  const debouncedSearch = useCallback(
    debounce((bbox, type) => {
      setSearchResults({})
      setSearchPending(true)
      onScreenSearch(bbox, type).then(res => {
        if (res === 'canceled') return
        const { poste, bal, chantier } = res
        if (poste) setSearchResults(prev => ({ ...prev, posteObjects: poste }))
        else setNoPosteSelected(true)
        if (bal) setSearchResults(prev => ({ ...prev, balObjects: bal }))
        else setNoBalSelected(true)
        if (chantier) setSearchResults(prev => ({ ...prev, chantierObjects: chantier }))
        else setNoTravauxSelected(true)
        setSearchPending(false)
      })
    }, 500),
    [],
  )

  useEffect(() => {
    if (!(selectedTab?.position === 'left')) return
    const { type, bbox } = leftData.value
    if (!bbox) return
    if (type !== 'geo' && type !== 'sch') return
    if (leftData.value?.vp.zoom < MIN_ZOOM) return
    debouncedSearch(bbox, type)
  }, [leftData.value?.vp, selectedTab?.position])

  useEffect(() => {
    if (!(selectedTab?.position === 'right')) return
    const { type, bbox } = rightData.value
    if (!bbox) return
    if (type !== 'geo' && type !== 'sch') return
    if (rightData.value?.vp.zoom < MIN_ZOOM) return
    debouncedSearch(bbox, type)
  }, [rightData.value?.vp, selectedTab?.position])

  const handleHoverObject = (id: string) => () => {
    MapDataSignal.hoveredObjects.value = [{ properties: { id } }].filter(Boolean)
  }

  const handleClickResult = (result: OnScreenResult) => () => {
    handleLocateObject(result.properties.id, result.properties.layer_slug, maps)
    const feature = { ...result, source: result.properties.layer_slug }

    PanelSignal.value = (
      <InfoSinglePanel
        feature={feature}
        onBack={() => { PanelSignal.value = <OnScreenPanel /> }}
      />
    )
    MapDataSignal.targetedObject.value = feature
  }

  const renderContent = () => {
    if (selectedTab?.position === 'left' && leftData.value?.vp.zoom < MIN_ZOOM) {
      return (
        <span>Niveau de zoom trop large</span>
      )
    } if (selectedTab?.position === 'right' && rightData.value?.vp.zoom < MIN_ZOOM) {
      return (
        <span>Niveau de zoom trop large</span>
      )
    }
    return (
      <>
        <Accordion title={`Postes${getCount(searchResults?.posteObjects?.features.length)}`}>
          {searchPending ? (<Loader variant="small" />) : (
            <ResultTable
              features={searchResults.posteObjects?.features || []}
              columns={{
                libelle_long: 'Libellé',
                type_installation_fixe_id_mnemo: 'Type',
                lrs_ligne: 'Ligne',
                lrs_pk: 'PK',
              }}
              handleClickObject={handleClickResult}
              handleHoverObject={handleHoverObject}
              noObjectMessage={noPosteSelected ? 'Seuls les éléments des couches actuellement actives sont affichés.'
                : 'Aucun poste'}
            />
          )}
        </Accordion>
        <Accordion title={`Tronçon${getCount(searchResults?.balObjects?.features.length)}`}>
          {searchPending ? (<Loader variant="small" />) : (
            <ResultTable
              features={searchResults.balObjects?.features || []}
              columns={{
                lrs_ligne: 'Ligne',
                libelle: 'Libellé',
              }}
              handleClickObject={handleClickResult}
              handleHoverObject={handleHoverObject}
              noObjectMessage={noBalSelected ? 'Seuls les éléments des couches actuellement actives sont affichés.'
                : 'Aucun tronçon'}
            />
          )}
        </Accordion>
        <Accordion title={`Projets Travaux${getCount(searchResults?.chantierObjects?.features.length)}`}>
          {searchPending ? (<Loader variant="small" />) : (
            <ResultTable
              features={searchResults.chantierObjects?.features || []}
              columns={{
                num_compte_geremi: 'C6',
                numero_astre: 'ASTRE',
                libelle: 'Libellé',
              }}
              handleClickObject={handleClickResult}
              handleHoverObject={handleHoverObject}
              noObjectMessage={noTravauxSelected ? 'Seuls les éléments des couches actuellement actives sont affichés.'
                : 'Aucun projet'}
            />
          )}
        </Accordion>
      </>
    )
  }

  return (
    <>
      <h3 className="panel-title">{terms.Header.toScreen}</h3>
      <div className="on-screen-panel hide-scroll">
        {rightData.value?.type && (
        <div className="tabs flex-center">
          {possibleTabs.length ? possibleTabs.map(tab => (
            <Button
              key={tab.position}
              className={selectedTab?.position === tab.position ? 'selected' : ''}
              text={terms.Panels.Search.tabs[tab.position]}
              style={ButtonStyle.borderLess}
              onClick={() => setSelectedTab(tab)}
            />
          )) : <span className="no-tab">{terms.Panels.Search.noTab}</span>}
        </div>
        )}
        {renderContent()}
      </div>
    </>
  )
}
