/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable camelcase */
import { useMap } from '@vis.gl/react-maplibre'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { Feature } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as FavoriteSelectedIcon } from 'assets/icons/favorite-selected.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ReactComponent as OpenInNew } from 'assets/icons/open-in-new.svg'
import { Collection, MapFeature } from 'types'
import { catchable, useAppSelector } from 'utils'
import {
  CHARTIS_SOURCES_IDS, CommentsSignal, getComments,
  getObjectCollections, ObjectCollectionSignal, postComment, SOURCE_NAMES,
  deleteFavoriteObject, FavoritesSignal, postFavoriteObject,
  getCollection,
  handleLocateObject,
  getChartisFeature,
} from 'services'
import {
  Accordion, Button, ButtonSize, ButtonStyle, Loader, TextInput,
} from 'components'
import { PanelSignal } from '../PanelsManager'
import CollectionPanel from '../collection/CollectionPanel'
import Comment from './Comment'
import MapLinks from './MapLinks'
import PanelObjectCount from './PanelObjectCount'

import './InfoPanel.scss'

type Props = {
  feature: MapFeature
  onBack?: () => void
}

const TYPE_PROPERTIES = {
  [CHARTIS_SOURCES_IDS.signal]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.localisateur]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.passageNiveau]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.acces]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.dbc]: 'type_installation_fixe_id_mnemo',
  [CHARTIS_SOURCES_IDS.pedale]: 'type_installation_fixe_id_mnemo',
  [CHARTIS_SOURCES_IDS.sousStation]: 'type_electrifications_id_libelle',
  [CHARTIS_SOURCES_IDS.adv]: 'type_appareil_de_voie_id_mnemonique',
  [CHARTIS_SOURCES_IDS.advLine]: 'type_appareil_de_voie_id_mnemonique',
  [CHARTIS_SOURCES_IDS.poste]: 'type_installation_fixe_id_mnemo',
}

const getTypeProperty = (properties: {[key: string]: string}, source: string) => {
  if (!Object.keys(TYPE_PROPERTIES).includes(source)) return ''
  return Object.entries(properties).find(([key]) => key === TYPE_PROPERTIES[source])?.[1] || 'NC'
}

export default function InfoSinglePanel({ feature, onBack }: Props) {
  const maps = useMap()
  const accountId = useAppSelector(({ user }) => user.account.id)
  const [newComment, setNewComment] = useState('')
  const [mapLinksFeature, setMapLinksFeature] = useState<Feature | null>(null)
  const [view, setView] = useState<'info' | 'comments'>('info')
  const [displayMenu, setDisplayMenu] = useState(false)
  const { properties, source } = feature
  // Needed for ZAP and IHM label layers
  const sourceLayer = source.replace('label-', '')
  const { lrs_ligne, lrs_voie, lrs_pk, libelle, libelle_long, id } = properties || {}
  const favoriteId = FavoritesSignal.objects.value.find(favorite => favorite.object_id === id)?.id

  useEffect(() => {
    if (feature.properties.id) {
      getComments(sourceLayer, feature.properties.id)
      getObjectCollections(sourceLayer, feature.properties.id)
      getChartisFeature(feature.properties.id, sourceLayer, 'full_rgi_track_geo').then(setMapLinksFeature)
    }
  }, [feature.properties.id, feature.source])

  const handleSelectView = (v: 'info' | 'comments') => () => {
    setView(v)
    setDisplayMenu(false)
  }

  const handlePostFavorite = () => {
    postFavoriteObject({
      object_id: feature?.properties?.id,
      object_libelle: libelle_long || libelle || 'N/C',
      object_lvp: `${lrs_ligne} - ${lrs_voie || ''} ${lrs_pk || ''}`,
      layer_id_field: 'id',
      layer_slug: sourceLayer,
    })
  }

  const handlePostComment = async () => {
    if (newComment) {
      postComment({
        user_id: accountId,
        content: newComment,
        type: 'Divers',
        object_id: feature?.properties?.id,
        object_libelle: libelle || 'N/C',
        layer_id_field: 'id',
        layer_slug: sourceLayer,
      })
      setNewComment('')
    }
  }

  const handleDisplayCollection = (collection: Collection) => async () => {
    const fetchedCollection = await getCollection(collection.id)
    if (fetchedCollection) {
      PanelSignal.value = (
        <CollectionPanel
          collection={fetchedCollection}
          onBack={() => {
            PanelSignal.value = <InfoSinglePanel feature={feature} onBack={onBack} />
          }}
        />
      )
    }
  }

  const handleDisplayPoste = (posteId: string) => async () => {
    const [error, response] = await catchable(() => get<Feature>(
      `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.poste}/geojson_feature/full_rgi_track_geo/`,
      { id: posteId },
    ))

    if (error) return

    handleLocateObject(posteId, CHARTIS_SOURCES_IDS.poste, maps)

    PanelSignal.value = (
      <InfoSinglePanel
        feature={{ ...response, source: CHARTIS_SOURCES_IDS.poste }}
        onBack={() => {
          PanelSignal.value = <InfoSinglePanel feature={feature} onBack={onBack} />
        }}
      />
    )
  }

  const renderProps = (label: string, prop: string) => prop && (
    <p>
      <b>{`${label} : `}</b>
      {prop}
    </p>
  )

  const renderInfo = () => (
    <div className="info">
      {CHARTIS_SOURCES_IDS.chantier === sourceLayer && (<b className="underline">Générale</b>)}
      {renderProps('Couche', SOURCE_NAMES[sourceLayer] || 'N/C')}
      {renderProps('Programme', feature?.properties?.programme)}
      {renderProps('Ligne', lrs_ligne || 'N/C')}
      {renderProps('Voie', lrs_voie || 'N/C')}
      {renderProps('PK', lrs_pk || 'N/C')}
      {renderProps('Année', feature?.properties?.annee)}
      {CHARTIS_SOURCES_IDS.chantier === sourceLayer && renderProps('Mise en service', feature?.properties?.date_mes_production
        ? (moment(feature?.properties?.date_mes_production).format('DD/MM/YYYY'))
        : 'Non renseignée')}
      {renderProps('Phase', feature?.properties?.phase)}
      {CHARTIS_SOURCES_IDS.center !== sourceLayer && renderProps('Gaia ID', id || 'N/C')}
      {renderProps('ID ARMEN', feature?.properties?.id_armen)}
      {renderProps('Type', getTypeProperty(properties, sourceLayer))}
      {CHARTIS_SOURCES_IDS.chantier === sourceLayer && (
        <>
          <div className="divider" />
          <b className="underline">Identifiants</b>
        </>
      )}
      {renderProps('Code C6', feature?.properties?.num_compte_geremi)}
      {renderProps('Clé banque', feature?.properties?.cle_banque_travaux)}
      {renderProps('N° Astre', feature?.properties?.numero_astre)}
      {renderProps('N° SEISM', feature?.properties?.seism_project_id)}
      {renderProps('N° FEM', feature?.properties?.reference_emergence)}
      <PanelObjectCount feature={feature} />
      <div className="divider" />
      <div className="collections-infos">
        {(CHARTIS_SOURCES_IDS.poste !== sourceLayer && CHARTIS_SOURCES_IDS.zap !== sourceLayer) && (
        <>
          <Accordion
            title={(
              <div className="title">
                <b>{`Poste(s) ${CHARTIS_SOURCES_IDS.chantier === sourceLayer ? 'impacté(s)' : 'de rattachement'} :`}</b>
                {ObjectCollectionSignal.loading.value && <Loader variant="x-small" />}
              </div>
            )}
          >
            {!ObjectCollectionSignal.loading.value && (
              <div className="items">
                {ObjectCollectionSignal.postes.value.length ? ObjectCollectionSignal.postes.value.map(poste => (
                  <Button
                    key={poste.id}
                    style={ButtonStyle.borderLess}
                    text={poste.label}
                    size={ButtonSize.medium}
                    onClick={handleDisplayPoste(poste.id)}
                    isAsync
                    inline
                  />
                )) : 'N/C (poste non identifié)'}
              </div>
            )}
          </Accordion>
          <div className="divider" />
        </>
        )}
        <Accordion
          title={(
            <div className="title">
              <b>{`Collection(s) Dex ${CHARTIS_SOURCES_IDS.chantier === sourceLayer ? 'impactée(s)' : 'associée(s)'} :`}</b>
              {ObjectCollectionSignal.loading.value && <Loader variant="x-small" />}
            </div>
        )}
        >
          {!ObjectCollectionSignal.loading.value && (
          <div className="items">
            {ObjectCollectionSignal.collections.value.length ? ObjectCollectionSignal.collections.value.map(item => (
              <Button
                key={item.id}
                style={ButtonStyle.borderLess}
                text={item.nom_collection_gep}
                size={ButtonSize.medium}
                inline
                onClick={handleDisplayCollection(item)}
                isAsync
              />
            )) : (ObjectCollectionSignal.postes.value.length
              ? 'N/C (échec lien gaia-ouranos ou collection absente)'
              : 'N/C (pas de poste identifié)'
            )}
          </div>
          )}
        </Accordion>
        {CHARTIS_SOURCES_IDS.chantier === sourceLayer && feature?.properties?.num_compte_geremi && (
          <>
            <div className="divider" />
            <Accordion
              title={(
                <div className="title">
                  <b>{'Fiches d\'analyses :'}</b>
                  {ObjectCollectionSignal.loading.value && <Loader variant="x-small" />}
                </div>
          )}
            >
              {!ObjectCollectionSignal.loading.value && (
              <div className="items">
                {ObjectCollectionSignal.fa.value.length ? ObjectCollectionSignal.fa.value.map(item => (
                  <div className="item-fa" key={item.id}>
                    <p>
                      <b>
                        FA
                        {' '}
                        {item.id}
                        {' '}
                        -
                        {' '}
                        {item.statut}
                        {' '}
                        -
                        {' '}
                        {moment(item.date_creation).format('DD/MM/YYYY')}
                      </b>
                      <br />
                      {item.libelle}
                    </p>
                    <button type="button" onClick={() => { window.open(`https://${item.url}`, '_blank') }}>
                      <OpenInNew />
                    </button>
                  </div>
                )) : 'Aucune Fiche d’Analyse n’est associée à ce code C6'}
              </div>
              )}
            </Accordion>
          </>
        )}
      </div>
    </div>
  )

  const renderComments = () => (
    <div className="comments">
      <div className="list hide-scroll">
        {CommentsSignal.value.map(comment => <Comment key={comment.id} comment={comment} />)}
      </div>
      <div className="add-comment">
        <TextInput label="Ajouter un commentaire" bindedValue={newComment} onChange={setNewComment} />
        <Button text="Publier" onClick={handlePostComment} />
      </div>
    </div>
  )

  const getTitle = () => {
    switch (view) {
      case 'info':
        return 'Infos'
      case 'comments':
        return `Commentaires (${CommentsSignal.value.length})`
      default:
        return 'Infos'
    }
  }

  const getView = () => {
    switch (view) {
      case 'info':
        return renderInfo()
      case 'comments':
        return renderComments()
      default:
        return renderInfo()
    }
  }

  return (
    <>
      {!onBack && (
        <h3 className="panel-title">
          {feature?.properties?.libelle_long || feature?.properties?.libelle || 'N/C'}
        </h3>
      )}
      {onBack && (
        <div className="panel-title">
          <Button
            className="button-back"
            style={ButtonStyle.borderLess}
            text=""
            icon={<ArrowLeftIcon />}
            onClick={onBack}
          />
          <h3>{feature?.properties?.libelle_long || feature?.properties?.libelle || 'N/C'}</h3>
        </div>
      )}
      <div className="info-panel panel hide-scroll">
        <div className="object-single">
          <div className="header">
            <p>{getTitle()}</p>
            {favoriteId
              ? <FavoriteSelectedIcon onClick={() => deleteFavoriteObject(favoriteId)} />
              : <FavoriteIcon onClick={handlePostFavorite} />}
            <Button
              text=""
              icon={<BurgerIcon />}
              style={ButtonStyle.borderLess}
              onClick={() => setDisplayMenu(!displayMenu)}
            />
            {CommentsSignal.value.length > 0 && <div className="comments-count">{CommentsSignal.value.length}</div>}
            <div className={`menu ${displayMenu ? 'display' : ''}`}>
              <Button
                text="Infos"
                style={ButtonStyle.borderLess}
                onClick={handleSelectView('info')}
                className={view === 'info' ? 'selected' : ''}
              />
              <Button
                text={`Commentaires (${CommentsSignal.value.length})`}
                style={ButtonStyle.borderLess}
                onClick={handleSelectView('comments')}
                className={view === 'comments' ? 'selected' : ''}
              />
            </div>
          </div>
          {feature && getView()}
        </div>
        <MapLinks feature={{ ...mapLinksFeature, source: sourceLayer }} />
      </div>
    </>
  )
}
