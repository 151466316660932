import { useMap } from '@vis.gl/react-maplibre'
import { MouseEvent, useState } from 'react'
import { Accordion, Button, ButtonSize, ButtonStyle } from 'components'
import { CHARTIS_SOURCES_IDS, handleLocateObject, MapDataSignal } from 'services'
import { Collection } from 'types'
import LinkedWorks from './LinkedWorks'

type Props = {
  collection: Collection
}

export default function Interactions({ collection }: Props) {
  const maps = useMap()
  const [loading, setLoading] = useState(false)

  const handleLocate = (id: string, layer: string) => async (e: MouseEvent) => {
    e.stopPropagation()
    setLoading(true)
    await handleLocateObject(id, layer, maps)
    setLoading(false)
  }

  const handleFocus = (id: string) => async () => {
    MapDataSignal.hoveredObjects.value = [{ properties: { id } }]
  }

  return (
    <>
      <Accordion title="Interactions" defaultExpanded>
        {collection?.infos_is?.map((is, index) => (
          <>
            <div key={is.id} className="is-item">
              <p>
                <b>{'IHM : '}</b>
                <span>{is.poste_ihms?.length ? 'Oui' : 'Aucune IHM Ouranos identifiée pour cette collection'}</span>
                {is.poste_ihms?.length > 0 && is.poste_ihms?.map((ihm, i) => (
                  <div key={ihm.id}>
                    <p>
                      <b>Libellé IHM : </b>
                      <Button
                        style={ButtonStyle.borderLess}
                        text={`${ihm.libelle}${(i + 1) < is.poste_ihms?.length ? ', ' : ''}`}
                        size={ButtonSize.medium}
                        inline
                        onClick={handleLocate(ihm.id, CHARTIS_SOURCES_IDS.ihm)}
                        disabled={loading}
                      />
                    </p>
                    <p>
                      <b>{'ID Ouranos : '}</b>
                      <span>{`${ihm.id}${(i + 1) < is.poste_ihms?.length ? ', ' : ''}`}</span>
                    </p>
                  </div>
                ))}
              </p>
            </div>
            {index < collection.infos_is.length - 1 && <div className="small-separator" />}
          </>
        ))}
        <div className="small-separator" />
        <div className="encadrants d-flex">
          <b>Encadrant(s) : </b>
          {collection.postes_encadrants?.map(poste => (
            <Button
              key={poste.id}
              style={ButtonStyle.borderLess}
              text={poste.libelle_long}
              size={ButtonSize.medium}
              inline
              onClick={handleLocate(poste.id, CHARTIS_SOURCES_IDS.poste)}
              onMouseEnter={handleFocus(poste.id)}
              disabled={loading}
            />
          ))}
          {collection.bals_encadrants?.map(bal => (
            <Button
              key={bal.id}
              style={ButtonStyle.borderLess}
              text={bal.libelle}
              size={ButtonSize.medium}
              inline
              onClick={handleLocate(bal.id, CHARTIS_SOURCES_IDS.bal)}
              disabled={loading}
            />
          ))}
          {collection.bals_encadrants?.length === 0 && collection.postes_encadrants?.length === 0 && 'Aucun'}
        </div>
        <div className="small-separator" />
        <LinkedWorks collection={collection} />
      </Accordion>
      <div className="separator" />
    </>
  )
}
