import { useMemo } from 'react'
import {
  CHARTIS_SOURCES_IDS,
  getGeoTrackFilter, isLayerSelected, isSomeChildSelected, PerimeterSignals,
} from 'services'
import { POSTES_LAYER_KEYS } from 'assets/layers'
import {
  AccesLayer, ADVLayer, ADVLineLayer, BALLayer, BifLayer, CDVLayer, CenterLayer, ChantierLayer, CSSLayer, DBCLayer,
  ExploitationModeLayer, IHMLabelLayer, IHMLayer, JDZLayer, LocalisateurLayer, PedaleLayer, PosteLayer, PRILayer,
  RacLayer, SignalLayer, SousStationLayer, StationLayer, StructureLayer, TrackLayer, UTMLayer, ZAPLabelLayer, ZAPLayer,
} from './layers'
import PassageNiveauLayer from './layers/PassageNiveauLayer'

export default function GeoLayers() {
  const geoTrackFilter = useMemo(
    () => getGeoTrackFilter(PerimeterSignals.activePerimeter.value),
    [PerimeterSignals.activePerimeter.value?.id],
  )

  return (
    <>
      <ExploitationModeLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('exploitation-mode-unique')
          || isLayerSelected('exploitation-mode-banalisee') || isLayerSelected('exploitation-mode-ipcs')}
      />
      <TrackLayer
        source={CHARTIS_SOURCES_IDS.trackGeo}
        trackFilter={geoTrackFilter}
      />
      <UTMLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('utm')}
      />
      <PRILayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('pri')}
      />
      <StructureLayer
        view="full_rgi_track_geo"
        visibilityZP={isLayerSelected('zp')}
        visibilityInfrapole={isLayerSelected('infrapole')}
      />
      <JDZLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('jdz')}
        angle="angle_rgi_track_geo"
      />
      <ZAPLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('zoneActionPoste')}
      />
      <BALLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('bal-zone')}
      />
      <IHMLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('ihm-zone')}
      />
      <ADVLineLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('adv')}
      />
      <CDVLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('cdv')}
      />
      <RacLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('rac')}
      />
      <ChantierLayer
        view="full_rgi_track_geo"
        visibility={isSomeChildSelected('Par année de travaux') && isSomeChildSelected('Par phase')}
      />
      <ADVLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('adv')}
      />
      <LocalisateurLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('localisateur')}
      />
      <PedaleLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('pedale')}
      />
      <AccesLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('acces')}
      />
      <BifLayer
        view="full_rgi_track_geo_centroid"
        visibility={isLayerSelected('bif')}
      />
      <DBCLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('dbc')}
        angle="angle_rgi_track_geo"
      />
      <PassageNiveauLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('passageNiveau')}
        angle="angle_rgi_track_geo"
      />
      <StationLayer
        view="full_rgi_track_geo_centroid"
        visibility={isLayerSelected('station')}
      />
      <SousStationLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('sousStation')}
      />
      <CSSLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('css')}
      />
      <SignalLayer
        view="full_rgi_track_geo"
        visibility={isSomeChildSelected('Signaux')}
        angle="angle_rgi_track_geo"
      />
      <PosteLayer
        view="full_rgi_track_geo_centroid"
        visibility={Object.values(POSTES_LAYER_KEYS).some(isLayerSelected)}
      />
      <CenterLayer
        view="full_rgi_track_geo"
        visibility={isLayerSelected('center')}
      />
      <ZAPLabelLayer
        view="full_rgi_track_geo_libelle"
        visibility={isLayerSelected('zoneActionPoste')}
      />
      <IHMLabelLayer
        view="full_rgi_track_geo_libelle"
        visibility={isLayerSelected('ihm-zone')}
      />
    </>
  )
}
