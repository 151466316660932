import SliderInput from './slider/SliderInput'
import SelectInput from './select/SelectInput'
import SelectCheckInput from './selectCheck/SelectCheckInput'
import TextInput from './text/TextInput'
import DateInput from './date/DateInput'
import CheckInput from './check/CheckInput'
import SwitchInput from './switch/SwitchInput'
import RadioInput from './radio/RadioInput'

export enum InputSize {
  xsmall = 'x-small',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export {
  SliderInput,
  SelectInput,
  SelectCheckInput,
  TextInput,
  DateInput,
  CheckInput,
  SwitchInput,
  RadioInput,
}
