/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { useClickOutside } from 'utils'
import CheckInput from '../check/CheckInput'

import './SelectCheckInput.scss'
import { TextInput } from '..'

export type CheckOption = {
  label?: string
  value: string | number
  checked: boolean
}

type Props = {
  label?: string
  placeholder?: string
  options: CheckOption[]
  disabled?: boolean
  onChange: (value: string | number) => void
  required?: boolean
  displayChip?: boolean
}

export default function SelectCheckInput({
  label = '',
  placeholder = '',
  disabled,
  options,
  onChange,
  required = false,
  displayChip = false,
}: Props) {
  const inputRef = useRef<HTMLDivElement>(null)
  const [displayOptions, setDisplayOptions] = useState(false)
  const [selected, setSelected] = useState<(string | number)[]>(undefined)

  useClickOutside(inputRef, () => {
    setDisplayOptions(false)
  })

  // set selected options if they are checked by default
  useEffect(() => {
    if (options?.length && !selected) {
      setSelected(options.filter(option => option.checked).map(option => option.value))
    }
  }, [options])

  const handleCheck = (value: string | number) => (checked: boolean) => {
    if (checked) {
      setSelected([...(selected || []), value])
    } else {
      setSelected((selected || []).filter(v => v !== value))
    }

    onChange(value)
  }

  const shouldHide = !displayOptions || disabled || !options.length

  const renderChips = () => selected?.map(value => {
    const option = options.find(opt => opt.value === value)

    if (!option) return null

    return (
      <span
        key={option.value.toString()}
        className="chip"
        onClick={e => {
          e.stopPropagation()
          handleCheck(option.value)(false)
        }}
      >
        {option.label}
        <CloseIcon />
      </span>
    )
  })

  return (
    <div className="select-check input-wrapper" ref={inputRef}>
      {label && (
        <label htmlFor={label}>
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      {displayChip && selected?.length > 0 && options.some(option => selected.includes(option.value)) ? (
        <div className="input-wrapper input chips">
          {renderChips()}
          <Chevron onClick={() => setDisplayOptions(!displayOptions)} />
        </div>
      ) : (
        <TextInput
          bindedValue={placeholder}
          RightIcon={<Chevron />}
          disabled={disabled}
          onClick={() => setDisplayOptions(!displayOptions)}
        />
      )}
      <div className={`options hide-scroll ${shouldHide && 'hidden'}`}>
        {options.map(option => (
          <CheckInput
            key={option.value.toString()}
            label={option.label || ''}
            defaultValue={selected?.includes(option.value)}
            onChange={handleCheck(option.value)}
          />
        ))}
      </div>
    </div>
  )
}
