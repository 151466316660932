import { Loader } from 'components'
import { useEffect, useState } from 'react'
import { CHARTIS_SOURCES_IDS, countObjectsFromGeometry, getChartisFeature } from 'services'
import { MapFeature, ObjectCount } from 'types'

type Props = {
  feature: MapFeature
}

export default function PanelObjectCount({ feature }: Props) {
  const [loadingCount, setLoadingCount] = useState(false)
  const [count, setCount] = useState<ObjectCount>()

  useEffect(() => {
    setCount(undefined)
    let source = ''
    switch (feature.source) {
      case CHARTIS_SOURCES_IDS.poste:
      case CHARTIS_SOURCES_IDS.zap:
      case `label-${CHARTIS_SOURCES_IDS.zap}`:
        source = CHARTIS_SOURCES_IDS.zap
        break
      case CHARTIS_SOURCES_IDS.ihm:
      case `label-${CHARTIS_SOURCES_IDS.ihm}`:
        source = CHARTIS_SOURCES_IDS.ihm
        break
      case CHARTIS_SOURCES_IDS.bal:
        source = CHARTIS_SOURCES_IDS.bal
        break
      default:
        break
    }
    if (!source) return
    setLoadingCount(true)
    getChartisFeature(feature.properties?.id, source, 'full_rgi_track_sch_flat').catch(e => {
      // eslint-disable-next-line no-console
      console.error(e)
    }).then(
      f => {
        if (f) {
          countObjectsFromGeometry(f.geometry).then(r => {
            setCount(r)
            setLoadingCount(false)
          })
        } else {
          setLoadingCount(false)
          setCount(undefined)
        }
      },
    )
  }, [feature])

  const renderProps = (label: string, prop: string) => prop && (
    <p style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      <b>{`${label} : `}</b>
      {loadingCount ? <Loader variant="x-small" /> : prop}
    </p>
  )

  if (!loadingCount && !count) return null
  return (
    <>
      {renderProps('Décompte d\'ADV', `${count?.adv}`)}
      {renderProps('Décompte de protections', `${count?.protect}`)}
      {renderProps('Décompte d\'avertissements', `${count?.avertissement}`)}
      {renderProps('Total cumulé', `${count?.total}`)}
    </>
  )
}
