import { effect, Signal, signal } from '@preact/signals-react'
import { MapCollection } from '@vis.gl/react-maplibre/dist/components/use-map'
import { get, patch, post } from '@osrdata/app_core/dist/requests'
import bbox from '@turf/bbox'
import kinks from '@turf/kinks'
import { Feature, FeatureCollection, Geometry, Polygon } from 'geojson'
import { ContentType, Perimeter } from 'types'
import { catchable } from 'utils'
import { ModalSignal, ToastLevel, ToastSignal } from 'components'
import terms from 'assets/terms'
import { MapRef } from '@vis.gl/react-maplibre'
import { reprojectViewport } from './map'
import { ContentSignals } from './contents'

export const PerimeterSignals = {
  features: signal<Feature[]>(null),
  drawing: signal<boolean>(false),
  list: signal<Perimeter[]>([]),
  activePerimeter: signal<Perimeter | null>(null),
  patchingPerimeter: signal<Perimeter | null>(null),
  selfIntersecting: signal<boolean>(false),
}

effect(() => {
  if (!PerimeterSignals.features.value?.length) return
  const polygon = PerimeterSignals.features.value[0]
  if (polygon.geometry.type !== 'Polygon') return
  const selfIntersecting = kinks(polygon as Feature<Polygon>).features.length > 0
  PerimeterSignals.selfIntersecting.value = selfIntersecting
  if (selfIntersecting) {
    ToastSignal.value = {
      severity: ToastLevel.WARNING,
      message: terms.Modals.SavePerimeter.selfIntersecting,
    }
  }
})

export const startPerimeterDraw = () => {
  PerimeterSignals.drawing.value = true
  PerimeterSignals.features.value = []
  PerimeterSignals.patchingPerimeter.value = null
}

export const updatePerimeterFeatures = (features: Feature[]) => {
  PerimeterSignals.features.value = features
}

export const cancelPerimeterDraw = () => {
  PerimeterSignals.drawing.value = false
  PerimeterSignals.features.value = []
  PerimeterSignals.patchingPerimeter.value = null
}

export const startPatchPerimeterProps = (perimeter: Perimeter) => {
  PerimeterSignals.patchingPerimeter.value = perimeter
  PerimeterSignals.drawing.value = false
  PerimeterSignals.features.value = []
}

export const startPatchPerimeterGeometry = (perimeter: Perimeter) => {
  PerimeterSignals.patchingPerimeter.value = perimeter
  PerimeterSignals.drawing.value = true
  PerimeterSignals.features.value = [perimeter]
}

export const endPatchPerimeter = (newPerimeter: Perimeter) => {
  PerimeterSignals.list.value = PerimeterSignals.list.value.map(
    p => (p.id === newPerimeter.id ? newPerimeter : p),
  )
  cancelPerimeterDraw()
  PerimeterSignals.patchingPerimeter.value = null
  PerimeterSignals.activePerimeter.value = newPerimeter
}

export const getPerimeters = async () => {
  const [error, response] = await catchable<FeatureCollection>(() => get('/dexcarto/users/study-perimeters/', {
    ordering: '-creation_date',
  }))
  if (error) return
  PerimeterSignals.list.value = response?.features as Perimeter[]
}

export const handleCreatePerimeter = (name: string, description: string, geometry: Geometry) => catchable(async () => {
  const newPerimeter = await post<Perimeter>('/dexcarto/users/study-perimeters/', {
    type: 'Feature',
    properties: {
      name,
      description,
    },
    geometry,
  })
  await getPerimeters()
  ModalSignal.value = undefined
  cancelPerimeterDraw()
  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: terms.Modals.SavePerimeter.success,
  }
  return newPerimeter
}, true, terms.Modals.SavePerimeter.error)

export const handleConfirmEditGeometry = () => catchable(async () => {
  const updatedPerimeter = await patch<Perimeter>(
    `/dexcarto/users/study-perimeters/${PerimeterSignals.patchingPerimeter.value.id}/`,
    { geometry: PerimeterSignals.features.value[0].geometry },
  )
  endPatchPerimeter(updatedPerimeter)
  PerimeterSignals.activePerimeter.value = updatedPerimeter
  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: terms.Modals.PatchPerimeter.success,
  }
  return updatedPerimeter
}, true, terms.Modals.PatchPerimeter.error)

export const handlePatchProperties = (name: string, description: string) => catchable(async () => {
  const updatedPerimeter = await patch<Perimeter>(
    `/dexcarto/users/study-perimeters/${PerimeterSignals.patchingPerimeter.value.id}/`,
    { properties: { name, description } },
  )
  endPatchPerimeter(updatedPerimeter)
  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: terms.Modals.PatchPerimeter.success,
  }
  return updatedPerimeter
}, true, terms.Modals.PatchPerimeter.error)

const updateViewport = async (data: Signal<ContentType>, perimeter: Perimeter, mapRef: MapRef) => {
  if (!data.value || !['geo', 'sch'].includes(data.value.type)) return
  const map = mapRef.getMap()
  const perimeterBbox = bbox(perimeter.geometry)
  const newVs = map.cameraForBounds(perimeterBbox as [number, number, number, number])
  if (data.value.type === 'sch') {
    map.flyTo({ center: newVs.center, zoom: newVs.zoom, animate: false })
  } else {
    const geoVs = await reprojectViewport(newVs, 'sch', 'geo')
    map.flyTo({ center: geoVs.center, zoom: geoVs.zoom, animate: false })
  }
}

export const updatePerimeterViewport = async (perimeter: Perimeter, maps: MapCollection) => {
  const leftData = ContentSignals.left.data
  const rightData = ContentSignals.right.data
  if (maps?.left) updateViewport(leftData, perimeter, maps.left)
  if (maps?.right) updateViewport(rightData, perimeter, maps.right)
}
