import { deleteRequest } from '@osrdata/app_core/dist/requests'
import { useMap } from '@vis.gl/react-maplibre'
import { useState, MouseEvent } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import terms from 'assets/terms'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { shouldBreakWord, useMountEffect } from 'utils'
import { Perimeter } from 'types'
import { getPerimeters, PerimeterSignals, updatePerimeterViewport } from 'services'
import {
  Button, ButtonStyle, ConfirmModal, ModalSignal, TextInput, ToastLevel, ToastSignal,
} from 'components'

import PerimeterInfoPanel from '../perimeterInfo/PerimeterInfoPanel'
import { PanelSignal } from '../PanelsManager'

import './PerimetersPanel.scss'

export default function PerimetersPanel() {
  useMountEffect(() => { getPerimeters() })
  const maps = useMap()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [filter, setFilter] = useState('')
  const [toogledContents, setToogledContents] = useState<number[]>([])
  const filteredPerimeters = PerimeterSignals.list.value?.filter(
    perimeter => perimeter.properties.name.toLowerCase().includes(filter),
  )

  const handleSortPerimeters = (value: string) => { setFilter(value.toLowerCase()) }

  const handleLoadPerimeter = (perimeter: Perimeter) => async () => {
    navigate(`/perimetre/${perimeter.id}/?${params}`)
    PerimeterSignals.activePerimeter.value = perimeter
    updatePerimeterViewport(perimeter, maps)
    PanelSignal.value = (
      <PerimeterInfoPanel
        perimeter={perimeter}
        onBack={() => { PanelSignal.value = <PerimetersPanel /> }}
      />
    )
  }

  const handleToggleContent = (id: number) => (e: MouseEvent) => {
    e.stopPropagation()
    if (toogledContents.includes(id)) {
      setToogledContents(toogledContents.filter(content => content !== id))
    } else {
      setToogledContents([...toogledContents, id])
    }
  }

  const handleDelete = (id: number) => (e: MouseEvent) => {
    e.stopPropagation()
    ModalSignal.value = (
      <ConfirmModal
        title={terms.Panels.Perimeters.deleteConfirm}
        handleClose={() => { ModalSignal.value = null }}
        handleValidate={() => {
          deleteRequest(`/dexcarto/users/study-perimeters/${id}/`)
            .then(() => {
              if (PerimeterSignals.activePerimeter.value?.id === id) {
                PerimeterSignals.activePerimeter.value = null
                navigate('/')
              }
              PerimeterSignals.list.value = PerimeterSignals.list.value?.filter(perimeter => perimeter.id !== id)
              ModalSignal.value = null
              ToastSignal.value = {
                severity: ToastLevel.SUCCESS,
                message: terms.Panels.Perimeters.deleteSuccess,
              }
            }).catch(() => {
              ToastSignal.value = {
                severity: ToastLevel.ERROR,
                message: terms.Panels.Perimeters.deleteError,
              }
            })
        }}
      />
    )
  }

  return (
    <>
      <h3 className="panel-title perimeter-panel-title">Périmètres</h3>
      <div className="perimeters-panel hide-scroll">
        <TextInput
          label={terms.Common.search}
          onChange={handleSortPerimeters}
          LeftIcon={<SearchIcon />}
        />
        <table className="perimeters">
          <thead>
            <tr>
              <th><div className="name">{terms.Panels.Perimeters.name}</div></th>
              <th><div className="description">{terms.Panels.Perimeters.description}</div></th>
              <th><div className="date">{terms.Panels.Perimeters.creationDate}</div></th>
              <th><div className="actions">{terms.Panels.Perimeters.actions}</div></th>
            </tr>
          </thead>
          <tbody>
            {filteredPerimeters?.map(perimeter => (
              <tr className="item" key={perimeter.id} onClick={handleLoadPerimeter(perimeter)}>
                <td>
                  <div className="name">
                    {perimeter.properties.name}
                  </div>
                </td>
                <td>
                  <div
                    className={`description${shouldBreakWord(perimeter.properties.description) ? ' break-word' : ''}`}
                  >
                    <span className={`${toogledContents.includes(perimeter.id) ? 'expanded' : ''}`}>
                      {perimeter.properties.description}
                    </span>
                    {perimeter.properties.description && (
                    <Button
                      style={ButtonStyle.borderLess}
                      text={`${toogledContents.includes(perimeter.id) ? terms.Common.showLess : terms.Common.showMore}`}
                      onClick={handleToggleContent(perimeter.id)}
                    />
                    )}
                  </div>
                </td>
                <td><div className="date">{moment(perimeter.properties.creation_date).format('DD/MM/YYYY')}</div></td>
                <td>
                  <div className="actions flex-center">
                    <Button
                      style={ButtonStyle.borderLessPrimary}
                      text=""
                      icon={<DeleteIcon />}
                      onClick={handleDelete(perimeter.id)}
                      isAsync
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
