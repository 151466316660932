import { Layer } from '@vis.gl/react-maplibre'
import { hoverColor } from 'utils'

interface Props {
  sourceId: string
  layers: [string, string]
  hoveredObjectsIds: string[]
  opacity: number
  lineFilter: string
  visibility: boolean
  color: string
  lineColor: string
}

/**
 * Generic layer displaying a line and (eventually) a label
 * It handles hovered object text highlighting and opacity decrease when filtering on a line
 * Renders a line and a label mapbox layers
 * @param {string} sourceId - The id of the source
 * @param {string[]} layers - The ids of the layers
 * @param {string[]} hoveredObjectsIds - The ids of the hovered objects
 * @param {number} opacity - The opacity of the layer
 * @param {string} lineFilter - The line filter
 * @param {boolean} visibility - The visibility of the layer
 * @param {string} color - The color of the label
 * @param {string} lineColor - The color of the line
 * @return {JSX.Element} The line layer
 */
export default function LineLayer({
  sourceId, layers, hoveredObjectsIds, opacity, lineFilter, visibility, color, lineColor,
}: Props) {
  const [lineLayer, labelLayer] = layers
  return (
    <>
      <Layer
        type="line"
        paint={{
          'line-width': 3,
          'line-color': hoverColor(lineColor, hoveredObjectsIds),
          'line-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={lineLayer}
        source={sourceId}
        source-layer={sourceId}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        minzoom={12}
      />
      <Layer
        type="symbol"
        id={labelLayer}
        source={sourceId}
        source-layer={sourceId}
        paint={{
          'text-color': '#ffffff',
          'text-halo-color': hoverColor(color, hoveredObjectsIds),
          'text-halo-width': 1.5,
        }}
        layout={{
          'text-font': ['Open Sans Regular'],
          'symbol-placement': 'line',
          'symbol-spacing': 500,
          'text-size': 14,
          'text-allow-overlap': false,
          'text-field': '{libelle}',
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={12}
      />
    </>
  )
}
