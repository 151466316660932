import { CHARTIS_SOURCES_IDS, isLayerSelected, isSomeChildSelected, PerimeterSignals } from 'services'
import { POSTES_LAYER_KEYS } from 'assets/layers'
import {
  AccesLayer, ADVLayer, ADVLineLayer, BALLayer, BifLayer, CDVLayer, CenterLayer, ChantierLayer, CSSLayer, DBCLayer,
  ExploitationModeLayer, IHMLabelLayer, IHMLayer, JDZLayer, LocalisateurLayer, PassageNiveauLayer, PedaleLayer,
  PosteLayer, PRILayer, RacLayer, SignalLayer, SousStationLayer, StationLayer, StructureLayer, TrackLayer,
  UTMLayer,
  ZAPLabelLayer,
  ZAPLayer,
} from './layers'
import EditorLayer from './layers/editor/EditorLayer'

export default function SchLayers() {
  return (
    <>
      <ExploitationModeLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('exploitation-mode-unique')
          || isLayerSelected('exploitation-mode-banalisee') || isLayerSelected('exploitation-mode-ipcs')}
      />
      <TrackLayer
        source={CHARTIS_SOURCES_IDS.trackSch}
        bpolygonParam="bpolygon"
      />
      <UTMLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('utm')}
      />
      <PRILayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('pri')}
      />
      <StructureLayer
        view="full_rgi_track_sch_flat"
        visibilityZP={isLayerSelected('zp')}
        visibilityInfrapole={isLayerSelected('infrapole')}
      />
      <JDZLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('jdz')}
        angle="angle_rgi_track_sch_flat"
      />
      <ZAPLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('zoneActionPoste')}
      />
      <BALLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('bal-zone')}
      />
      <IHMLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('ihm-zone')}
      />
      <ADVLineLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('adv')}
      />
      <CDVLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('cdv')}
      />
      <RacLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('rac')}
      />
      <ChantierLayer
        view="full_rgi_track_sch_flat"
        visibility={isSomeChildSelected('Par année de travaux') && isSomeChildSelected('Par phase')}
      />
      <ADVLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('adv')}
      />
      <LocalisateurLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('localisateur')}
      />
      <PedaleLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('pedale')}
      />
      <AccesLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('acces')}
      />
      <BifLayer
        view="full_rgi_track_sch_flat_centroid"
        visibility={isLayerSelected('bif')}
      />
      <DBCLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('dbc')}
        angle="angle_rgi_track_sch_flat"
      />
      <PassageNiveauLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('passageNiveau')}
        angle="angle_rgi_track_sch_flat"
      />
      <StationLayer
        view="full_rgi_track_sch_flat_centroid"
        visibility={isLayerSelected('station')}
      />
      <SousStationLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('sousStation')}
      />
      <CSSLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('css')}
      />
      <SignalLayer
        view="full_rgi_track_sch_flat"
        visibility={isSomeChildSelected('Signaux')}
        angle="angle_rgi_track_sch_flat"
      />
      <PosteLayer
        view="full_rgi_track_sch_flat_centroid"
        visibility={Object.values(POSTES_LAYER_KEYS).some(isLayerSelected)}
      />
      <CenterLayer
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('center')}
      />
      <ZAPLabelLayer
        view="full_rgi_track_sch_flat_libelle"
        visibility={isLayerSelected('zoneActionPoste')}
      />
      <IHMLabelLayer
        view="full_rgi_track_sch_flat_libelle"
        visibility={isLayerSelected('ihm-zone')}
      />
      {PerimeterSignals.drawing.value && (
        <EditorLayer />
      )}
    </>
  )
}
