import terms from 'assets/terms'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import {
  Button, ButtonStyle, ConfirmModal, DropMenu, ModalSignal, popModal, pushModal,
} from 'components'
import { Member } from 'types'
import { deleteTeamMember, TeamSignal } from 'services/users'
import AddTeamMembers from './AddTeamMembers'

import './TeamMembersModal.scss'

type Props = {
  teamId: string
}

export default function TeamMembersModal({ teamId }: Props) {
  const team = TeamSignal.list.value.find(t => t.id === teamId)
  const handleGoBack = () => {
    ModalSignal.value = null
  }

  const handleDeleteMember = (user: Member) => () => {
    if (!Array.isArray(ModalSignal.value)) return
    ModalSignal.value = [
      ...ModalSignal.value,
      (
        <ConfirmModal
          key={1}
          title={`Supprimer "${user.displayName}" de l'équipe ?`}
          handleValidate={() => { deleteTeamMember(team, user.id); popModal() }}
          handleClose={popModal}
        />
      ),
    ]
  }

  const handleAddMember = () => {
    pushModal(<AddTeamMembers teamId={teamId} />)
  }

  const getDropMenuActions = (user: Member) => [
    { label: 'Retirer de l\'équipe', onClick: handleDeleteMember(user) },
  ]

  return (
    <div className="team-members-modal hide-scroll">
      <div className="header"><h2>{`${terms.Modals.Teams.members} "${team.nom}"`}</h2></div>
      <table className="members">
        <thead>
          <tr>
            <th><div className="name">CP</div></th>
            <th><div className="role">Nom</div></th>
            <th><div className="actions">Entité</div></th>
            <th><div className="actions">Role</div></th>
            <th><div className="actions">Action</div></th>
          </tr>
        </thead>
        <tbody>
          {team.users.map(user => (
            <tr key={user?.id}>
              <td>{user?.username}</td>
              <td>{`${user?.firstName} ${user?.lastName}`}</td>
              <td>{`${team.entites?.[0]?.nom} - PRI` || 'N/C'}</td>
              <td>
                {user?.type === 'member' ? (
                  <span className="role member">Membre</span>
                ) : (
                  <span className="role leader">Responsable</span>
                )}
              </td>
              <td>
                <DropMenu actions={getDropMenuActions(user)}>
                  <Button
                    text=""
                    style={ButtonStyle.borderLess}
                    icon={<SettingsIcon />}
                  />
                </DropMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="actions">
        <Button
          text={terms.Common.back}
          icon={<ArrowLeftIcon />}
          style={ButtonStyle.light}
          onClick={handleGoBack}
        />
        <Button
          text={terms.Modals.Teams.addMember}
          onClick={handleAddMember}
        />
      </div>
    </div>
  )
}
