import terms from 'assets/terms'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import {
  Button, ButtonStyle, ConfirmModal, DropMenu, ModalSignal, popModal, pushModal,
} from 'components'
import { Member } from 'types'
import { deleteSectionMember, SectionSignal } from 'services/users'
import AddSectionMembers from './AddSectionMembers'

import './SectionMembersModal.scss'

type Props = {
  sectionId: string
}

export default function SectionMembersModal({ sectionId }: Props) {
  const section = SectionSignal.list.value.find(t => t.id === sectionId)
  const handleGoBack = () => {
    ModalSignal.value = null
  }

  const handleDeleteMember = (user: Member) => () => {
    if (!Array.isArray(ModalSignal.value)) return
    ModalSignal.value = [
      ...ModalSignal.value,
      (
        <ConfirmModal
          key={1}
          title={`Supprimer "${user.displayName}" de la section ?`}
          handleValidate={() => { deleteSectionMember(section, user.id); popModal() }}
          handleClose={popModal}
        />
      ),
    ]
  }

  const handleAddMember = () => {
    pushModal(<AddSectionMembers sectionId={sectionId} />)
  }

  const getDropMenuActions = (user: Member) => [
    { label: 'Retirer de la section', onClick: handleDeleteMember(user) },
  ]

  return (
    <div className="section-members-modal hide-scroll">
      <div className="header"><h2>{`${terms.Modals.Sections.members} "${section.nom}"`}</h2></div>
      <table className="members">
        <thead>
          <tr>
            <th><div className="name">CP</div></th>
            <th><div className="name">Email</div></th>
            <th><div className="role">Nom</div></th>
            <th><div className="actions">Entité</div></th>
            <th><div className="actions">Action</div></th>
          </tr>
        </thead>
        <tbody>
          {section.users.map(user => (
            <tr key={user?.id}>
              <td>{user?.username}</td>
              <td>{user?.email}</td>
              <td>{`${user?.firstName} ${user?.lastName}`}</td>
              <td>{`${section.entite?.nom} - PRI` || 'N/C'}</td>
              <td>
                <DropMenu actions={getDropMenuActions(user)}>
                  <Button
                    text=""
                    style={ButtonStyle.borderLess}
                    icon={<SettingsIcon />}
                  />
                </DropMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="actions">
        <Button
          text={terms.Common.back}
          icon={<ArrowLeftIcon />}
          style={ButtonStyle.light}
          onClick={handleGoBack}
        />
        <Button
          text={terms.Modals.Sections.addMember}
          onClick={handleAddMember}
        />
      </div>
    </div>
  )
}
