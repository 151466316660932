import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'
import RectangleLayer from './generic/RectangleLayer'

export default function SousStationLayer({ visibility, view }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.sousStation,
    view,
    perimeterParam,
  )

  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <RectangleLayer
      sourceId={CHARTIS_SOURCES_IDS.sousStation}
      layers={[MAPBOX_LAYER_IDS.sousStationRect, MAPBOX_LAYER_IDS.sousStationCircle]}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      color="#80009a"
      strokeColor="#3492da"
      textField={[
        'format',
        'SST',
        {
          'font-scale': 1.2,
        },
        '\n',
        {},
        ['get', 'libelle'],
        '\n',
        {},
        'km ',
        ['get', 'lrs_pk'],
        '\n',
        {},
        ['get', 'type_electrifications_id_libelle'],
      ]}
    />
  )
}
