import { Layer } from '@vis.gl/react-maplibre'
import { DataDrivenPropertyValueSpecification } from 'maplibre-gl'
import { hoverColor } from 'utils'

interface Props {
  sourceId: string
  layers: [string, string, string]
  color: string
  hoveredObjectsIds: string[]
  opacity: number
  lineFilter: string
  visibility: boolean
  textField: DataDrivenPropertyValueSpecification<string>
}

/**
 * Generic layer displaying a point with a label
 * It handles hovered object text highlighting and opacity decrease when filtering on a line
 * Renders three mapbox layers: one for the point, one for the highlight circle and one for the label
 * @param {string} sourceId - The id of the source
 * @param {string[]} layers - The ids of the layers [point, highlight, label]
 * @param {string} color - The color of the point
 * @param {string[]} hoveredObjectsIds - The ids of the hovered objects
 * @param {number} opacity - The opacity of the layer
 * @param {string} lineFilter - The line filter
 * @param {boolean} visibility - The visibility of the layer
 * @param {DataDrivenPropertyValueSpecification<string>} textField - The text field
 * @return {JSX.Element} The point layer
*/

export default function PointLayer({
  sourceId, layers, color, hoveredObjectsIds, opacity, lineFilter, visibility, textField,
}: Props) {
  const [circleLayer, highlightLayer, labelLayer] = layers
  return (
    <>
      <Layer
        type="circle"
        paint={{
          'circle-radius': 3,
          'circle-color': hoverColor(color, hoveredObjectsIds),
          'circle-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={circleLayer}
        source={sourceId}
        source-layer={sourceId}
        layout={{
          visibility: visibility ? 'visible' : 'none',
          'circle-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], -1, 0],
        }}
        minzoom={9}
      />
      <Layer
        type="circle"
        id={highlightLayer}
        source={sourceId}
        source-layer={sourceId}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        paint={{
          'circle-radius': 6,
          'circle-stroke-color': '#ff0000',
          'circle-stroke-width': 2,
          'circle-color': 'transparent',
        }}
        filter={['in', ['get', 'id'], ['literal', hoveredObjectsIds]]}
        minzoom={9}
      />
      <Layer
        type="symbol"
        id={labelLayer}
        paint={{
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-halo-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
            'transparent'],
          'text-halo-width': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
            0.1],
        }}
        source={sourceId}
        source-layer={sourceId}
        layout={{
          'text-field': textField,
          'text-font': ['Open Sans Regular'],
          'text-size': 13,
          'text-offset': ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-1, 0]], ['literal', [1, 0]]],
          'text-anchor': ['case', ['==', ['get', 'lrs_sens'], 'C'], 'right', 'left'],
          'symbol-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], -1, 0],
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={12}
      />
    </>
  )
}
