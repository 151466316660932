import { Layer } from '@vis.gl/react-maplibre'
import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'

const sourceLayer = `label-${CHARTIS_SOURCES_IDS.zap}`

export default function ZAPLabelLayer({ view, visibility }:LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.zap,
    view,
    perimeterParam,
    sourceLayer,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <Layer
      type="symbol"
      id={MAPBOX_LAYER_IDS.zapLabel}
      source-layer={CHARTIS_SOURCES_IDS.zap}
      source={sourceLayer}
      layout={{
        'icon-image': ['concat', 'rectanglearrondi-', ['get', 'color']],
        'icon-text-fit': 'both',
        'icon-allow-overlap': false,
        'text-font': ['Open Sans Bold'],
        'text-allow-overlap': false,
        'text-size': 12,
        'text-justify': 'left',
        'text-field': [
          'format',
          ['get', 'libelle'],
        ],
        'text-max-width': 1000,
        'text-anchor': 'right',
        visibility: visibility ? 'visible' : 'none',
      }}
      paint={{
        'text-color': '#fff',
        'text-opacity': ['case',
          ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
          opacity],
        'icon-opacity': ['case',
          ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
          opacity],
      }}
      minzoom={12}
    />
  )
}
