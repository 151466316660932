import { Layer } from '@vis.gl/react-maplibre'
import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { hoverColor, useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'

export default function ADVLineLayer({ view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.advLine,
    view,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <Layer
      id={MAPBOX_LAYER_IDS.advLine}
      type="line"
      paint={{
        'line-width': 3,
        'line-color': hoverColor('#FF9313', hoveredObjectsIds),
        'line-opacity': ['case',
          ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
          opacity],
      }}
      source-layer={CHARTIS_SOURCES_IDS.advLine}
      source={CHARTIS_SOURCES_IDS.advLine}
      layout={{ visibility: visibility ? 'visible' : 'none' }}
      minzoom={9}
    />
  )
}
