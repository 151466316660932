import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerWithAngleProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'
import PictoLayer from './generic/PictoLayer'

export default function PassageNiveauLayer({ view, visibility, angle }: LayerWithAngleProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.passageNiveau,
    view,
    perimeterParam,
  )

  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <PictoLayer
      sourceId={CHARTIS_SOURCES_IDS.passageNiveau}
      layer={MAPBOX_LAYER_IDS.passageNiveau}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      layout={{
        'text-field': ['get', 'libelle'],
        'icon-image': 'pn_no_label',
        'icon-rotate': ['+', ['coalesce', ['get', angle], 0], -90],
        'icon-size': 0.5,
      }}
    />
  )
}
