import { Accordion } from 'components'
import { IndiceIDF } from 'types'

type Props = {
  collectionIdf: {
    indices?: IndiceIDF[];
    nom_collection_idf: string;
    nombre_dc_bloque: number | null;
    nombre_dc_non_commence: number | null;
    nombre_dc_non_termine: number | null;
    nombre_en_attente_rc: number | null;
    nombre_etude_en_cours: number | null;
  }
}

export default function Chantiers({ collectionIdf }: Props) {
  return (
    <>
      <Accordion title="Retours Chantiers" defaultExpanded>
        <p>
          <b>{'Collection IDF : '}</b>
          <span>{collectionIdf.nom_collection_idf}</span>
        </p>
        <table style={{ width: 'fit-content' }}>
          <tr>
            <td>Etudes en cours</td>
            <td>{collectionIdf.nombre_etude_en_cours}</td>
          </tr>
          <tr>
            <td>RC en attente</td>
            <td>{collectionIdf.nombre_en_attente_rc}</td>
          </tr>
          <tr>
            <td>DC réalisables non lancés</td>
            <td>{collectionIdf.nombre_dc_non_commence}</td>
          </tr>
          <tr>
            <td>DC bloqués</td>
            <td>{collectionIdf.nombre_dc_bloque}</td>
          </tr>
          <tr>
            <td>DC en cours</td>
            <td>{collectionIdf.nombre_dc_non_termine}</td>
          </tr>
        </table>
        <table>
          <thead>
            <tr>
              <th>Indice</th>
              <th>Statut</th>
              <th>Projet / Travaux</th>
            </tr>
          </thead>
          <tbody>
            {collectionIdf.indices?.map(indice => (
              <tr key={indice.id}>
                <td>{indice.name}</td>
                <td>{indice.etat_collection}</td>
                <td>{indice.libelle_operation}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
      <div className="separator" />
    </>
  )
}
