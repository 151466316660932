/* eslint-disable no-console */
import terms from 'assets/terms'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, ButtonStyle, ModalSignal, SaveViewModal } from 'components'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as LayerIcon } from 'assets/icons/layers.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'
import { ReactComponent as PageViewIcon } from 'assets/icons/pageview.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as PerimeterIcon } from 'assets/icons/perimeter.svg'
import { ButtonSize } from 'components/button/Button'
import { PerimeterSignals, startPerimeterDraw } from 'services/perimeters'
import { ContentSignals } from 'services'
import { PanelSignal } from '../panels/PanelsManager'
import LayerPanel from '../panels/layers/LayerPanel'
import SearchPanel from '../panels/search/SearchPanel'
import OnScreenPanel from '../panels/onScreen/OnScreenPanel'
import PerimeterInfoPanel from '../panels/perimeterInfo/PerimeterInfoPanel'

import './ToolsBar.scss'

export default function ToolsBar() {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const doesSchDisplayed = (
    ContentSignals.left.data.value?.type === 'sch' || ContentSignals.right.data.value?.type === 'sch'
  )

  const tools = [
    {
      label: terms.Header.saveView,
      icon: <GridIcon />,
      onClick: () => { ModalSignal.value = <SaveViewModal /> },
      enable: true,
      visible: true,
    },
    doesSchDisplayed && {
      label: !PerimeterSignals.drawing.value ? terms.Header.createPerimeter : terms.Header.creatingPerimeter,
      icon: <PlusIcon />,
      onClick: startPerimeterDraw,
      enable: !PerimeterSignals.drawing.value,
      visible: !PerimeterSignals.activePerimeter.value,
    },
    {
      label: terms.Header.closePerimeter,
      icon: <CloseIcon />,
      onClick: () => {
        PerimeterSignals.activePerimeter.value = null
        navigate(`/?${params}`)
      },
      enable: true,
      visible: !!PerimeterSignals.activePerimeter.value,
    },
    {
      label: terms.Header.detailPerimeter,
      icon: <PerimeterIcon />,
      onClick: () => { PanelSignal.value = <PerimeterInfoPanel perimeter={PerimeterSignals.activePerimeter.value} /> },
      enable: true,
      visible: !!PerimeterSignals.activePerimeter.value,
    },
    {
      label: terms.Header.createObject,
      icon: <ShareIcon />,
      onClick: () => console.log('Create Object'),
      enable: false,
      visible: false,
    },
    {
      label: terms.Header.layers,
      icon: <LayerIcon />,
      onClick: () => { PanelSignal.value = <LayerPanel /> },
      enable: true,
      visible: true,
    },
    {
      label: terms.Common.search,
      icon: <SearchIcon />,
      onClick: () => { PanelSignal.value = <SearchPanel /> },
      enable: true,
      visible: true,
    },
    {
      label: terms.Header.toScreen,
      icon: <PageViewIcon />,
      onClick: () => { PanelSignal.value = <OnScreenPanel /> },
      enable: true,
      visible: true,
    },
  ]

  return (
    <div className="tools-bar">
      {tools.filter(tool => tool.visible).map(tool => (
        <Button
          className={tool.label === terms.Header.layers ? 'layers' : ''}
          key={tool.label}
          style={ButtonStyle.borderLessWhite}
          text={tool.label}
          size={ButtonSize.medium}
          icon={tool.icon}
          onClick={tool.onClick}
          disabled={!tool.enable}
        />
      ))}
    </div>
  )
}
