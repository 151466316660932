import { get } from '@osrdata/app_core/dist/requests'
import { catchable } from 'utils'
import { UserCerbere } from 'types'

export const getCerbereUsers = async (searchValue: string) => {
  const [error, response] = await catchable(
    () => get<{ results: UserCerbere[] }>('/cerbere/users/', { search: searchValue }),
  )

  if (!error) {
    return response.results
  }

  return []
}

export const getCerbereGroups = async () => {
  const [error, response] = await catchable(() => get<{results: UserCerbere[]}>('/cerbere/groups/'))

  if (!error) {
    return response.results
  }

  return []
}

export const getCerbereMembers = async (groupId: string) => {
  const [error, response] = await catchable(() => get<UserCerbere[]>(`/cerbere/groups/${groupId}/members/`))

  if (!error) {
    return response
  }

  return null
}
