import { Layer } from '@vis.gl/react-maplibre'
import {
  CHARTIS_SOURCES_IDS,
  getDistinctInfrapoleCentroid,
  getDistinctZPCentroid,
  MAPBOX_LAYER_IDS,
  MapDataSignal,
  useBorderLabels,
} from 'services'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'

interface Props {
  view: string
  visibilityZP: boolean
  visibilityInfrapole: boolean
}

const LABEL_SOURCE_ZP_ID = 'zp-labels'
const LABEL_SOURCE_INFRAPOLE_ID = 'infrapole-labels'
export default function StructureLayer({ view, visibilityZP, visibilityInfrapole }: Props) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useBorderLabels([MAPBOX_LAYER_IDS.zp], visibilityZP, LABEL_SOURCE_ZP_ID, getDistinctZPCentroid)
  useBorderLabels(
    [MAPBOX_LAYER_IDS.infrapole],
    visibilityInfrapole,
    LABEL_SOURCE_INFRAPOLE_ID,
    getDistinctInfrapoleCentroid,
  )
  useSource(
    CHARTIS_SOURCES_IDS.structure,
    view,
    {
      ...perimeterParam,
      libelle__ilike: '%Siège INFRAPOLE%',
    },
  )

  const hoveredZPIds = useHoveredObjectsIds('zone_de_production_libelle')
  const hoveredInfrapoleIds = useHoveredObjectsIds('libelle')

  const { opacity, lineFilter } = MapDataSignal

  return (
    <>
      <Layer
        type="line"
        paint={{
          'line-color': ['get', 'zone_de_production_color'],
          'line-opacity': [
            'case',
            ['in', lineFilter.value, ['get', 'code_ligne']], 1,
            opacity,
          ],
          'line-width': [
            'case',
            ['in', ['get', 'zone_de_production_libelle'], ['literal', hoveredZPIds]], 4,
            2,
          ],
        }}
        id={MAPBOX_LAYER_IDS.zp}
        source-layer={CHARTIS_SOURCES_IDS.structure}
        source={CHARTIS_SOURCES_IDS.structure}
        layout={{
          visibility: visibilityZP ? 'visible' : 'none',
          'line-sort-key': [
            'case',
            ['in', ['get', 'zone_de_production_libelle'], ['literal', hoveredZPIds]],
            0, -1],
        }}
      />
      <Layer
        type="line"
        paint={{
          'line-color': ['get', 'infrapole_color'],
          'line-opacity': [
            'case',
            ['in', lineFilter.value, ['get', 'code_ligne']], 1,
            opacity,
          ],
          'line-width': [
            'case',
            ['in', ['get', 'libelle'], ['literal', hoveredInfrapoleIds]], 4,
            2,
          ],
        }}
        id={MAPBOX_LAYER_IDS.infrapole}
        source-layer={CHARTIS_SOURCES_IDS.structure}
        source={CHARTIS_SOURCES_IDS.structure}
        layout={{
          visibility: visibilityInfrapole ? 'visible' : 'none',
          'line-sort-key': ['case', ['in', ['get', 'libelle'], ['literal', hoveredInfrapoleIds]], 0, -1],
        }}
      />
      <Layer // ZP labels
        type="symbol"
        id={MAPBOX_LAYER_IDS.zpLabel}
        source={LABEL_SOURCE_ZP_ID}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-field': '{zone_de_production_libelle}',
          'text-size': 14,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-offset': [0, 1],
          visibility: visibilityZP ? 'visible' : 'none',
          'text-allow-overlap': true,
        }}
        paint={{
          'text-color': ['get', 'zone_de_production_color'],
          'text-halo-color': ['get', 'zone_de_production_color'],
          'text-halo-width': 0.5,
        }}
        maxzoom={24}
        minzoom={8}
      />
      <Layer // Infrapole labels
        type="symbol"
        id={MAPBOX_LAYER_IDS.infrapoleLabel}
        source={LABEL_SOURCE_INFRAPOLE_ID}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-field': '{libelle}',
          'text-size': 14,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-offset': [0, 1],
          visibility: visibilityInfrapole ? 'visible' : 'none',
          'text-allow-overlap': true,
        }}
        paint={{
          'text-color': ['get', 'infrapole_color'],
          'text-halo-color': ['get', 'infrapole_color'],
          'text-halo-width': 0.5,
        }}
        maxzoom={24}
        minzoom={8}
      />
    </>
  )
}
