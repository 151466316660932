/* eslint-disable max-len */
import centroid from '@turf/centroid'
import { Feature, FeatureCollection } from 'geojson'
import { useMap } from '@vis.gl/react-maplibre'
import { useEffect } from 'react'
import { GeoJSONSource } from 'maplibre-gl'

const getDistinctCentroid = (features: Feature[], labelField: string, colorField: string, idField: string): FeatureCollection => {
  const featureGroups = features.reduce((acc: {id:number, label: string, color: string, features: Feature[]}[], feature) => {
    const label = feature.properties?.[labelField]
    const color = feature.properties?.[colorField]
    const id = feature.properties?.[idField]
    const existingGroup = acc.find(group => group.label === label)
    if (existingGroup) {
      existingGroup.features.push(feature)
    } else {
      acc.push({ id, label, color, features: [feature] })
    }
    return acc
  }, [])
  return {
    type: 'FeatureCollection',
    features: featureGroups.map(group => centroid(
      { type: 'FeatureCollection', features: group.features },
      { properties: { [idField]: group.id, [labelField]: group.label, [colorField]: group.color } },
    )),
  }
}

export const getDistinctPRICentroid = (features: Feature[]) => getDistinctCentroid(features, 'region_libelle', 'region_color', 'id')

export const getDistinctUTMCentroid = (features: Feature[]) => getDistinctCentroid(features, 'structure_libelle', 'color', 'id')

export const getDistinctZPCentroid = (features: Feature[]) => getDistinctCentroid(features, 'zone_de_production_libelle', 'zone_de_production_color', 'gaia_id')

export const getDistinctInfrapoleCentroid = (features: Feature[]) => getDistinctCentroid(features, 'libelle', 'infrapole_color', 'gaia_id')

export const useBorderLabels = (
  layers: string[],
  visibility: boolean,
  labelSourceId: string,
  parseFeatureCollection: (features: Feature[]) => FeatureCollection,
) => {
  const maps = useMap()

  useEffect(() => {
    const map = maps?.current?.getMap()
    if (!map) return undefined
    if (!visibility) {
      map.getSource<GeoJSONSource>(labelSourceId)?.setData({ type: 'FeatureCollection', features: [] })
      return undefined
    }
    const calculateLabels = () => {
      const lines = map.queryRenderedFeatures({ layers }) as Feature[] || []
      const labels = parseFeatureCollection(lines)
      if (!map.getSource(labelSourceId)) {
        map.addSource(labelSourceId, {
          type: 'geojson',
          data: labels,
        })
      } else {
        map.getSource<GeoJSONSource>(labelSourceId).setData(labels)
      }
    }
    map.once('idle', calculateLabels) // Only once to avoid infinite loop
    map.on('moveend', calculateLabels)

    return () => {
      map.off('moveend', calculateLabels)
    }
  }, [visibility])
}
