/* eslint-disable quote-props */
import { MenuLayer } from 'types'

/**
 * Define the layers menu order and structure
 *
 * If you update this list, do not forget to update the layerIndexes below
 */
export const layerMenu: MenuLayer = {
  'Zone d\'action DEX': {
    'zoneActionPoste': 'Zone d\'action des postes',
    // 'zoneActionPosteAmendee': 'Zones d\'action des postes (éditables)',
    'bal-zone': 'Zones de tronçons (BAL, etc.)',
    'ihm-zone': 'Zones IHM',
    // 'ihm-zone-amendee': 'Zones IHM (éditables)',
  },
  'Statut des collections DEX': {
    type: 'radio',
    'collection-status-study': 'Etudes en cours',
    'collection-status-waiting': 'RC en attente',
    'RC reçus, non conformes': {
      type: 'radio-child',
      'collection-status-not-started': 'DC réalisables non lancés',
      'collection-status-blocked': 'DC bloqués par un indice',
      'collection-status-done': 'DC en cours',
    },
  },
  'Signalisation': {
    'Signaux': {
      'signalProtection': 'SG PROTECT',
      'signalArretAnnonce': 'SG ANNONCE',
      'signalLimitationDeVitesse': 'SG LIMVIT',
      'signalManoeuvre': 'SG MANOEUV',
      'signalIndicateurDirection': 'SG DIR',
      'signalDepartTrain': 'SG DEPART',
      'signalSortieGroupesVoiesConvergentes': 'SG CONVERG',
      'signalTGV': 'SG TGV',
      'signalTractionElectrique': 'SG TRELECT',
      'signalTableauPancarte': 'SG DIVERS',
      tooltip: {
        'signalProtection': 'Signal de Protection et Signal de cantonnement',
        'signalArretAnnonce': 'Signaux d\'annonce d\'arrêt',
        'signalLimitationDeVitesse': 'Signaux de limitation de vitesse',
        'signalManoeuvre': 'Signaux de manœuvre',
        'signalIndicateurDirection': 'Signal indicateur de direction',
        'signalDepartTrain': 'Signal de départ de train',
        'signalSortieGroupesVoiesConvergentes': 'Signal de sortie de groupes de voies convergentes',
        'signalTGV': 'Signal TGV',
        'signalTractionElectrique': 'Signal de traction électrique',
        'signalTableauPancarte': 'Tableaux et Pancartes à inscriptions diverses',
      },
    },
    'adv': 'Appareils de voie',
    'cdv': 'Circuits de voie',
    'jdz': 'Joints de zone',
    'dbc': 'Détecteurs de boîte chaude',
    'localisateur': 'Localisateurs',
    'passageNiveau': 'Passages à niveau',
    'pedale': 'Pédales',
  },
  'Energie': {
    'sousStation': 'Sous-stations',
  },
  'Bâtiments': {
    'station': 'Gares',
    'acces': 'Accès',
    'css': 'Centrales sous-stations',
    'Poste Majeurs': {
      'poste-cc': 'Commandes centralisées',
      'Postes VP - informatisé': {
        'poste-argos': 'ARGOS',
        'poste-pai': 'PAI',
        'poste-pipc': 'PIPC',
        tooltip: {
          'poste-argos': 'Poste de VP électrique à enclenchement informatique de type ARGOS',
          'poste-pai': 'Poste de VP électrique à enclenchement informatique',
          'poste-pipc': 'Poste de VP électrique à enclenchement informatique à base de technologie PC',
        },
      },
      'Postes VP - à relais': {
        'poste-prci': 'PRCI',
        'poste-prs': 'PRS',
        'poste-prg': 'PRG',
        'poste-prmi': 'PRMI',
        tooltip: {
          'poste-prci': 'Poste de VP électrique à relais à commande informatique',
          'poste-prs': 'Poste de VP électrique tout relais à transit souple',
          'poste-prg': 'Poste de VP électrique tout relais à cablage géographique',
          'poste-prmi': 'Poste de VP électrique tout relais à manettes individuelles',
        },
      },
      'Postes VP - électrique': {
        'poste-plit': 'PLIT',
        'poste-peli': 'PELI',
        'poste-pelir': 'PELIR',
        'poste-pml': 'PML',
        tooltip: {
          'poste-plit': 'Poste de VP électrique à leviers d\'itinéraire',
          'poste-peli': 'Poste de VP électrique à leviers individuels unifiés',
          'poste-pelir': 'Poste de VP électrique à leviers individuels régionaux ou constructeur',
          'poste-pml': 'Poste de VP électrique à manettes libres',
        },
      },
      'Postes VP - mécanique': {
        'poste-emu': 'EMU',
        'poste-mu45': 'MU45',
        'poste-mrsaxby': 'MR-SAXBY',
        'poste-mrlevdr': 'MR-LEV-DR',
        'poste-mrvignty': 'MR-VIGNTY',
        'poste-mrexal': 'MR-EX-AL',
        'poste-ssdv': 'SS-DV',
        'poste-ssvuvb': 'SS-VUVB',
        tooltip: {
          'poste-emu': 'Poste de VP mécanique électromécanique unifié',
          'poste-mu45': 'Poste de VP mécanique électromécanique type 45',
          'poste-mrsaxby': 'Poste de VP mécanique régional type SAXBY',
          'poste-mrlevdr': 'Poste de VP mécanique régional à levier à demi-révolution SAXBY ou POTRON',
          'poste-mrvignty': 'Poste de VP mécanique régional type VIGNIER ou TYER',
          'poste-mrexal': 'Poste de VP mécanique régional ex-AL',
          'poste-ssdv': 'Poste de VP mécanique à serrures S de double voie',
          'poste-ssvuvb': 'Poste de VP mécanique à serrures S de voie unique ou voie banalisée',
        },
      },
      'Postes VS (service)': {
        'poste-pvstri': 'PVS-TRI',
        'poste-pvselec': 'PVS-ELEC',
        'poste-pvsei': 'PVS-EI',
        'poste-pvsci': 'PVS-CI',
        'poste-pvsmec': 'PVS-MEC',
        tooltip: {
          'poste-pvstri': 'Poste de VS de triage à commande automatique',
          'poste-pvselec': 'Poste de VS électrique',
          'poste-pvsei': 'Poste de VS à enclenchement informatique',
          'poste-pvsci': 'Poste de VS à commande informatique',
          'poste-pvsmec': 'Poste de VS mécanique non enclenché',
        },
      },
      tooltip: {
        'poste-cc': 'Poste de VP électrique commande centralisée',
      },
    },
    'Poste Autres': {
      'poste-point': 'Point',
      'poste-levier': 'Levier',
      'poste-p10': 'P-10',
      'poste-autres': 'Autres',
      tooltip: {
        'poste-point': 'Poste divers point de manoeuvre',
        'poste-levier': 'Poste divers levier isolé',
        'poste-p10': 'Poste divers de moins de 10 leviers',
      },
    },
    'center': 'Centres d\'appareillage',
    // 'center-armen': 'Centres d\'appareillage (ARMEN)',
  },
  'Voies et exploitation': {
    'bif': 'Bifurcations',
    'rac': 'Raccordements',
    'exploitation-mode-unique': 'Voie unique',
    'exploitation-mode-banalisee': 'Voie banalisée',
    'exploitation-mode-ipcs': 'IPCS',
    tooltip: {
      'exploitation-mode-ipcs': 'Installations permanentes de contre sens',
    },
  },
  'Frontières métiers': {
    type: 'radio',
    'pri': 'Régions PRI',
    'infrapole': 'Infrapôles',
    'utm': 'Unités de maintenance',
    'zp': 'Zone de production',
  },
  'Travaux': {
    'Par année de travaux': {
      'chantier-2024': '2024',
      'chantier-2025': '2025',
      'chantier-2026': '2026',
      'chantier-2027': '2027',
      'chantier-2028': '2028',
      'chantier-2029': '2029',
      'chantier-2030-plus': '2030 et plus',
    },
    'Par phase': {
      'chantier-phase-rea': 'REA',
      'chantier-phase-tvx': 'TVX',
      'chantier-phase-tvxp': 'TVXP',
      'chantier-phase-rea-tvxp': 'REA_TVXP',
      'chantier-phase-rea-tvx': 'REA_TVX',
      'chantier-phase-apo-rea': 'APO_REA',
      'chantier-phase-apo-tvx': 'APO_TVX',
      'chantier-phase-apo-rea-tvx': 'APO_REA_TVX',
      'chantier-phase-pro-rea': 'PRO_REA',
    },
    'Filtre par type d\'identifiant': {
      type: 'radio',
      'chantier-astre': 'ASTRE',
      'chantier-seism': 'SEISM',
      'chanter-astre-and-seism': 'ASTRE et SEISM',
      tooltip: {
        'chantier-astre': 'Afficher uniquement les travaux ayant un code ASTRE',
        'chantier-seism': 'Afficher uniquement les travaux ayant un code SEISM',
        'chanter-astre-and-seism': 'Afficher uniquement les travaux ayant un code ASTRE et SEISM',
      },
    },
  },
}

/**
 * Map layer slug to index in order to shorten the URL
 * Its also define children descendants for each parent index
 *
 * /!\ DO NOT UPDATE INDEX ENTRIES /!\
 * Favorite / save views feature will break if you do. You can still remove or add new entries if needed.
 *
 * If you update this list, do not forget to update the layerMenu above
 */
export const layerIndexes = {
  '1': 'Zone d\'action DEX',
  '1.1': 'zoneActionPoste',
  // '1.2': 'zoneActionPosteAmendee',
  '1.3': 'bal-zone',
  '1.4': 'ihm-zone',
  // '1.5': 'ihm-zone-amendee',
  '2': 'Statut des collections DEX',
  '2.1': 'collection-status-study',
  '2.2': 'collection-status-waiting',
  '2.3': 'RC reçus, non conformes',
  '2.3.1': 'collection-status-not-started',
  '2.3.2': 'collection-status-blocked',
  '2.3.3': 'collection-status-done',
  '3': 'Signalisation',
  '3.1': 'Signaux',
  '3.1.1': 'signalProtection',
  '3.1.2': 'signalArretAnnonce',
  '3.1.3': 'signalLimitationDeVitesse',
  '3.1.4': 'signalManoeuvre',
  '3.1.5': 'signalIndicateurDirection',
  '3.1.6': 'signalDepartTrain',
  '3.1.7': 'signalSortieGroupesVoiesConvergentes',
  '3.1.8': 'signalTGV',
  '3.1.9': 'signalTractionElectrique',
  '3.1.10': 'signalTableauPancarte',
  '3.2': 'adv',
  '3.3': 'cdv',
  '3.4': 'jdz',
  '3.5': 'dbc',
  '3.6': 'localisateur',
  '3.7': 'passageNiveau',
  '3.8': 'pedale',
  '4': 'Energie',
  '4.1': 'sousStation',
  '5': 'Bâtiments',
  '5.1': 'station',
  '5.2': 'acces',
  '5.3': 'css',
  '5.4': 'Poste Majeurs',
  '5.4.1': 'poste-cc',
  '5.4.2': 'Postes VP - informatisé',
  '5.4.2.1': 'poste-argos',
  '5.4.2.2': 'poste-pai',
  '5.4.2.3': 'poste-pipc',
  '5.4.3': 'Postes VP - à relais',
  '5.4.3.1': 'poste-prci',
  '5.4.3.2': 'poste-prs',
  '5.4.3.3': 'poste-prg',
  '5.4.3.4': 'poste-prmi',
  '5.4.4': 'Postes VP - électrique',
  '5.4.4.1': 'poste-plit',
  '5.4.4.2': 'poste-peli',
  '5.4.4.3': 'poste-pelir',
  '5.4.4.4': 'poste-pml',
  '5.4.5': 'Postes VP - mécanique',
  '5.4.5.1': 'poste-emu',
  '5.4.5.2': 'poste-mu45',
  '5.4.5.3': 'poste-mrsaxby',
  '5.4.5.4': 'poste-mrlevdr',
  '5.4.5.5': 'poste-mrvignty',
  '5.4.5.6': 'poste-mrexal',
  '5.4.5.7': 'poste-ssdv',
  '5.4.5.8': 'poste-ssvuvb',
  '5.4.6': 'Postes VS (service)',
  '5.4.6.1': 'poste-pvstri',
  '5.4.6.2': 'poste-pvselec',
  '5.4.6.3': 'poste-pvsei',
  '5.4.6.4': 'poste-pvsci',
  '5.4.6.5': 'poste-pvsmec',
  '5.5': 'Poste Autres',
  '5.5.1': 'poste-point',
  '5.5.2': 'poste-levier',
  '5.5.3': 'poste-p10',
  '5.5.4': 'poste-autres',
  '5.6': 'center',
  // '5.7': 'center-armen',
  '6': 'Voies et exploitation',
  '6.1': 'bif',
  '6.2': 'rac',
  '6.3': 'exploitation-mode-unique',
  '6.4': 'exploitation-mode-banalisee',
  '6.5': 'exploitation-mode-ipcs',
  '7': 'Frontières métiers',
  '7.1': 'pri',
  '7.2': 'infrapole',
  '7.3': 'utm',
  '7.4': 'zp',
  '8': 'Travaux',
  '8.1': 'Par année de travaux',
  '8.1.1': 'chantier-2024',
  '8.1.2': 'chantier-2025',
  '8.1.3': 'chantier-2026',
  '8.1.4': 'chantier-2027',
  '8.1.5': 'chantier-2028',
  '8.1.6': 'chantier-2029',
  '8.1.7': 'chantier-2030-plus',
  '8.2': 'Par phase',
  '8.2.1': 'chantier-phase-rea',
  '8.2.2': 'chantier-phase-tvx',
  '8.2.3': 'chantier-phase-tvxp',
  '8.2.4': 'chantier-phase-rea-tvxp',
  '8.2.5': 'chantier-phase-rea-tvx',
  '8.2.6': 'chantier-phase-apo-rea',
  '8.2.7': 'chantier-phase-apo-tvx',
  '8.2.8': 'chantier-phase-apo-rea-tvx',
  '8.2.9': 'chantier-phase-pro-rea',
  '8.3': 'Filtre par type d\'identifiant',
  '8.3.1': 'chantier-astre',
  '8.3.2': 'chantier-seism',
  '8.3.3': 'chanter-astre-and-seism',
}

export const POSTES_LAYER_KEYS = {
  ARGOS: 'poste-argos',
  PAI: 'poste-pai',
  PRCI: 'poste-prci',
  PRS: 'poste-prs',
  PRG: 'poste-prg',
  PRMI: 'poste-prmi',
  CC: 'poste-cc',
  PLIT: 'poste-plit',
  PELI: 'poste-peli',
  'PELI-R': 'poste-pelir',
  PML: 'poste-pml',
  EMU: 'poste-emu',
  MU45: 'poste-mu45',
  'MR-SAXBY': 'poste-mrsaxby',
  'MR-LEV-DR': 'poste-mrlevdr',
  'MR-VIGNTY': 'poste-mrvignty',
  'MR-EX-AL': 'poste-mrexal',
  'SS-DV': 'poste-ssdv',
  'SS-VUVB': 'poste-ssvuvb',
  'PVS-TRI': 'poste-pvstri',
  'PVS-ELEC': 'poste-pvselec',
  'PVS-EI': 'poste-pvsei',
  'PVS-CI': 'poste-pvsci',
  'PVS-MEC': 'poste-pvsmec',
  PIPC: 'poste-pipc',
  POINT: 'poste-point',
  LEVIER: 'poste-levier',
  'P-10': 'poste-p10',
  Autres: 'poste-autres',
} as const

export const CHANTIER_LAYERS_YEARS = {
  '2024': 'chantier-2024',
  '2025': 'chantier-2025',
  '2026': 'chantier-2026',
  '2027': 'chantier-2027',
  '2028': 'chantier-2028',
  '2029': 'chantier-2029',
  '2030': 'chantier-2030-plus',
} as const

export const CHANTIER_LAYERS_PHASES = {
  'REA': 'chantier-phase-rea',
  'TVX': 'chantier-phase-tvx',
  'TVXP': 'chantier-phase-tvxp',
  'REA_TVXP': 'chantier-phase-rea-tvxp',
  'REA_TVX': 'chantier-phase-rea-tvx',
  'APO_REA': 'chantier-phase-apo-rea',
  'APO_TVX': 'chantier-phase-apo-tvx',
  'APO_REA_TVX': 'chantier-phase-apo-rea-tvx',
  'PRO_REA': 'chantier-phase-pro-rea',
} as const

export const SIGNAL_LAYERS_MNEMO = {
  signalProtection: [
    'Ct', 'CARRE', 'CARRE (CH)', 'CV', 'D', 'Ex', 'GA', 'R17', 'R18', 'S', 'S (CH)', 'SG HEURT',
  ],
  signalManoeuvre: ['LM', 'SLM'],
  signalIndicateurDirection: ['ID', 'TIDD'],
  signalDepartTrain: ['DD', 'SLD'],
  signalSortieGroupesVoiesConvergentes: ['CHEVRON', 'VOIE CONV', 'IDP'],
  signalTGV: ['CAB E', 'JAL MAN', 'REP TGV', 'CAB S', 'CAB E', 'CAB R'],
  signalTractionElectrique: [
    'BIMODE', 'BIMODE A', 'SECT', 'REV', 'BP DIS', 'BP EXE', 'CC EXE', 'BP FIN', 'CC FIN', 'FIN CAT',
  ],
  signalTableauPancarte: [
    'CHEVRON', 'FEUXVERTS', 'JAL ARRET', 'LGR', 'ARRET', 'ARRET A', 'ATC', 'CARRE A', 'DESTI', 'DIVERS', 'GARE', 'PN',
    'PN...', 'APPROETSA', 'ARRET VOY', 'SIFFLER', 'STOP', 'STOP A', 'MIBLAN VER', 'PAD', 'MV', 'APPROCHETS',
    'GABARIT', 'TUNNEL', 'LIMITETS', 'RLI', 'SAC', 'Aig M', 'Aig T', 'SIG A TRAM', 'B', 'DEPOT', 'TAB DIVERS',
    'FLECHE ACC', 'G', 'GIVRE', 'HEURT...', 'IMP', 'TLD', 'TECS', 'TSCS',
  ],
  signalArretAnnonce: ['A', 'A (CH)', 'A TRAM'],
  signalLimitationDeVitesse: ['L', 'R', 'Z', 'R30', 'RR30', 'REPER VIT', 'P', 'TIV A TRAM', 'TIV E TRAM', 'TIV D FIXE',
    'TIVD B FIX', 'TIVD C FIX', 'TIV D MOB', 'TIV R MOB', 'TIV PENDIS', 'TIV PENEXE', 'TIV PENREP', 'TIV R TRAM',
    'TBFVN TMP', 'TB TMP', 'P TMP', 'TIV D TMP', 'TIV R TMP',
  ],
} as const
