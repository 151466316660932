import { cloneElement, useRef, useState } from 'react'
import { useClickOutside } from 'utils'
import './DropMenu.scss'

interface Props {
  children: JSX.Element;
  actions: { label: string; onClick: () => void }[];
}

export default function DropMenu({ children, actions }: Props) {
  const menuWrapperRef = useRef<HTMLDivElement>(null)
  const [menuDisplayed, setMenuDisplayed] = useState(false)

  const closeMenu = () => setMenuDisplayed(false)
  const toggleMenu = () => setMenuDisplayed(!menuDisplayed)

  useClickOutside(menuWrapperRef, closeMenu)

  return (
    <div className="drop-menu" ref={menuWrapperRef}>
      {cloneElement(children, { onClick: e => { e.stopPropagation(); toggleMenu() } })}
      <ul className={menuDisplayed ? 'displayed' : ''}>
        {actions.map(action => (
          <li key={crypto.randomUUID()}>
            <a onClick={e => { e.stopPropagation(); action.onClick(); closeMenu() }} className="flex-center">
              {action.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
