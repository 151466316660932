/* eslint-disable react/no-array-index-key */
import './Pagination.scss'

type PaginationProps = {
  currentPage: number
  total: number
  onPageChange: (page: number) => void
}

export default function Pagination({ currentPage, total, onPageChange }: PaginationProps) {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < total) {
      onPageChange(currentPage + 1)
    }
  }

  const getPageNumbers = () => {
    const maxVisible = 6
    const pageNumbers = []

    if (total <= maxVisible) {
      for (let i = 1; i <= total; i += 1) {
        pageNumbers.push(i)
      }
    } else {
      const start = Math.max(1, currentPage - Math.floor(maxVisible / 2))
      const end = Math.min(total, start + maxVisible - 1)

      if (start > 1) {
        pageNumbers.push(1, '...')
      }

      for (let i = start; i <= end; i += 1) {
        pageNumbers.push(i)
      }

      if (end < total) {
        pageNumbers.push('...', total)
      }
    }

    return pageNumbers
  }

  const pageNumbers = getPageNumbers()

  return (
    <div className="pagination">
      {/* Previous Button */}
      <button
        type="button"
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className="pagination-button"
      >
        &lt;
      </button>

      {/* Page Number Links */}
      {pageNumbers.map((page, index) => (typeof page === 'number' ? (
        <button
          key={index}
          type="button"
          onClick={() => onPageChange(page)}
          className={`pagination-number ${
            page === currentPage ? 'active' : ''
          }`}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-ellipsis">
          {page}
        </span>
      )))}

      {/* Next Button */}
      <button
        type="button"
        onClick={handleNext}
        disabled={currentPage === total}
        className="pagination-button"
      >
        &gt;
      </button>
    </div>
  )
}
