import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'
import PointLayer from './generic/PointLayer'

export default function LocalisateurLayer({ view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.localisateur,
    view,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <PointLayer
      sourceId={CHARTIS_SOURCES_IDS.localisateur}
      layers={[
        MAPBOX_LAYER_IDS.localisateurCircle,
        MAPBOX_LAYER_IDS.localisateurHighlight,
        MAPBOX_LAYER_IDS.localisateurLabel,
      ]}
      color="#A51FFF"
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      textField={['format', 'Localisateur', '\n', ['get', 'libelle']]}
    />
  )
}
