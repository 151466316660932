import { MapEvent, MapRef } from '@vis.gl/react-maplibre'
import rectangleArrondi from 'assets/icons/rectangle_arrondi.png'
import stationPng from 'assets/icons/station.png'
import stationRedPng from 'assets/icons/station_red.png'
import jdzBluePng from 'assets/icons/jdz_blue.png'
import jdzRedPng from 'assets/icons/jdz_red.png'
import dotPng from 'assets/icons/dot.png'
import ihmLine from 'assets/icons/ihm_line.png'
import ihmLineRed from 'assets/icons/ihm_line_red.png'
import greenCircle from 'assets/icons/green_circle.png'
import redCircle from 'assets/icons/red_circle.png'
import yellowCircle from 'assets/icons/yellow_circle.png'
import orangeCircle from 'assets/icons/orange_circle.png'

const hexToRgb = (hex:string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

const generateColorImage = (color: string, image: ImageBitmap): ImageData | undefined => {
  const rgbColor = hexToRgb(color)
  if (!rgbColor) return undefined

  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  const ctx = canvas.getContext('2d')
  if (!ctx) return undefined
  ctx.drawImage(image, 0, 0)
  const imgData = ctx.getImageData(0, 0, image.width, image.height)
  const newImageData = new ImageData(imgData.width, imgData.height)
  for (let x = 0; x < imgData.width; x += 1) {
    for (let y = 0; y < imgData.height; y += 1) {
      const offset = (y * imgData.width + x) * 4
      newImageData.data[offset + 0] = rgbColor.r // red
      newImageData.data[offset + 1] = rgbColor.g // green
      newImageData.data[offset + 2] = rgbColor.b // blue
      newImageData.data[offset + 3] = imgData.data[offset + 3] // alpha
    }
  }
  return newImageData
}

const IMAGES = {
  station: stationPng,
  station_red: stationRedPng,
  jdz_blue: jdzBluePng,
  jdz_red: jdzRedPng,
  dot: dotPng,
  ihm_line: ihmLine,
  ihm_line_red: ihmLineRed,
  green_circle: greenCircle,
  red_circle: redCircle,
  yellow_circle: yellowCircle,
  orange_circle: orangeCircle,
}

export const loadMissingImage = async (
  map: MapRef,
  imageId: string,
) => {
  if (imageId.includes('rectanglearrondi-')) {
    const color = imageId.trim().split('-')?.[1]
    const image = await map.loadImage(rectangleArrondi)
    const generatedImage = generateColorImage(color, image.data as ImageBitmap)
    if (generatedImage && !map.hasImage(imageId)) {
      map.addImage(imageId, generatedImage, {
        stretchX: [[40, 88]], stretchY: [[31, 32]], pixelRatio: 3, content: [20, 10, 108, 54],
      })
    }
  }
}

export const loadImages = async (e: MapEvent) => {
  const currentMap = e.target
  if (!currentMap) return

  Object.keys(IMAGES).forEach(async imageId => {
    const image = await currentMap.loadImage(IMAGES[imageId])
    currentMap.addImage(imageId, image.data, { sdf: false })
  })
}
