/* eslint-disable camelcase */
import { Accordion, Loader } from 'components'
import { useEffect, useState } from 'react'
import { ObjectCount, Perimeter } from 'types'
import './InfosTab.scss'
import { countObjectsFromGeometry } from 'services'

type LineData = { [key: string]: {track_name: string, start_pk: string, end_pk:string}[]}

export default function InfosTab({ perimeter }: {perimeter: Perimeter}) {
  const [data, setData] = useState<LineData>({})
  const [loadingCount, setLoadingCount] = useState(false)
  const [count, setCount] = useState<ObjectCount>()

  useEffect(() => {
    const linesData: LineData = {}
    perimeter.properties.lvp_perimeter.reduce((acc, line) => {
      const { line_code, track_name, start_pk, end_pk } = line

      if (acc[line_code]) {
        acc[line_code].push({ track_name, start_pk, end_pk })
      } else {
        acc[line_code] = [{ track_name, start_pk, end_pk }]
      }
      return acc
    }, linesData)
    setData(linesData)
  }, [perimeter])

  useEffect(() => {
    if (perimeter.geometry.type === 'Polygon') {
      setLoadingCount(true)
      countObjectsFromGeometry(perimeter.geometry).then(r => {
        setCount(r)
        setLoadingCount(false)
      })
    }
  }, [perimeter.geometry])

  return (
    <div className="infos-tab">
      <h3>Infos</h3>
      <h4 className="color-blue">Description</h4>
      <p>{perimeter.properties.description || 'Aucune description renseignée'}</p>
      <h4 className="color-blue">Infos générales</h4>
      <div className="count">
        <div className="">
          <strong>Nombre d&apos;ADV : </strong>
          <span>{loadingCount ? <Loader variant="x-small" /> : count?.adv}</span>
        </div>
        <div className="">
          <strong>Nombre de protections : </strong>
          <span>{loadingCount ? <Loader variant="x-small" /> : count?.protect}</span>
        </div>
        <div className="">
          <strong>Nombre d&apos;avertissements : </strong>
          <span>{loadingCount ? <Loader variant="x-small" /> : count?.avertissement}</span>
        </div>
        <div className="">
          <strong>Total cumulé : </strong>
          <span>{loadingCount ? <Loader variant="x-small" /> : count?.total}</span>
        </div>
      </div>
      <h4 className="color-blue">Lignes / Voies</h4>
      <div className="lines">
        {Object.entries(data).map(([line, tracks]) => (
          <Accordion key={line} title={line} className="line-accordion" defaultExpanded={false}>
            {tracks.map(track => (
              <div key={crypto.randomUUID()} className="track-details">
                <span>{track.track_name}</span>
                <span>{`PK ${track.start_pk} à PK ${track.end_pk}`}</span>
              </div>
            ))}
          </Accordion>
        ))}
      </div>
    </div>
  )
}
