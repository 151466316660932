import { Layer, SymbolLayerSpecification } from '@vis.gl/react-maplibre'
import { hoverColor } from 'utils'

interface Props {
  sourceId: string
  layer: string
  hoveredObjectsIds: string[]
  opacity: number
  lineFilter: string
  visibility: boolean
  paint?: SymbolLayerSpecification['paint']
  layout?: SymbolLayerSpecification['layout']
}

/**
 * Generic layer displaying an icon and (eventually) a label
 * It handles hovered object text highlighting and opacity decrease when filtering on a line
 * Renders a single mapbox layer
 * @param {string} sourceId - The id of the source
 * @param {string} layer - The id of the layer
 * @param {string[]} hoveredObjectsIds - The ids of the hovered objects
 * @param {number} opacity - The opacity of the layer
 * @param {string} lineFilter - The line filter
 * @param {boolean} visibility - The visibility of the layer
 * @param {SymbolLayerSpecification['paint']} paint - The paint properties of the layer
 * @param {SymbolLayerSpecification['layout']} layout - The layout properties of the layer
 * @return {JSX.Element} The picto layer
*/

export default function PictoLayer({
  sourceId, layer, hoveredObjectsIds, opacity, lineFilter, visibility, paint, layout,
}: Props) {
  return (
    <Layer
      type="symbol"
      id={layer}
      source={sourceId}
      source-layer={sourceId}
      layout={{
        'text-font': ['Open Sans Regular'],
        'text-size': 13,
        'text-offset': [0, -2],
        'icon-allow-overlap': true,
        'icon-rotation-alignment': 'map',
        visibility: visibility ? 'visible' : 'none',
        ...layout,
      }}
      minzoom={12}
      paint={{
        'text-color': hoverColor('#000000', hoveredObjectsIds),
        'text-opacity': ['case',
          ['in', lineFilter, ['get', 'lrs_ligne']], 1,
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
          opacity],
        'icon-opacity': ['case',
          ['in', lineFilter, ['get', 'lrs_ligne']], 1,
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
          opacity],
        'text-halo-color': ['case',
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
          'transparent'],
        'text-halo-width': ['case',
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
          0.1],
        ...paint,
      }}
    />
  )
}
