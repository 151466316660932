import { Accordion, Pagination } from 'components'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { getObjectFa, ObjectFASignal } from 'services'
import { Collection } from 'types'

type Props = {
  collection: Collection
}

export default function ObjectFA({ collection }: Props) {
  const [objectFaPage, setObjectFaPage] = useState(1)
  const objectFa = ObjectFASignal.list.value

  useEffect(() => {
    getObjectFa(collection.indices_gep?.map(indice => indice.code_c6), objectFaPage)
  }, [objectFaPage])

  return (
    <>
      <Accordion title="Fiches FA" defaultExpanded>
        <table>
          <thead>
            <tr>
              <th>Clé</th>
              <th>
                Code
                {'\u00A0'}
                C6
              </th>
              <th>Statut</th>
              <th>Date de création</th>
              <th>Libellé</th>
            </tr>
          </thead>
          <tbody>
            {objectFa?.map(fiche => (
              <tr
                key={fiche.id}
                className="interactive"
                onClick={() => {
                  window.open(`https://${fiche.url}`, '_blank')
                }}
              >
                <td>{fiche.key}</td>
                <td>{fiche.code_c6}</td>
                <td>{fiche.statut}</td>
                <td>{moment(fiche.date_creation).format('DD/MM/YYYY')}</td>
                <td>{fiche.libelle}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={objectFaPage}
          total={Math.ceil(ObjectFASignal.count.value / 100)}
          onPageChange={setObjectFaPage}
        />
      </Accordion>
      <div className="separator" />
    </>
  )
}
