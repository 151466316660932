import { useCallback, useEffect, useState } from 'react'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import terms from 'assets/terms'
import {
  Button, ButtonStyle, ModalSignal, SelectInput, TextInput, ButtonSize,
  SelectCheckInput,
} from 'components'
import { Team, TeamForm, UserEntity, UserSearch } from 'types'
import { getEntities, getUsersForEntites, patchTeam, postTeam } from 'services/users'

import './CreateTeamModal.scss'

type Props = {
  team?: Team
}

export default function CreateTeamModal({ team }: Props) {
  const [entities, setEntities] = useState<UserEntity[]>([])
  const [users, setUsers] = useState<UserSearch[]>([])
  const [data, setData] = useState<TeamForm>({
    name: team?.nom || '',
    entites: team?.entites?.map(entity => entity.id) || [],
    leaders: (team?.users?.filter(user => user.type === 'leader') || []) as UserSearch[],
    members: (team?.users?.filter(user => user.type === 'member') || []) as UserSearch[],
  })
  const [loading, setLoading] = useState(false)
  const [showMore, setShowMore] = useState({ leaders: false, members: false })
  const requiredFields: (keyof TeamForm)[] = ['name', 'entites']

  useEffect(() => { getEntities().then(response => { setEntities(response) }) }, [])

  useEffect(() => {
    if (!data.entites?.length) return

    setLoading(true)
    getUsersForEntites(data.entites)
      .then(response => setUsers(response))
      .finally(() => setLoading(false))
  }, [data.entites])

  const handleSelectUser = (key: 'leaders' | 'members') => (user: UserSearch) => {
    setData(prev => ({ ...prev, [key]: [...(prev[key] || []), user] }))
  }

  const handleRemoveUser = (key: 'leaders' | 'members', userId: string) => () => {
    setData(prev => ({ ...prev, [key]: prev[key]?.filter(user => user.id !== userId) }))
  }

  const handleToggleShowMore = (key: 'leaders' | 'members') => () => {
    setShowMore(prev => ({ ...prev, [key]: !prev[key] }))
  }

  const handleChange = (key: keyof TeamForm) => (value: string) => {
    setData(prev => ({ ...prev, [key]: value }))
  }

  const handleChangeEntity = (value: string) => {
    if (data.entites.includes(value)) {
      setData(prev => ({ ...prev, entites: data.entites.filter(id => id !== value) }))
    } else {
      setData(prev => ({ ...prev, entites: [...data.entites, value] }))
    }
  }

  const handleClose = () => { ModalSignal.value = undefined }

  const handleValidate = async () => {
    const error = team
      ? await patchTeam(team?.id, data)
      : await postTeam(data)
    if (!error) handleClose()
  }

  const renderUserList = useCallback(
    (key: 'leaders' | 'members') => data[key]?.length > 0 && (
    <table className="users highlighted">
      <tbody>
        {data[key].slice(0, showMore[key] ? data[key].length : 2).map(user => (
          <tr key={user.id} className="result">
            <td className="cp">{user.username}</td>
            <td className="name">{`${user.firstName} ${user.lastName}`}</td>
            <td className="email">{user.email}</td>
            <td className="remove">
              <Button
                text=""
                icon={<DeleteIcon />}
                onClick={handleRemoveUser(key, user.id)}
                size={ButtonSize.xsmall}
                style={ButtonStyle.borderLess}
              />
            </td>
          </tr>
        ))}
        {data[key].length > 2 && (
        <tr className="show-more">
          <td colSpan={4}>
            <Button
              text={showMore[key] ? 'Voir moins' : 'Voir plus'}
              onClick={handleToggleShowMore(key)}
              style={ButtonStyle.borderLess}
            />
          </td>
        </tr>
        )}
      </tbody>
    </table>
    ),
    [data, showMore],
  )

  return (
    <div className="modal-team-create modal-content hide-scroll">
      <div className="header"><h2>{terms.Modals.Teams[team ? 'update' : 'create']}</h2></div>

      <TextInput
        label="Nom de l'équipe"
        required={requiredFields.includes('name')}
        onChange={handleChange('name')}
        bindedValue={data.name}
      />

      <SelectCheckInput
        label="Entité(s) associée(s)"
        options={entities.map(entity => ({
          value: entity.id,
          checked: data.entites.includes(entity.id),
          label: `${entity.nom} - ${entity.type}`,
        }))}
        placeholder="Sélectionner une entité"
        displayChip
        required={requiredFields.includes('entites')}
        onChange={handleChangeEntity}
      />

      <div className="search leaders">
        <SelectInput
          onChange={id => handleSelectUser('leaders')(users.find(user => user.id === id))}
          label={'Responsable(s) de l\'équipe'}
          placeholder="Sélectionner un utilisateur"
          shouldReset
          loading={loading}
          options={users
            .map(user => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))
            .filter(user => !data.leaders?.some(leader => leader.id === user.value))}
        />
        {renderUserList('leaders')}
      </div>

      <div className="search members">
        <SelectInput
          onChange={id => handleSelectUser('members')(users.find(user => user.id === id))}
          label={'Membre(s) de l\'équipe'}
          placeholder="Sélectionner un utilisateur"
          shouldReset
          loading={loading}
          options={users
            .map(user => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))
            .filter(user => !data.members?.some(member => member.id === user.value))}
        />
        {renderUserList('members')}
      </div>

      {!!team && !team?.entites.every(e => data.entites.includes(e.id)) && (
        <div className="warning">
          {terms.Modals.Teams.warningMissingEntity}
        </div>
      )}

      <div className="actions">
        <Button
          text={terms.Common.cancel}
          onClick={handleClose}
          style={ButtonStyle.light}
        />
        <Button
          text={terms.Common.save}
          onClick={handleValidate}
          isAsync
          disabled={requiredFields.some(field => !data[field])}
        />
      </div>
    </div>
  )
}
