import { Fragment, ReactElement, useEffect } from 'react'
import { signal } from '@preact/signals-react'

import './ModalWrapper.scss'

/**
 * Modal types
*/
export enum ModalType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  LOADING = 'loading',
}

/**
 * Signal used to communicate with modals
 * which are responsible for displaying/hiding the modal
*/
export const ModalSignal = signal<React.ReactNode | React.ReactNode[]>([])

const resetSignal = () => {
  ModalSignal.value = undefined
}

export const popModal = () => {
  if (!Array.isArray(ModalSignal.value) || ModalSignal.value.length === 1) {
    resetSignal()
    return
  }
  ModalSignal.value = ModalSignal.value.slice(0, -1)
}

export const pushModal = (modal: React.ReactNode) => {
  ModalSignal.value = Array.isArray(ModalSignal.value) ? [...ModalSignal.value, modal] : [ModalSignal.value, modal]
}

export default function ModalWrapper(): ReactElement {
  useEffect(() => () => {
    // Reset signal when unmounting modal component
    resetSignal()
  }, [])

  if (Array.isArray(ModalSignal.value)) {
    return (
      <div className={`modal-wrapper ${ModalSignal.value ? ' displayed' : ''}`}>
        {ModalSignal.value.map(modal => (
          <Fragment key={crypto.randomUUID()}>
            <div className="background" onClick={popModal} />
            <div className="modal" key={crypto.randomUUID()}>
              {modal}
            </div>
          </Fragment>
        )).filter(Boolean)}
      </div>
    )
  }

  return (
    <div className={`modal-wrapper ${ModalSignal.value ? ' displayed' : ''}`}>
      <div className="background" onClick={resetSignal} />
      <div className="modal">
        {ModalSignal.value}
      </div>
    </div>
  )
}
