import { Layer } from '@vis.gl/react-maplibre'
import { CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { hoverColor, useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'

export default function IHMLayer({ view, visibility }:LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.ihm,
    view,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()

  return (
    <>
      <Layer
        id={MAPBOX_LAYER_IDS.ihmOutline}
        type="line"
        paint={{
          'line-width': ['step', ['zoom'], 2, 13, 4],
          'line-pattern': [
            'case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'ihm_line_red',
            'ihm_line',
          ],
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
          'line-cap': 'square',
          'line-join': 'miter',
        }}
        minzoom={11}
        source-layer={CHARTIS_SOURCES_IDS.ihm}
        source={CHARTIS_SOURCES_IDS.ihm}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.ihmFill}
        type="fill"
        paint={{
          'fill-color': hoverColor('transparent', hoveredObjectsIds),
          'fill-opacity': 0.2,
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={11}
        source-layer={CHARTIS_SOURCES_IDS.ihm}
        source={CHARTIS_SOURCES_IDS.ihm}
      />
    </>
  )
}
