import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import terms from 'assets/terms'
import {
  Button, ButtonStyle, ModalSignal, SelectInput, TextInput, Loader,
  SelectCheckInput,
} from 'components'
import { UserCerbere, UserForm, UserSearch } from 'types'
import {
  getCerbereUsers, getEntities, getEntity, patchUser, postUser, useRoles,
} from 'services'

import './CreateUserModal.scss'

type Props = {
  user?: UserSearch
}

export default function CreateUserModal({ user }: Props) {
  const { isMOE } = useRoles()
  const [entities, setEntities] = useState([])
  const [sections, setSections] = useState([])
  const [teams, setTeams] = useState([])
  const [results, setResults] = useState<UserCerbere[]>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<UserForm>({
    ...user,
    cp: user?.username || '',
    role: user?.role || 'AGENT',
    entite: user?.entite?.id || '',
    sections: user?.sections?.map(section => section.id) || [],
    equipes: user?.equipes?.map(equipe => equipe.id) || [],
  })
  const requiredFields: (keyof UserForm)[] = ['cp', 'email', 'firstName', 'lastName', 'role']

  useEffect(() => {
    getEntities().then(response => {
      setEntities(response.map(entity => ({ value: entity.id, label: `${entity.nom} - ${entity.type}` })))
      if (response.length > 0) setData(prev => ({ ...prev, entites: response[0].id }))
    })
  }, [])

  useEffect(() => {
    if (!data.entite) return
    getEntity(data.entite).then(response => {
      setSections(response.sections)
      setTeams(response.equipes)
    })
  }, [data.entite])

  const handleSearch = useCallback(debounce(async (value: string) => {
    if (value?.length < 3) {
      return
    }

    setLoading(true)
    setResults(await getCerbereUsers(value))
    setLoading(false)
  }, 500), [])

  const handleSelect = (cerb: UserCerbere) => (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation()
    setData(prev => ({
      ...prev,
      cerbere_id: cerb.id,
      cp: cerb.username,
      email: cerb.email,
      firstName: cerb.firstName,
      lastName: cerb.lastName,
    }))
    setResults([])
  }

  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleValidate = async () => {
    const sanitizedData = {
      ...data,
      equipes: data.equipes?.filter(equipe => teams.some(team => team.id === equipe)),
      sections: data.sections?.filter(section => sections.some(sec => sec.id === section)),
    }

    const error = user
      ? await patchUser(user, sanitizedData)
      : await postUser(sanitizedData)
    if (!error) handleClose()
  }

  const handleChange = (key: keyof UserForm, isArray?: boolean) => (value: string) => {
    setData(prev => {
      if (isArray) {
        const prevValue = (prev[key] || []) as string[]
        return {
          ...prev,
          [key]: prevValue.includes(value) ? prevValue.filter(v => v !== value) : [...prevValue, value],
        }
      }

      return { ...prev, [key]: value }
    })
  }

  return (
    <div className="modal-user-create modal-content">
      <div className="header">
        <h2>{terms.Modals.Users[user ? 'update' : 'create']}</h2>
      </div>
      <div className="search">
        {!user && (
          <TextInput
            onChange={handleSearch}
            label="Rechercher un utilisateur"
            placeholder="Entrer un nom ou CP"
            RightIcon={loading ? <Loader variant="x-small" /> : null}
            LeftIcon={<SearchIcon />}
          />
        )}
        {results?.length > 0 && (
          <div className="results hide-scroll">
            <table>
              {results?.map(userCeerbere => (
                <tr key={userCeerbere.id} className="result" onClick={handleSelect(userCeerbere)}>
                  <td className="cp">{userCeerbere.username}</td>
                  <td className="name">{`${userCeerbere.firstName} ${userCeerbere.lastName}`}</td>
                  <td className="email">{userCeerbere.email}</td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
      <div className="flex-center groups">
        <TextInput
          label="Nom"
          bindedValue={data?.lastName}
          disabled
          required={requiredFields.includes('lastName')}
        />
        <TextInput
          label="Prénom"
          bindedValue={data?.firstName}
          disabled
          required={requiredFields.includes('firstName')}
        />
      </div>
      <div className="flex-center groups">
        <TextInput
          label="CP"
          bindedValue={data?.cp}
          disabled
          required={requiredFields.includes('cp')}
        />
        <TextInput
          label="Email"
          bindedValue={data?.email}
          disabled
          required={requiredFields.includes('email')}
        />
      </div>
      <div className="flex-center groups">
        <SelectInput
          label="Rôle"
          options={[
            { value: 'AGENT', label: 'Agent' },
            { value: 'OBSERVATEUR', label: 'Observateur' },
            { value: 'VALIDATEUR', label: 'Validateur' },
            { value: 'MOE', label: 'MOE' },
          ]}
          defaultValue={user?.role || 'AGENT'}
          onChange={handleChange('role')}
          required={requiredFields.includes('role')}
          disabled={!isMOE}
        />
        <SelectInput
          label="Entité de rattachement"
          placeholder="Sélectionner une entité"
          options={entities}
          defaultValue={user?.entite?.id || 'placeholder'}
          onChange={handleChange('entite')}
          disabled={!isMOE}
        />
      </div>
      <div className="groups">
        <SelectCheckInput
          label="Sections de rattachement"
          placeholder={`Sélectionner une ${data.entite ? 'section' : 'entité'}`}
          options={sections.map(section => ({
            value: section.id,
            label: section.nom,
            checked: data?.sections?.some(s => s === section.id) || false,
          }))}
          displayChip
          disabled={!data.entite}
          onChange={handleChange('sections', true)}
        />
        <SelectCheckInput
          label="Équipes associées"
          placeholder={`Sélectionner une ${data.entite ? 'équipe' : 'entité'}`}
          options={teams.map(team => ({
            value: team.id,
            label: team.nom,
            checked: data?.equipes?.some(e => e === team.id) || false,
          }))}
          displayChip
          disabled={!data.entite}
          onChange={handleChange('equipes', true)}
        />
      </div>
      <div className="actions">
        <Button text={terms.Common.cancel} onClick={handleClose} style={ButtonStyle.light} />
        <Button
          text={terms.Common.save}
          onClick={handleValidate}
          isAsync
          disabled={requiredFields.some(field => !data?.[field])}
        />
      </div>
    </div>
  )
}
