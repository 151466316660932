import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'
import PointLayer from './generic/PointLayer'

export default function ADVLayer({ view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.adv,
    view,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal
  return (
    <PointLayer
      sourceId={CHARTIS_SOURCES_IDS.adv}
      layers={[MAPBOX_LAYER_IDS.advCircle, MAPBOX_LAYER_IDS.advHighlight, MAPBOX_LAYER_IDS.advLabel]}
      color="#FF9313"
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      textField={['format', 'ADV', '\n', ['get', 'libelle']]}
    />
  )
}
