import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'
import LineLayer from './generic/LineLayer'

export default function CDVLayer({ view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.cdv,
    view,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <LineLayer
      sourceId={CHARTIS_SOURCES_IDS.cdv}
      layers={[MAPBOX_LAYER_IDS.cdvLine, MAPBOX_LAYER_IDS.cdvLabel]}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      lineColor="transparent"
      color="#038f03"
    />
  )
}
