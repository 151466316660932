import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'

/**
 * Get the error message from an error object
 * @param error - The error object
 * @returns string
 */
export const getErrorMessage = (error: {status: number}) => {
  switch (error.status) {
    case 400:
      return terms.Error.httpStatus.badRequest
    case 401:
      return terms.Error.httpStatus.forbidden
    case 403:
      return terms.Error.httpStatus.forbidden
    case 404:
      return terms.Error.httpStatus.notFound
    case 502:
      return terms.Error.httpStatus.badGateway
    default:
      return terms.Error.httpStatus.unknownError
  }
}

/**
 * Catch errors from an async function and display a toast error message
 *
 * @param fn - The function to catch errors from
 * @returns The result of the function or an error object
 */
export const catchable = async<T> (
  fn: () => Promise<T>,
  displayToast?: boolean,
  errorMessage?: string,
): Promise<[string, T | null]> => {
  try {
    return [null, await fn()]
  } catch (e) {
    const error = errorMessage || e?.response?.data?.detail || e?.message?.toString() || getErrorMessage(e)
    if (displayToast) {
      ToastSignal.value = {
        message: error,
        severity: ToastLevel.ERROR,
      }
    }

    return [error, null]
  }
}
