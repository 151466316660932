import terms from 'assets/terms'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import {
  Button, ButtonSize, ButtonStyle, ConfirmModal, CreateSectionModal, DropMenu, Loader, ModalSignal,
  SectionMembersModal,
} from 'components'
import { deleteSection, getSections, SectionSignal } from 'services/users'
import { useMountEffect } from 'utils'
import { Section } from 'types'

export default function Sections() {
  useMountEffect(getSections)
  const loading = SectionSignal.loading.value
  const list = SectionSignal.list.value

  const closeModal = () => { ModalSignal.value = undefined }

  const handleUpdate = (section: Section) => () => {
    ModalSignal.value = <CreateSectionModal section={section} />
  }

  const handleDeleteSection = (section: Section) => () => {
    ModalSignal.value = (
      <ConfirmModal
        title={`Supprimer la section "${section.nom}" ?`}
        handleClose={closeModal}
        handleValidate={async () => {
          await deleteSection(section.id)
          closeModal()
        }}
      />
    )
  }

  const handleDisplayMembers = (section: Section) => () => {
    ModalSignal.value = [<SectionMembersModal sectionId={section.id} key={0} />]
  }

  const getDropMenuActions = (section: Section) => [
    { label: 'Supprimer la section', onClick: handleDeleteSection(section) },
    { label: 'Modifier la section', onClick: handleUpdate(section) },
  ]

  return (
    <div className="teams-panel hide-scroll">
      <table className="teams">
        <thead>
          <tr>
            <th><div className="entity">{terms.Panels.TeamsManager.sections.entity}</div></th>
            <th><div className="name">{terms.Panels.TeamsManager.sections.name}</div></th>
            <th><div className="actions">{terms.Panels.TeamsManager.sections.action}</div></th>
          </tr>
        </thead>
        <tbody>
          {list?.map(section => (
            <tr key={section.id} onClick={handleDisplayMembers(section)}>
              <td className="name">{section.entite.nom}</td>
              <td className="name">{section.nom}</td>
              <td>
                <DropMenu actions={getDropMenuActions(section)}>
                  <Button
                    text=""
                    style={ButtonStyle.borderLess}
                    size={ButtonSize.xsmall}
                    icon={<SettingsIcon />}
                  />
                </DropMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <Loader />}
    </div>
  )
}
