import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'
import LineLayer from './generic/LineLayer'

/*
  "type_ligne_id","type_ligne_id_libelle"
  "467e6d22-6665-11e3-afff-01f464e0362d","Voie-mère d'embranchement"
  "467e6e52-6665-11e3-afff-01f464e0362d","Voie de desserte de voies ferrées de port"
  "467e67fa-6665-11e3-afff-01f464e0362d","Ligne proprement dite"
  "467e6b4c-6665-11e3-afff-01f464e0362d","Raccordement"
*/

export default function RacLayer({ view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.rac,
    view,
    { ...perimeterParam, type_ligne_id: '467e6b4c-6665-11e3-afff-01f464e0362d' },
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <LineLayer
      sourceId={CHARTIS_SOURCES_IDS.rac}
      layers={[MAPBOX_LAYER_IDS.racLine, MAPBOX_LAYER_IDS.racLabel]}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      lineColor="#9d15cf"
      color="#9d15cf"
    />
  )
}
