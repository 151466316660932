import { useEffect, useState } from 'react'
import terms from 'assets/terms'
import {
  Button, ButtonStyle, ModalSignal, SelectInput, TextInput,
} from 'components'
import { Section, SectionForm, UserEntity } from 'types'
import { getEntities, patchSection, postSection } from 'services/users'

import './CreateSectionModal.scss'

type Props = {
  section?: Section
}

export default function CreateSectionModal({ section }: Props) {
  const [entities, setEntities] = useState<UserEntity[]>([])
  const [data, setData] = useState<SectionForm>({
    name: section?.nom || '',
    entite: section?.entite?.id || '',
  })
  const requiredFields: (keyof SectionForm)[] = ['name', 'entite']

  useEffect(() => { getEntities().then(response => { setEntities(response) }) }, [])

  const handleChange = (key: keyof SectionForm) => (value: string) => {
    setData(prev => ({ ...prev, [key]: value }))
  }

  const handleClose = () => { ModalSignal.value = undefined }

  const handleValidate = async () => {
    const error = section
      ? await patchSection(section, data)
      : await postSection(data)
    if (!error) handleClose()
  }

  return (
    <div className="modal-section-create modal-content hide-scroll">
      <div className="header"><h2>{terms.Modals.Sections[section ? 'update' : 'create']}</h2></div>

      <TextInput
        label="Nom de la section"
        required={requiredFields.includes('name')}
        onChange={handleChange('name')}
        bindedValue={data.name}
      />

      <SelectInput
        label="Entité(s) associée(s)"
        options={entities.map(entity => ({
          value: entity.id,
          label: `${entity.nom} - ${entity.type}`,
        }))}
        defaultValue={section?.entite?.id}
        disabled={!!section}
        placeholder="Sélectionner une entité"
        required={requiredFields.includes('entite')}
        onChange={handleChange('entite')}
      />

      <div className="actions">
        <Button
          text={terms.Common.cancel}
          onClick={handleClose}
          style={ButtonStyle.light}
        />
        <Button
          text={terms.Common.save}
          onClick={handleValidate}
          isAsync
          disabled={requiredFields.some(field => !data[field])}
        />
      </div>
    </div>
  )
}
