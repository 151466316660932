/* eslint-disable camelcase */
import MAIN_API from 'config/config'
import { ErrorEvent, useMap } from '@vis.gl/react-maplibre'
import { useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { MapURLParams } from 'types'
import { isLayerSelected, MapDataSignal, PerimeterSignals } from 'services'
import { ExpressionSpecification, FilterSpecification, VectorTileSource } from 'maplibre-gl'

const isTileserverResource = (type: string) => (type === 'Source' || type === 'Tile')

const replaceUrl = (url: string): string => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

export const transformRequest = (url: string | undefined, resourceType: string | undefined) => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType as string))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}

const errorStatuses = [
  'Failed to fetch',
  'signal is aborted without reason',
  'The user aborted a request.',
]
export const filterError = (error: ErrorEvent) => {
  if (errorStatuses.includes((error.error as unknown as { statusText: string})?.statusText)) return
  if (errorStatuses.includes(error.error.message)) return
  // eslint-disable-next-line no-console
  console.error(error.error)
}

export const useSource = (layer: string, view: string, urlParams?: MapURLParams, altSourceId?: string) => {
  const [params, setParams] = useState<MapURLParams>(urlParams || {})
  const maps = useMap()
  const sourceId = altSourceId || layer

  useEffect(() => {
    if (urlParams && !isEqual(urlParams, params)) {
      setParams(urlParams)
    }
  }, [urlParams])

  useEffect(() => {
    const url = `${MAIN_API.proxy}/chartis/v2/layer/${layer}/mvt/${view}/?${new URLSearchParams(urlParams)}`
    const map = maps.current?.getMap()

    // Create source when the style is loaded
    const createSource = () => {
      // eslint-disable-next-line no-underscore-dangle
      if (map.style && map.style._loaded && !map.getSource(sourceId)) {
        map.off('styledata', createSource)
        map.addSource(sourceId, { type: 'vector', url })
      }
    }
    if (map) {
      const source = map.getSource<VectorTileSource>(sourceId)
      if (source) {
        source.setUrl(url)
      } else {
        // eslint-disable-next-line no-underscore-dangle, no-lonely-if
        if (map.style && map.style._loaded) {
          map.addSource(sourceId, { type: 'vector', url })
        // Sometimes the style is not loaded yet
        } else {
          map.on('styledata', createSource)
        }
      }
    }
    return () => {
      if (map) {
        map.off('styledata', createSource)
      }
    }
  }, [params])
}

export const useActivePerimeterParams = (paramKey: string) => {
  const params = useMemo(() => {
    if (PerimeterSignals.activePerimeter.value && paramKey && !PerimeterSignals.patchingPerimeter.value) {
      return {
        [paramKey]: JSON.stringify(PerimeterSignals.activePerimeter.value.geometry),
      }
    }
    return {}
  }, [PerimeterSignals.activePerimeter.value?.id, paramKey, PerimeterSignals.patchingPerimeter.value])

  return params
}

export const useHoveredObjectsIds = (idField = 'id') => {
  const [hoveredObjectsIds, setHoveredObjectsIds] = useState<string[]>([])
  useEffect(() => {
    setHoveredObjectsIds([
      ...MapDataSignal.hoveredObjects.value?.map(o => o.properties?.[idField]) || [],
      MapDataSignal.targetedObject.value?.properties?.[idField],
    ].filter(Boolean))
  }, [MapDataSignal.hoveredObjects.value, MapDataSignal.targetedObject.value])
  return hoveredObjectsIds
}

export const getCollectionStatus = () => {
  const visibility = [
    'collection-status-study', 'collection-status-waiting', 'collection-status-not-started',
    'collection-status-blocked', 'collection-status-done',
  ].some(layer => isLayerSelected(layer))

  const count: ExpressionSpecification = [
    '+',
    ['case',
      ['boolean', isLayerSelected('collection-status-study')],
      ['coalesce', ['get', 'nombre_etude_en_cours'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerSelected('collection-status-waiting')],
      ['coalesce', ['get', 'nombre_en_attente_rc'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerSelected('collection-status-not-started')],
      ['coalesce', ['get', 'nombre_dc_non_commence'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerSelected('collection-status-blocked')],
      ['coalesce', ['get', 'nombre_dc_bloque'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerSelected('collection-status-done')],
      ['coalesce', ['get', 'nombre_dc_non_termine'], 0], 0,
    ],
  ]

  const filter: FilterSpecification = [
    'any',
    ['!=', ['get', 'nombre_etude_en_cours'], null],
    ['!=', ['get', 'nombre_dc_bloque'], null],
    ['!=', ['get', 'nombre_dc_non_commence'], null],
    ['!=', ['get', 'nombre_en_attente_rc'], null],
    ['!=', ['get', 'nombre_dc_non_termine'], null],
  ]

  return { visibility, count, filter }
}

export const hoverColor = (color: string, ids: string[]): ExpressionSpecification => (['case',
  ['in', ['get', 'id'], ['literal', ids]], '#cc0000',
  color,
])
