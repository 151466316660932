import { Layer } from '@vis.gl/react-maplibre'
import { DataDrivenPropertyValueSpecification } from 'maplibre-gl'
import { hoverColor } from 'utils'

interface Props {
  sourceId: string
  layers: [string, string]
  hoveredObjectsIds: string[]
  opacity: number
  lineFilter: string
  visibility: boolean
  color: string
  strokeColor: string
  textField: DataDrivenPropertyValueSpecification<string>
}

/**
  * Generic layer displaying a circle at high zoom levels and a text fitting rectangle at low zoom levels
  * It handles hovered object text highlighting and opacity decrease when filtering on a line
  * Renders two mapbox layers: one for the rectangle and one for the circle
  * @param {string} sourceId - The id of the source
  * @param {string[]} layers - The ids of the layers [rectangle, point]
  * @param {string} color - The color of the rectangle
  * @param {string[]} hoveredObjectsIds - The ids of the hovered objects
  * @param {number} opacity - The opacity of the layer
  * @param {string} lineFilter - The line filter
  * @param {boolean} visibility - The visibility of the layer
  * @param {DataDrivenPropertyValueSpecification<string>} textField - The text field
  * @return {JSX.Element} The rectangle layer
*/

export default function RectangleLayer({
  sourceId, layers, hoveredObjectsIds, opacity, lineFilter, visibility, color, strokeColor, textField,
}: Props) {
  const [rectangleLayer, pointLayer] = layers

  return (
    <>
      <Layer
        id={rectangleLayer}
        type="symbol"
        source={sourceId}
        source-layer={sourceId}
        minzoom={12}
        layout={{
          'icon-image': `rectanglearrondi-${color}`,
          'icon-text-fit': 'both',
          'icon-allow-overlap': false,
          'text-font': ['Roboto Condensed'],
          'text-size': 12,
          'text-field': textField,
          'text-max-width': 1000,
          'icon-text-fit-padding': [5, 10, 10, 10],
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          'text-color': '#FFFFFF',
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
        }}
      />
      <Layer
        id={pointLayer}
        type="circle"
        source={sourceId}
        source-layer={sourceId}
        maxzoom={12}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          'circle-color': hoverColor(color, hoveredObjectsIds),
          'circle-stroke-color': strokeColor,
          'circle-stroke-width': 1,
          'circle-radius': 5,
          'circle-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
          'circle-stroke-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
        }}
      />
    </>
  )
}
