import { useMap } from '@vis.gl/react-maplibre'
import { Feature } from 'geojson'
import { useState, MouseEvent } from 'react'
import { Accordion, Button, ButtonSize, ButtonStyle } from 'components'
import { CHARTIS_SOURCES_IDS, handleLocateObject } from 'services'
import { Collection, InfoIs } from 'types'
import { catchable } from 'utils'
import { get } from '@osrdata/app_core/dist/requests'
import { PanelSignal } from '../PanelsManager'
import InfoSinglePanel from '../info/InfoSinglePanel'
import CollectionPanel from './CollectionPanel'

type Props = {
  collection: Collection
  onBack: () => void
}

export default function IS({ collection, onBack }: Props) {
  const maps = useMap()
  const [loading, setLoading] = useState(false)

  const getIsLayer = (is: InfoIs) => {
    if (is.type === 'POSTE') {
      return CHARTIS_SOURCES_IDS.poste
    }

    return CHARTIS_SOURCES_IDS.bal
  }

  const handleLocate = (id: string, layer: string) => async (e: MouseEvent) => {
    e.stopPropagation()
    setLoading(true)
    await handleLocateObject(id, layer, maps)
    setLoading(false)
  }

  const handleClickIs = (is: InfoIs) => async (e: MouseEvent) => {
    handleLocate(is.gaia_id, getIsLayer(is))(e)
    if (is.type === 'POSTE') {
      const [, feature] = await catchable(() => get<Feature>(
        `/chartis/v2/layer/${CHARTIS_SOURCES_IDS.poste}/geojson_feature/full_rgi_track_geo/`,
        { id: is.gaia_id },
      ))
      PanelSignal.value = (
        <InfoSinglePanel
          feature={{ ...feature, source: CHARTIS_SOURCES_IDS.poste }}
          onBack={() => {
            PanelSignal.value = <CollectionPanel collection={collection} onBack={onBack} />
          }}
        />
      )
    }
  }

  return (
    <>
      <Accordion title="IS de la collection" defaultExpanded>
        {collection.infos_is?.map((is, index) => (
          <>
            <div key={is.id} className="is-item">
              <p>
                <b>ID Ouranos : </b>
                {is.id}
              </p>
              <p>
                <b>Nom de l’IS : </b>
                <span>{is.libelle_long}</span>
              </p>
              <p>
                <b>Type : </b>
                {is.type}
              </p>
              <p>
                <b>Technologie : </b>
                {is.poste_technologie_libelle || 'N/C'}
              </p>
              <p>
                <b>Ferrolocalisation : </b>
                {`${is.ligne_id} - ${is.localisation}`}
              </p>
              <div className="small-separator" />
              <p>
                <b>Gaia : </b>
                {is.gaia_match ? 'Oui' : 'Non'}
              </p>
              {is.gaia_match && (
              <p>
                <b>Libellé Gaia : </b>
                <Button
                  style={ButtonStyle.borderLess}
                  text={is.poste_libelle_long || 'N/C'}
                  size={ButtonSize.medium}
                  inline
                  onClick={handleClickIs(is)}
                  disabled={!is.poste_libelle_long || loading}
                />
              </p>
              )}
              {is.gaia_match && (
              <p>
                <b>ID Gaia : </b>
                {is.gaia_id || 'N/C'}
              </p>
              )}
            </div>
            {index < collection.infos_is.length - 1 && <div className="separator" />}
          </>
        ))}
      </Accordion>

      <div className="separator" />
    </>
  )
}
