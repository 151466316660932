// import { FeatureOf, Polygon } from '@nebula.gl/edit-modes'
import { useMap } from '@vis.gl/react-maplibre'
import { useState } from 'react'
import { Feature } from 'geojson'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import terms from 'assets/terms'
import { Button, ButtonSize, ButtonStyle } from 'components'
import { handleCreatePerimeter, PerimeterSignals, updatePerimeterViewport } from 'services'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import PerimeterInfoPanel from 'pages/home/panels/perimeterInfo/PerimeterInfoPanel'

import PerimeterFields from './PerimeterFields'
import { ModalSignal } from '../ModalWrapper'
import './SavePerimeterModal.scss'

interface Props {
  perimeterGeometry: Feature[]
}

export default function SavePerimeterModal({ perimeterGeometry }: Props) {
  const navigate = useNavigate()
  const maps = useMap()
  const [params] = useSearchParams()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const handleSave = async () => {
    const [, newPerimeter] = await handleCreatePerimeter(name, description, perimeterGeometry[0].geometry)
    PerimeterSignals.activePerimeter.value = newPerimeter
    updatePerimeterViewport(newPerimeter, maps)
    navigate(`/perimetre/${newPerimeter.id}/?${params}`)
    PanelSignal.value = (
      <PerimeterInfoPanel perimeter={newPerimeter} />
    )
  }
  const handleClose = () => { ModalSignal.value = undefined }

  return (
    <div className="save-perimeter-modal">
      <div className="info flex-column-center">
        <h3>{terms.Modals.SavePerimeter.title}</h3>
        <span>{terms.Modals.SavePerimeter.info}</span>
      </div>
      <PerimeterFields name={name} setName={setName} description={description} setDescription={setDescription} />
      <div className="flex-center actions">
        <Button
          size={ButtonSize.medium}
          style={ButtonStyle.light}
          text={terms.Common.abort}
          onClick={handleClose}
          icon={<CloseIcon />}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.save}
          onClick={handleSave}
          icon={<SaveIcon />}
          disabled={!name}
          isAsync
        />
      </div>
    </div>
  )
}
