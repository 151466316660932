import { Accordion } from 'components'
import { Collection } from 'types'

type Props = {
  collection: Collection
}

export default function ObjectFMR({ collection }: Props) {
  return (
    <>
      <Accordion title="Fiches FMR et FDMDE" defaultExpanded>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Clé</th>
              <th>Indice</th>
              <th>Statut</th>
              <th>Résumé</th>
            </tr>
          </thead>
          <tbody>
            {collection.fiches?.map(fiche => (
              <tr
                key={fiche.id}
                className="interactive"
                onClick={() => {
                  window.open(`https://${fiche.url}`, '_blank')
                }}
              >
                <td>{fiche.type}</td>
                <td>{fiche.key}</td>
                <td>{fiche.indice}</td>
                <td>{fiche.statut}</td>
                <td>{fiche.libelle}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
      <div className="separator" />
    </>
  )
}
