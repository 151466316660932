import { Layer } from '@vis.gl/react-maplibre'
import { CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { hoverColor, useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'

const sourceLayer = `label-${CHARTIS_SOURCES_IDS.ihm}`

export default function IHMLabelLayer({ view, visibility }:LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.ihm,
    view,
    perimeterParam,
    sourceLayer,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()

  return (
    <Layer
      id={MAPBOX_LAYER_IDS.ihmLabel}
      type="symbol"
      paint={{ 'text-color': '#fff' }}
      layout={{
        'icon-image': ['concat', 'rectanglearrondi-', hoverColor('#0066FF', hoveredObjectsIds)],
        'icon-text-fit': 'both',
        'icon-allow-overlap': false,
        'text-font': ['Open Sans Bold'],
        'text-allow-overlap': false,
        'text-size': 12,
        'text-justify': 'left',
        'text-field': ['get', 'libelle'],
        'text-max-width': 1000,
        visibility: visibility ? 'visible' : 'none',
      }}
      source-layer={CHARTIS_SOURCES_IDS.ihm}
      source={sourceLayer}
      minzoom={11}
    />
  )
}
