import { signal } from '@preact/signals-react'
import { ArrayFormat, deleteRequest, get, patch, post } from '@osrdata/app_core/dist/requests'
import { ToastLevel, ToastSignal } from 'components'
import {
  Member, Section, SectionForm, Team, TeamForm, UserEntity, UserForm, UserSearch,
} from 'types'
import { catchable } from 'utils'

export const TeamSignal = {
  list: signal<Team[]>([]),
  loading: signal(false),
}

export const UserSignal = {
  list: signal<UserSearch[]>([]),
  loading: signal(false),
}

export const SectionSignal = {
  list: signal<Section[]>([]),
  loading: signal(false),
}

export const getUsers = async () => {
  UserSignal.loading.value = true

  const [error, response] = await catchable(() => get<UserSearch[]>('/dexcarto/users/cerbere-users/'))

  if (!error && response) {
    UserSignal.list.value = response.filter(u => u.firstName && u.lastName).map(user => ({
      ...user,
      id: user.cerbere_id,
    }))
  }

  UserSignal.loading.value = false
}

export const getEntities = async () => {
  const [error, response] = await catchable(
    () => get<UserEntity[]>('/dexcarto/habilitation/entites/'),
  )

  if (error || !response) {
    return []
  }

  return response
}

export const getUsersForEntites = async (ids: string[]) => {
  const [error, response] = await catchable(() => get<UserSearch[]>(
    '/dexcarto/users/search',
    { entites: ids },
    ArrayFormat.repeat,
  ))

  if (error || !response) {
    return []
  }

  return response.filter(user => !!user.username).map(user => ({
    ...user,
    id: user.cerbere_id,
  }))
}

export const getEntity = async (id: string) => {
  const [error, response] = await catchable(
    () => get<UserEntity>(`/dexcarto/habilitation/entites/${id}/`),
  )

  if (error || !response) {
    return null
  }

  return response
}

export const getTeams = async () => {
  TeamSignal.loading.value = true
  const [error, response] = await catchable(() => get<Team[]>('/dexcarto/habilitation/equipes/'))

  if (error) {
    return []
  }

  const [, cerbereResponse] = await catchable(
    () => post<UserSearch[]>(
      '/cerbere/users/simple_with_email/',
      response.flatMap(team => team.users.map(user => user.user)),
    ),
  )

  const responseWithUsers = response.map(team => ({
    ...team,
    users: team.users.map(user => ({
      ...user,
      ...cerbereResponse?.find(cerbereUser => cerbereUser.id === user.user),
    })),
  }))

  TeamSignal.list.value = responseWithUsers
  TeamSignal.loading.value = false

  return responseWithUsers
}

export const postTeam = async (data: TeamForm) => {
  const form = {
    nom: data.name,
    entites: data.entites,
    users: data.members?.length > 0 || data.leaders?.length > 0 ? [
      ...(data.leaders ? data.leaders.map(user => ({ user: user.id, type: 'leader' })) : []),
      ...(data.members ? data.members.map(user => ({ user: user.id, type: 'member' })) : []),
    ] : undefined,
  }

  const [error] = await catchable(() => post<{ id: string }>('/dexcarto/habilitation/equipes/', form), true)

  if (error) {
    return error
  }

  await getTeams()

  ToastSignal.value = {
    message: `L'équipe ${data.name || ''} a bien été créée`,
    severity: ToastLevel.SUCCESS,
  }

  return null
}

export const patchTeam = async (teamId: string, data: TeamForm) => {
  const form = {
    nom: data.name,
    entites: data.entites,
    users: [
      ...(data?.leaders ? data.leaders.map(user => ({ user: user.id, type: 'leader' })) : []),
      ...(data?.members ? data.members.map(user => ({ user: user.id, type: 'member' })) : []),
    ],
  }

  const [error] = await catchable(() => patch(`/dexcarto/habilitation/equipes/${teamId}/`, form), true)

  if (error) {
    return error
  }

  await getTeams()

  ToastSignal.value = {
    message: `L'équipe ${data.name || ''} a bien été modifiée`,
    severity: ToastLevel.SUCCESS,
  }

  return null
}

export const patchTeamMembers = async (teamId: string, data: Member[]) => {
  const teamMembers = TeamSignal.list.value.find(team => team.id === teamId)?.users || []
  const form = {
    users: data.concat(teamMembers).map(user => ({ user: user.user, type: user.type })),
  }

  const [error] = await catchable(() => patch(`/dexcarto/habilitation/equipes/${teamId}/`, form), true)

  if (error) {
    return error
  }

  await getTeams()

  ToastSignal.value = {
    message: 'Les membres de l\'équipe ont bien été modifiés',
    severity: ToastLevel.SUCCESS,
  }

  return null
}

export const deleteTeam = async (teamId: string) => {
  const [error] = await catchable(() => deleteRequest(`/dexcarto/habilitation/equipes/${teamId}/`))

  if (error) {
    return error
  }

  await getTeams()

  ToastSignal.value = {
    message: 'L\'équipe a bien été supprimée',
    severity: ToastLevel.SUCCESS,
  }
  return undefined
}

export const postUser = async (data: UserForm) => {
  const form = {
    cerbere_id: data.cerbere_id,
    role: data.role,
    entite: data.entite,
    equipes: data.equipes,
    sections: data.sections,
  }

  const [error] = await catchable(() => post('/dexcarto/users/cerbere-users/', form), true)
  await getUsers()

  if (error) {
    return error
  }

  ToastSignal.value = {
    message: `L'utilisateur ${data.firstName} ${data.lastName} a bien été créé`,
    severity: ToastLevel.SUCCESS,
  }

  return null
}

export const patchUser = async (user: UserSearch, data: UserForm) => {
  const form = {
    role: data.role === user.role ? undefined : data.role,
    entite: data.entite === user.entite.id ? undefined : data.entite,
    equipes: user.equipes.map(equipe => equipe.id).every(id => data.equipes.includes(id)) ? undefined : data.equipes,
    sections: user.sections.map(
      section => section.id,
    ).every(id => data.sections.includes(id)) ? undefined : data.sections,
  }

  const [error] = await catchable(() => patch(`/dexcarto/users/cerbere-users/${user.id}/`, form), true)

  if (error) {
    return error
  }

  await getUsers()

  ToastSignal.value = {
    message: `L'utilisateur ${data.firstName} ${data.lastName} a bien été modifié`,
    severity: ToastLevel.SUCCESS,
  }

  return null
}

export const deleteTeamMember = async (team: Team, userId: string) => {
  const form = {
    users: team.users.filter(user => user.user !== userId).map(user => ({ user: user.user, type: user.type })),
  }

  const [error] = await catchable(() => patch(`/dexcarto/habilitation/equipes/${team.id}/`, form), true)

  if (error) {
    return error
  }

  await getTeams()

  ToastSignal.value = {
    message: 'L\'utilisateur a bien été retiré de l\'équipe',
    severity: ToastLevel.SUCCESS,
  }

  return undefined
}

export const deleteUser = async (user: UserSearch) => {
  const [error] = await catchable(() => deleteRequest(`/dexcarto/users/cerbere-users/${user.id}/`), true)

  if (error) {
    return error
  }

  await getUsers()

  ToastSignal.value = {
    message: `L'utilisateur ${user.firstName} ${user.lastName} a bien été supprimé`,
    severity: ToastLevel.SUCCESS,
  }

  return undefined
}

export const getSections = async () => {
  SectionSignal.loading.value = true
  const [error, response] = await catchable(() => get<Section[]>('/dexcarto/habilitation/sections/'))

  if (error) {
    return []
  }

  const [, cerbereResponse] = await catchable(
    () => post<UserSearch[]>(
      '/cerbere/users/simple_with_email/',
      response.flatMap(section => section.users.map(user => user.user)),
    ),
  )

  const responseWithUsers = response.map(team => ({
    ...team,
    users: team.users.map(user => ({
      ...user,
      ...cerbereResponse?.find(cerbereUser => cerbereUser.id === user.user),
    })),
  }))

  SectionSignal.list.value = responseWithUsers
  SectionSignal.loading.value = false

  return response
}

export const deleteSection = async (sectionId: string) => {
  const [error] = await catchable(() => deleteRequest(`/dexcarto/habilitation/sections/${sectionId}/`))

  if (error) {
    return error
  }

  await getSections()

  ToastSignal.value = {
    message: 'La section a bien été supprimée',
    severity: ToastLevel.SUCCESS,
  }

  return undefined
}

export const postSection = async (data: SectionForm) => {
  const form = {
    nom: data.name,
    entite: data.entite,
  }

  const [error] = await catchable(() => post('/dexcarto/habilitation/sections/', form), true)

  if (error) {
    return error
  }

  await getSections()

  ToastSignal.value = {
    message: `La section ${data.name || ''} a bien été créée`,
    severity: ToastLevel.SUCCESS,
  }

  return null
}

export const patchSection = async (section: Section, data: SectionForm) => {
  const form = {
    nom: data.name === section.nom ? undefined : data.name,
    entite: data.entite === section.entite.id ? undefined : data.entite,
  }

  const [error] = await catchable(() => patch(`/dexcarto/habilitation/sections/${section.id}/`, form), true)

  if (error) {
    return error
  }

  await getSections()

  ToastSignal.value = {
    message: `La section ${data.name || ''} a bien été modifiée`,
    severity: ToastLevel.SUCCESS,
  }

  return null
}

export const deleteSectionMember = async (section: Section, userId: string) => {
  const form = {
    users: section.users.filter(user => user.user !== userId).map(user => ({ user: user.user, type: user.type })),
  }

  const [error] = await catchable(() => patch(`/dexcarto/habilitation/sections/${section.id}/`, form), true)

  if (error) {
    return error
  }

  await getSections()

  ToastSignal.value = {
    message: 'L\'utilisateur a bien été retiré de la section',
    severity: ToastLevel.SUCCESS,
  }

  return undefined
}

export const patchSectionMembers = async (sectionId: string, data: Partial<Member>[]) => {
  const sectionMembers = SectionSignal.list.value.find(section => section.id === sectionId)?.users || []
  const form = {
    users: data.concat(sectionMembers).map(user => ({ user: user.user, type: user.type })),
  }

  const [error] = await catchable(() => patch(`/dexcarto/habilitation/sections/${sectionId}/`, form), true)

  if (error) {
    return error
  }

  await getSections()

  ToastSignal.value = {
    message: 'Les membres de la section ont bien été modifiés',
    severity: ToastLevel.SUCCESS,
  }

  return null
}
