import { Accordion } from 'components'
import { Collection } from 'types'

type Props = {
  collection: Collection
}

export default function LinkedWorks({ collection }: Props) {
  return (
    <div className="linked-works">
      <Accordion title="Travaux impactants :" defaultExpanded>
        {collection.travaux_impactants?.length ? (
          <table>
            <thead>
              <tr>
                <th>Libellé</th>
                <th>Année</th>
                <th>
                  Code
                  {'\u00A0'}
                  C6
                </th>
                <th>
                  Code
                  {'\u00A0'}
                  ASTRE
                </th>
              </tr>
            </thead>
            <tbody>
              {collection.travaux_impactants?.map(work => (
                <tr key={work.id}>
                  <td>{work.libelle}</td>
                  <td>{work.annee}</td>
                  <td>{work.num_compte_geremi}</td>
                  <td>{work.numero_astre}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : <p>Aucun travaux impactant identifié pour cette collection</p>}
      </Accordion>
    </div>
  )
}
