import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerWithAngleProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'
import PictoLayer from './generic/PictoLayer'

export default function JDZLayer({
  view, visibility, angle,
}: LayerWithAngleProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.jdz,
    view,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <PictoLayer
      sourceId={CHARTIS_SOURCES_IDS.jdz}
      layer={MAPBOX_LAYER_IDS.jdz}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      layout={{
        'icon-image': ['case',
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'jdz_red',
          'jdz_blue'],
        'icon-size': 0.3,
        'icon-rotate': ['coalesce', ['get', angle], 0],
      }}
    />
  )
}
