import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'
import PointLayer from './generic/PointLayer'

export default function PedaleLayer({ view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat_centroid__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.bif,
    view,
    { ...perimeterParam, code_ch: 'BF' },
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <PointLayer
      sourceId={CHARTIS_SOURCES_IDS.bif}
      layers={[
        MAPBOX_LAYER_IDS.bifCircle,
        MAPBOX_LAYER_IDS.bifHighlight,
        MAPBOX_LAYER_IDS.bifLabel,
      ]}
      color="#9d15cf"
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      textField={['format', 'Bifurcation', '\n', ['get', 'libelle']]}
    />
  )
}
