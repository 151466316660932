/* eslint-disable max-len */
export const terms = {
  Common: {
    disconnect: 'Déconnexion',
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    abort: 'Abandonner',
    save: 'Sauvegarder',
    back: 'Retour',
    confirm: 'Confirmer',
    delete: 'Supprimer',
    add: 'Ajouter',
    edit: 'Modifier',
    search: 'Rechercher',
    searching: 'Recherche',
    reset: 'Réinitialiser',
    filter: 'Filtrer',
    close: 'Fermer',
    continue: 'Continuer',
    validate: 'Valider',
    new: 'Nouveau',
    send: 'Envoyer',
    unselectAll: 'Tout désélectionner',
    selectAll: 'Tout sélectionner',
    showLess: 'Voir moins',
    showMore: 'Voir plus',
  },
  Contents: {
    sch: 'Schématique',
    geo: 'Géographique',
    table: 'Tableau',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      badGateway: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
  Header: {
    saveView: 'Enregistrer la vue',
    createPerimeter: 'Créer un périmètre d’étude',
    creatingPerimeter: 'Création de périmètre en cours',
    closePerimeter: 'Fermer le périmètre d’étude',
    detailPerimeter: 'Détail du périmètre',
    layers: 'Couches',
    createObject: 'Créer un objet',
    toScreen: 'À l’écran',
  },
  ToolsPanel: {
    agent: {
      title: 'Espace agent',
      favorites: 'Mes favoris',
      perimeters: 'Mes périmètres',
      views: 'Mes vues',
      contributes: 'Mes contributions',
      roles: 'Rôles et habilitations',
      teams: 'Gestion des équipes / sections',
    },
    content: {
      title: 'Type de vue',
      simple: 'Vue simple',
      split: 'Vues scindées',
    },
    contact: {
      title: 'Contacter l’équipe technique',
      suggest: 'Pour une suggestion',
      bug: 'Pour un bug',
    },
    contribute: {
      title: 'Contribuer à la donnée',
      suggest: 'Suggérer une édition (poste, IHM ou frontière)',
      comments: 'Voir la liste de mes commentaires sur les objets',
    },
    help: {
      title: 'Aide',
      faq: 'Comment utiliser DEXCARTO ?',
      news: 'Nouveautés',
    },
  },
  Pages: {
    Access: {
      title: 'Vous n’êtes pas encore habilité à voir l’application',
      info: 'Pour faire une demande, veuillez contacter votre administrateur via le formulaire ci-dessous',
      form: {
        group: 'Groupe DEX/PRI (ou préciser si autre entité) :',
        reason: 'Motif de la demande',
        action: 'Envoyer la demande',
        success: 'Votre demande a bien été envoyée.',
      },
    },
    Home: {
      content: 'Home Page - Click me',
    },
  },
  Panels: {
    Views: {
      title: 'Vues',
      search: 'Rechercher une vue',
      name: 'Nom de la vue',
      layers: 'Couches',
      creationDate: 'Création',
      access: 'Accèder',
      actions: 'Actions',
    },
    Perimeters: {
      title: 'Périmètres',
      search: 'Rechercher un périmètre',
      name: 'Nom du périmètre',
      description: 'Description',
      creationDate: 'Création',
      access: 'Accèder',
      actions: 'Actions',
      deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce périmètre ?',
      deleteError: 'Une erreur est survenue lors de la suppression du périmètre.',
      deleteSuccess: 'Le périmètre a été supprimé avec succès.',
    },
    Favorites: {
      tabs: {
        views: 'Vues',
        perimeters: 'Périmètres',
        objects: 'Objets',
        collections: 'Collections',
      },
    },
    Search: {
      title: 'Recherche à l’écran',
      subtitle: 'La recherche est restreinte aux couches activées et visibles à l’écran.',
      tabs: {
        left: 'Vue de gauche',
        right: 'Vue  de droite',
      },
      noTab: 'Aucune vue disponible',
    },
    TeamsManager: {
      title: 'Gestion des équipes / sections',
      teams: {
        name: 'Nom de l’équipe',
        entities: 'Entités associées',
        owner: 'Responsables',
        members: 'Membres',
        action: 'Action',
        create: 'Créer une équipe',
      },
      sections: {
        entity: 'Entité associée',
        name: 'Nom de la section',
        action: 'Action',
        create: 'Créer une section',
      },
      tabs: {
        teams: 'Équipes',
        sections: 'Sections',
      },
    },
    Users: {
      title: 'Rôles et habilitations',
      create: 'Nouvel utilisateur',
      CP: 'CP',
      firstname: 'Prénom',
      lastname: 'Nom',
      role: 'Rôle',
      entities: 'Entité',
      section: 'Section(s)',
      teams: 'Équipe(s)',
      action: 'Action',
    },
  },
  Modals: {
    SaveView: {
      title: 'Sauvegarder la vue',
      info: 'Retrouver facilement cette zone dans vos vues.<br />DEXCARTO se rappellera du niveau de zoom et des couches sélectionnées',
      label: 'Nommez la vue pour la retrouver dans vos vues :',
      success: 'La vue a été sauvegardée avec succès.',
      error: 'Une erreur est survenue lors de la sauvegarde de la vue.',
    },
    ViewSaved: {
      title: (name: string) => `La vue ${name} a été sauvegardée avec succès.`,
      info: 'Pour revenir à cette vue, ouvrez le panneau de gauche puis “Vues”.',
      showView: 'Voir les vues',
    },
    SavePerimeter: {
      title: 'Créer un périmètre',
      info: 'Ajoutez les informations liées au périmètre et associez-le à une étude DEX.',
      nameLabel: 'Nommez le périmètre :',
      descriptionLabel: 'Ajoutez une description :',
      error: 'Une erreur est survenue lors de la sauvegarde du périmètre.',
      success: 'Le périmètre a été créé avec succès.',
      selfIntersecting: 'Le polygone dessiné ne doit pas se croiser avec lui-même.',
    },
    PatchPerimeter: {
      title: 'Modifier le périmètre',
      info: 'Modifiez les informations liées au périmètre et associez-le à une étude DEX.',
      nameLabel: 'Nommez le périmètre :',
      descriptionLabel: 'Ajoutez une description :',
      error: 'Une erreur est survenue lors de la modification du périmètre.',
      success: 'Le périmètre a été modifié avec succès.',
    },
    Report: {
      subject: 'Sujet',
      comment: 'Votre commentaire',
      sendBrowserInfo: "Inclure des données sur votre environnement actuel, comme le navigateur et l'URL de la page. Cela nous permet de mieux comprendre votre commentaire.",
    },
    Users: {
      create: 'Créer un nouvel utilisateur',
      update: 'Modifier l’utilisateur',
      info: 'Ajoutez les informations liées à l’utilisateur',
      formTitle: 'Informations générales',
    },
    Teams: {
      create: 'Créer une équipe',
      update: 'Modifier l’équipe',
      members: 'Membres de',
      addMember: 'Ajouter un membre',
      warningMissingEntity: 'Attention : supprimer une entité entraînera la suppression de tous ses membres de cette équipe !',
    },
    Sections: {
      create: 'Créer une section',
      update: 'Modifier la section',
      addMember: 'Ajouter un membres',
      members: 'Membres de',
    },
    AddMembers: {
      title: 'Ajouter un ou plusieurs membres',
    },
  },
  Messages: {},
} as const

export default terms
