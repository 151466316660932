import { Layer } from '@vis.gl/react-maplibre'
import { SIGNAL_LAYERS_MNEMO } from 'assets/layers'
import { ExpressionSpecification } from 'maplibre-gl'
import { MapDataSignal, CHARTIS_SOURCES_IDS, isLayerSelected, MAPBOX_LAYER_IDS } from 'services'
import { LayerWithAngleProps } from 'types'
import { hoverColor, useActivePerimeterParams, useHoveredObjectsIds, useSource } from 'utils'

const getMnemo: ExpressionSpecification = ['get', 'type_installation_fixe_id_mnemo']
const getPictoImage: ExpressionSpecification = ['case',
  ['in', getMnemo, ['literal', ['CARRE', 'CARRE (CH)']]], 'signal_lumineux_carre_bis',
  ['==', getMnemo, 'CV'], 'signal_lumineux_carre_violet_bis',
  ['in', getMnemo, ['literal', ['S', 'S (CH)']]], 'semaphore',
  ['==', getMnemo, 'D'], 'signal_lumineux_disque_vitesse_limite',
  ['in', getMnemo, ['literal', ['A', 'A (CH)']]], 'signal_lumineux_avertissement_vitesse_limite',
  ['==', getMnemo, 'GA'], 'signal_guidon_arret',
  ['==', getMnemo, 'SG HEURT'], 'heurtoir',
  'cercle2',
]

const getPictoOffset: ExpressionSpecification = ['case',
  ['in', getMnemo, ['literal', ['CARRE', 'CARRE (CH)', 'S', 'S (CH)']]],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-50, -150]], ['literal', [50, -150]]],
  ['==', getMnemo, 'GA'],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-50, -60]], ['literal', [50, -60]]],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-50, -100]], ['literal', [50, -100]]],
]

const getTextOffset: ExpressionSpecification = ['case',
  ['in', getMnemo, ['literal', ['CARRE', 'CARRE (CH)', 'S', 'S (CH)']]],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-2, -7]], ['literal', [2, -7]]],
  ['==', getMnemo, 'GA'],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-1.75, -3.25]], ['literal', [1.75, -3.25]]],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-2, -5.5]], ['literal', [2, -5.5]]],
]

export default function SignalLayer({ view, visibility, angle }: LayerWithAngleProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  useSource(
    CHARTIS_SOURCES_IDS.signal,
    view,
    perimeterParam,
  )

  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal
  const signalFilter = Object.entries(SIGNAL_LAYERS_MNEMO)
    .filter(([key]) => isLayerSelected(key)).flatMap(([, value]) => value)
  const pictoMnemos = ['CARRE', 'CARRE (CH)', 'CV', 'S', 'S (CH)', 'D', 'A', 'A (CH)', 'GA', 'SG HEURT']
  const pictoFilter = signalFilter.filter(mnemo => pictoMnemos.includes(mnemo))
  const noPictoFilter = signalFilter.filter(mnemo => !pictoMnemos.includes(mnemo))
  return (
    <>
      <Layer
        id={MAPBOX_LAYER_IDS.signalCircle}
        type="circle"
        paint={{
          'circle-radius': 3,
          'circle-color': hoverColor('#0088ce', hoveredObjectsIds),
          'circle-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        source-layer={CHARTIS_SOURCES_IDS.signal}
        source={CHARTIS_SOURCES_IDS.signal}
        minzoom={9}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', signalFilter]]}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.signalHighlight}
        type="circle"
        paint={{
          'circle-radius': 6,
          'circle-stroke-color': '#ff0000',
          'circle-stroke-width': 2,
          'circle-color': 'transparent',
        }}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        filter={['in', ['get', 'id'], ['literal', hoveredObjectsIds]]}
        source-layer={CHARTIS_SOURCES_IDS.signal}
        source={CHARTIS_SOURCES_IDS.signal}
        minzoom={9}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.signalMat}
        type="symbol"
        layout={{
          'icon-image': ['case', ['==', ['get', 'lrs_sens'], 'C'], 'mat_gauche', 'mat_droite'],
          'icon-allow-overlap': true,
          'icon-size': 0.8,
          'icon-rotation-alignment': 'map',
          'icon-rotate': ['coalesce', ['get', angle], 0],
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          'icon-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', pictoFilter]]}
        source-layer={CHARTIS_SOURCES_IDS.signal}
        source={CHARTIS_SOURCES_IDS.signal}
        minzoom={14}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.signalPicto}
        type="symbol"
        layout={{
          'icon-image': getPictoImage,
          'icon-allow-overlap': true,
          'icon-size': 0.4,
          'icon-rotation-alignment': 'map',
          'icon-offset': getPictoOffset,
          'text-font': ['Open Sans Regular'],
          'text-size': 13,
          'text-rotation-alignment': 'map',
          'text-offset': getTextOffset,
          'text-allow-overlap': true,
          'text-field': ['case',
            ['==', getMnemo, 'SG HEURT'],
            ['format', ''],
            [
              'format',
              ['get', 'libelle'],
              {},
              '\n',
              {},
              ['get', 'lrs_pk'],
              {
                'text-color': hoverColor('#0088ce', hoveredObjectsIds),
              },
            ],
          ],
          visibility: visibility ? 'visible' : 'none',
          'icon-rotate': ['coalesce', ['get', angle], 0],
          'text-rotate': ['coalesce', ['get', angle], 0],
        }}
        paint={{
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'icon-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-halo-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
            'transparent'],
          'text-halo-width': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
            0.1],
        }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', pictoFilter]]}
        source-layer={CHARTIS_SOURCES_IDS.signal}
        source={CHARTIS_SOURCES_IDS.signal}
        minzoom={14}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.signalLabel}
        type="symbol"
        layout={{
          'text-field': ['format', 'S', '\n', ['get', 'libelle']],
          'text-font': ['Open Sans Regular'],
          'text-size': 13,
          'text-allow-overlap': false,
          'text-offset': ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [1, 0]], ['literal', [-1, 0]]],
          'text-variable-anchor': ['left', 'right', 'bottom-left', 'bottom-right', 'top-left', 'top-right'],
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'text-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-halo-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
            'transparent'],
          'text-halo-width': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
            0.1],
        }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', noPictoFilter]]}
        source-layer={CHARTIS_SOURCES_IDS.signal}
        source={CHARTIS_SOURCES_IDS.signal}
        minzoom={14}
      />
    </>
  )
}
