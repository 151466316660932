import { useEffect, useState } from 'react'
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg'
import Loader from 'components/loader/Loader'
import { InputSize } from '..'

import '../wrapper.scss'
import './SelectInput.scss'

export type Option = {
  label?: string
  value: string | number
}

type Props = {
  defaultValue?: string
  label?: string
  placeholder?: string
  options: Option[]
  shouldReset?: boolean
  onChange: (value: string | number) => void
  size?: InputSize
  required?: boolean
  loading?: boolean
  disabled?: boolean
}

export default function Select({
  defaultValue,
  label = '',
  placeholder = '',
  options,
  shouldReset = false,
  onChange,
  size = InputSize.medium,
  required = false,
  loading = false,
  disabled = false,
}: Props) {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(shouldReset ? 'placeholder' : event.target.value)
    onChange(event.target.value)
  }

  return (
    <div className={`select input-wrapper input--${size}${!label ? ' no-label' : ''}`}>
      {label && (
        <label htmlFor={label}>
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      <select
        className="input"
        value={value}
        onChange={handleChange}
        disabled={loading || disabled}
      >
        {placeholder && <option value="placeholder" disabled selected>{placeholder}</option>}
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label || option.value}
          </option>
        ))}
      </select>
      {loading ? <Loader variant="x-small" /> : <Chevron />}
    </div>
  )
}
